            #interna.page-template {
                .img-kicker-container,
                .img-desc {
                    background: rgba(228, 0, 57, .85);
                    .icon-box {
                        background: rgba(228, 0, 57, .85);
                    }
                }
                &.page-template-template-clientes {
                    .img-desc {
                        background: rgba(228, 0, 57, .85) !important;
                    }
                    #section-1-interna {
                        margin-bottom: 180px;
                        .img-kicker-container {
                            padding: 103px 20px !important;
                        }
                        .text-top {
                            text-align: center;
                            width: 100%;
                            max-width: 1152px;
                            margin: 220px auto auto;
                            font-size: 20px;
                            line-height: 30px;
                            padding: 0 70px;
                        }
                    }
                    #section-2-interna {
                        margin-bottom: 170px;
                        .section-text {
                            padding-top: 200px;
                            img {
                                margin-bottom: 60px;
                            }
                            .btn {
                                margin-top: 24px;
                            }
                        }
                        .section-img {
                            .img-desc {
                                width: 320px;
                                height: 260px;
                                top: 130px;
                                right: -35px;
                            }
                        }
                    }
                    #section-3-interna {
                        .section-text {
                            padding: 89px 70px!important;
                            top: auto;
                            bottom: -80px;
                            .img-desc {
                                top: -174px;
                                left: 354px;
                            }
                            .content-items {
                                display: grid;
                                grid-template-columns: 1fr 1fr 1fr 1fr;
                                grid-template-rows: 1fr 1fr;
                                margin: 0 auto;
                                grid-column-gap: 60px;
                                grid-row-gap: 100px;
                                .item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                    #brands-content {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 146px 30px;
                        img {
                            width: auto;
                        }
                    }
                    #slider {
                        margin-bottom: 430px;
                        display: flex;
                        justify-content: flex-end;
                        .container-slider {
                            max-width: 1728px;
                            width: 100%;
                            position: relative;
                            .swiper-container {
                                width: 100%;
                                height: 100%;
                            }
                            .swiper-slide {
                                text-align: center;
                                font-size: 18px;
                                background: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .content-pagination {
                                z-index: 7;
                                width: 287px;
                                background: white;
                                height: 120px;
                                position: absolute;
                                bottom: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 20px;
                                line-height: 30px;
                                color: #414d5b;
                                font-weight: 600;
                                .swiper-pagination {
                                    position: relative;
                                    bottom: auto;
                                    width: auto;
                                }
                                .swiper-button-next,
                                .swiper-button-prev {
                                    width: 9px;
                                    height: 18px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    margin-top: 0;
                                    background-size: contain;
                                }
                                .swiper-button-next {
                                    background-image: url('img/clientes/icons/arrow-right.jpg');
                                    right: 70px;
                                }
                                .swiper-button-prev {
                                    background-image: url('img/clientes/icons/arrow-left.jpg');
                                    left: 70px;
                                }
                            }
                            .image-float {
                                position: absolute;
                                right: 162px;
                                z-index: 8;
                                bottom: -760px;
                                img {
                                    width: auto;
                                }
                            }
                        }
                    }
                    #brands-content-2 {
                        margin: auto auto 100px;
                        div {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;
                            width: 100%;
                            margin: auto;
                            flex-wrap: wrap;
                            padding: 0 60px;
                            hr {
                                width: 100%;
                                opacity: 0;
                                margin: 0;
                            }
                            img {
                                width: auto;
                                margin-bottom: 124px;
                                &:nth-child(5) {
                                    &:before {
                                        content: '';
                                    }
                                }
                            }
                        }
                    }
                    #section-4-interna {
                        margin-bottom: 130px;
                        .section-text {
                            bottom: 50px;
                            .content-items {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 107px 165px;
                            }
                            .img-desc {
                                top: -178px;
                                right: 162px;
                            }
                        }
                    }
                }
                &.page-template-template-clientes_bunge {
                    #section-1-interna {
                        margin-bottom: 180px;
                        .img-kicker-container {
                            padding: 96px 20px;
                        }
                        .text-top {
                            text-align: center;
                            width: 100%;
                            max-width: 1152px;
                            margin: auto;
                            margin-top: 180px;
                            font-size: 20px;
                            line-height: 30px;
                            padding: 0 32px;
                        }
                        .content-video {
                            padding-bottom: 34.25%;
                            video {
                                width: 100%;
                                height: auto;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }
                    }
                    #section-2-interna {
                        margin-bottom: 180px;
                        .section-text {
                            padding-top: 172px;
                            position: relative;
                            h2 {
                                margin-bottom: 40px !important;
                                color: #e40039;
                            }
                            img {
                                left: 50%;
                                position: absolute;
                                top: 10px;
                                transform: translateX(-50%);
                            }
                        }
                        .section-img {
                            .img-desc {
                                bottom: -30px;
                                right: -35px;
                                padding: 40px 30px;
                                text-transform: initial;
                                font-size: 25px;
                                line-height: 34px;
                                max-width: 580px;
                            }
                        }
                    }
                    #section-3-interna {
                        margin-bottom: 400px;
                        .section-text {
                            padding-left: 80px;
                            padding-top: 160px;
                            padding-bottom: 78px;
                            top: 300px;
                            h2 {
                                color: white !important;
                                margin-bottom: 40px !important;
                            }
                            .btn {
                                margin-top: 20px;
                                font-size: 24px;
                            }
                            .img-desc {
                                font-size: 25px;
                                line-height: 32px;
                                text-transform: initial;
                                padding: 48px 59px;
                                position: absolute;
                                top: 0;
                                transform: translateY(-50%);
                            }
                        }
                        .casos {
                            flex-direction: column;
                            font-weight: 400;
                            text-transform: initial;
                            font-size: 25px;
                            line-height: 40px;
                            padding: 52px 117px;
                            position: absolute;
                            bottom: -492px;
                            left: 384px;
                            z-index: 7;
                            h6 {
                                font-weight: 700;
                                font-size: 50px;
                                line-height: 70px;
                            }
                            p {
                                line-height: 32px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1950px) {
                    &.page-template-template-clientes {
                        #section-1-interna {
                            margin-bottom: 140px !important;
                            .text-top {
                                margin: 200px auto auto;
                            }
                        }
                        #section-2-interna {
                            .section-img .img-desc {
                                width: 260px;
                                height: 210px;
                                top: 176px;
                                right: -20px;
                            }
                            .section-text {
                                img {
                                    width: 300px !important;
                                    margin-bottom: 50px;
                                }
                            }
                        }
                        #brands-content {
                            padding: 70px 90px;
                        }
                        #slider {
                            margin-bottom: 240px!important;
                            .container-slider {
                                max-width: calc(100% - 200px);
                                .image-float {
                                    bottom: -540px;
                                    img {
                                        width: 700px;
                                    }
                                }
                            }
                        }
                        #brands-content-2 {
                            margin-bottom: 30px !important;
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-1-interna {
                            .text-top {
                                margin: 120px auto auto;
                            }
                        }
                        #section-2-interna .section-img .img-desc {
                            right: -20px;
                        }
                        #section-3-interna {
                            margin-bottom: 240px!important;
                            .casos {
                                bottom: -370px !important;
                                padding: 40px 86px !important;
                                font-size: 24px!important;
                                h6 {
                                    font-size: 42px !important;
                                    line-height: 62px !important;
                                }
                                p {
                                    line-height: 28px;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1450px) {
                    .img-desc.icon-box {
                        width: 150px!important;
                        height: 150px!important;
                        img {
                            width: 60%!important;
                        }
                    }
                    &.page-template-template-clientes {
                        #section-1-interna {
                            margin-bottom: 105px!important;
                            .img-kicker-container {
                                padding: 40px!important;
                            }
                            .text-top {
                                margin-top: 110px!important;
                            }
                        }
                        #section-2-interna {
                            .flex-container {
                                align-items: center;
                                .section-img .img-desc {
                                    width: 190px;
                                    height: 150px;
                                }
                                .section-text {
                                    padding-top: 0px;
                                    img {
                                        width: 200px!important;
                                        margin-bottom: 40px;
                                    }
                                    .btn {
                                        margin-top: 10px;
                                    }
                                }
                            }
                        }
                        #section-3-interna {
                            .section-text {
                                padding: 40px 40px!important;
                                bottom: -45px;
                                .img-desc {
                                    top: -119px;
                                    left: 32%;
                                }
                                .content-items {
                                    grid-column-gap: 2%;
                                    grid-row-gap: 5px;
                                    .item {
                                        padding: 12%;
                                        img {
                                            max-width: 95%!important;
                                            max-height: 70px;
                                        }
                                    }
                                }
                            }
                        }
                        #brands-content {
                            padding: 50px 40px;
                        }
                        #slider {
                            margin-bottom: 150px!important;
                            .container-slider {
                                .image-float {
                                    bottom: -395px;
                                    img {
                                        width: 500px;
                                    }
                                }
                                .content-pagination {
                                    width: 230px;
                                    height: 90px;
                                    .swiper-button-next {
                                        right: 50px
                                    }
                                    .swiper-button-prev {
                                        left: 50px
                                    }
                                    .swiper-pagination {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }
                        #brands-content-2 {
                            margin-bottom: 50px!important;
                            div {
                                margin-bottom: 0 !important;
                                img {
                                    margin-bottom: 80px;
                                }
                                &:first-child {
                                    max-width: 100%;
                                    padding: 0 40px;
                                }
                            }
                        }
                        #section-4-interna {
                            .section-text {
                                padding-right: 0 !important;
                                .img-desc {
                                    top: -119px;
                                    right: 48px;
                                }
                                .content-items {
                                    padding: 82px 40px;
                                }
                            }
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-1-interna .text-top {
                            margin-top: 110px !important;
                        }
                        #section-2-interna {
                            .section-text {
                                padding-top: 114px !important;
                                padding-bottom: 55px;
                                img {
                                    width: 200px !important;
                                }
                            }
                            .section-img {
                                .img-desc {
                                    right: -22px !important;
                                    padding: 22px 50px !important;
                                    bottom: 0;
                                }
                            }
                        }
                        #section-3-interna {
                            .section-text {
                                padding-top: 110px !important;
                                .img-desc {
                                    padding: 30px 42px !important;
                                }
                            }
                            .casos {
                                padding: 18px 44px!important;
                                padding-top: 22px !important;
                                font-size: 16px!important;
                                padding-bottom: 22px !important;
                                bottom: -330px!important;
                                h6 {
                                    font-size: 26px!important;
                                    line-height: 38px!important;
                                }
                                p {
                                    line-height: 22px !important;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1200px) {
                    &.page-template-template-clientes {
                        #brands-content {
                            flex-wrap: wrap;
                            justify-content: space-around;
                            padding: 0px 40px;
                            img {
                                margin: 0 25px;
                                margin-bottom: 30px;
                            }
                        }
                        #slider .container-slider {
                            max-width: calc(100% - 80px);
                            .image-float {
                                right: 75px;
                                bottom: -365px;
                                img {
                                    width: 400px;
                                }
                            }
                        }
                        #brands-content-2 {
                            margin-bottom: 60px!important;
                            div {
                                flex-wrap: wrap;
                                justify-content: space-around;
                                margin-bottom: 0 !important;
                                img {
                                    margin: 0 25px;
                                    margin-bottom: 40px;
                                }
                            }
                        }
                        #section-4-interna .section-text {
                            bottom: -20px;
                            .content-items {
                                padding: 60px 20px;
                            }
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-3-interna {
                            margin-bottom: 340px!important;
                            .casos {
                                bottom: -400px!important;
                                left: auto !important;
                                right: 30px;
                            }
                            .section-text {
                                top: 230px;
                            }
                        }
                    }
                }
                @media screen and (max-width: 960px) {
                    &.page-template-template-clientes_bunge {
                        #section-3-interna {
                            margin-bottom: 440px!important;
                            .casos {
                                padding: 18px 18px 22px!important
                            }
                        }
                    }
                }
                @media screen and (max-width: 900px) {
                    &.page-template-template-clientes {
                        #section-1-interna {
                            margin-bottom: 70px!important;
                            .text-top {
                                font-size: 15px;
                                line-height: 22px;
                                padding: 0;
                            }
                        }
                        #section-2-interna {
                            .flex-container {
                                .section-text {
                                    text-align: center;
                                    img {
                                        margin: auto;
                                        margin-bottom: 30px;
                                    }
                                }
                            }
                        }
                        #section-3-interna {
                            .section-text {
                                padding: 20px!important;
                                padding-top: 40px !important;
                                padding-bottom: 40px !important;
                                .content-items {}
                                .img-desc {
                                    bottom: 0px !important;
                                    top: -110px !important;
                                }
                            }
                        }
                        #slider {
                            margin-bottom: 120px!important;
                            .container-slider {
                                max-width: 100%;
                                .image-float {
                                    right: 20px;
                                    img {
                                        width: 300px;
                                    }
                                }
                                .content-pagination {
                                    width: 170px;
                                    height: 60px;
                                    .swiper-button-next {
                                        right: 25px;
                                    }
                                    .swiper-button-prev {
                                        left: 25px;
                                    }
                                }
                            }
                        }
                        #section-4-interna {
                            .section-text {
                                width: 100% !important;
                                max-width: calc(100% - 40px) !important;
                                left: 20px !important;
                                padding: 0 !important;
                                .img-desc {
                                    top: -117px !important;
                                }
                            }
                        }
                        #brands-content-2 {
                            hr {
                                display: none !important;
                            }
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-1-interna {
                            margin-bottom: 120px!important;
                            .text-top {
                                margin-top: 70px !important;
                                p {
                                    font-size: 15px;
                                    line-height: 22px;
                                }
                            }
                        }
                        #section-2-interna {
                            .section-img {
                                .img-desc {
                                    bottom: -50px !important;
                                }
                            }
                            .section-text {
                                padding-top: 50px!important;
                                padding-bottom: 0 !important;
                                position: initial;
                                img {
                                    bottom: auto;
                                    top: -80px !important;
                                }
                            }
                        }
                        #section-3-interna {
                            margin-bottom: 230px!important;
                            .section-text {
                                text-align: center;
                                padding-top: 90px!important;
                                .img-desc {
                                    bottom: auto !important;
                                    top: -74px !important;
                                    left: 50% !important;
                                    transform: translateX(0%);
                                    padding: 20px 32px!important;
                                    width: 380px !important;
                                    margin-left: -190px !important;
                                }
                            }
                            .casos {
                                left: 50% !important;
                                width: calc(100% - 70px);
                                transform: translate(-50%, 50%);
                                bottom: -138px!important;
                            }
                        }
                    }
                }
                @media screen and (max-width: 640px) {
                    &.page-template-template-clientes {
                        #section-1-interna .img-kicker-container {
                            padding: 20px!important;
                        }
                        #section-4-interna .section-text .content-items {
                            flex-direction: column;
                            img {
                                margin-bottom: 40px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-1-interna .content-video {
                            padding-bottom: 0;
                            height: 300px;
                            video {
                                width: auto;
                                height: 100%;
                            }
                        }
                        #section-2-interna .section-img .img-desc {
                            padding: 20px 15px!important;
                        }
                        #section-3-interna .section-text {
                            padding-top: 70px!important;
                            .img-desc {
                                top: -54px!important;
                                width: 274px!important;
                                margin-left: -137px!important;
                            }
                        }
                    }
                }
                @media screen and (max-width: 499px) {
                    &.page-template-template-clientes {
                        .img-desc {
                            width: 100px!important;
                            height: 100px!important;
                        }
                        #section-1-interna {
                            margin-bottom: 50px!important;
                        }
                        #section-2-interna .flex-container .section-text img {
                            margin: auto auto 25px;
                        }
                        #section-3-interna .section-text {
                            padding: 10px !important;
                            .img-desc {
                                top: -83px!important;
                            }
                            .content-items {
                                grid-template-columns: 1fr 1fr;
                                grid-column-gap: 0;
                                grid-row-gap: 0;
                                .item {
                                    padding: 25px !important;
                                }
                            }
                        }
                        #brands-content {
                            padding: 0 20px;
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-column-gap: 10px;
                            grid-row-gap: 40px;
                            img {
                                max-width: 70%;
                                max-height: 65px;
                                margin: auto;
                                display: block;
                            }
                        }
                        #slider {
                            margin-bottom: 100px!important;
                            .container-slider {
                                .content-pagination .swiper-pagination {
                                    font-size: 14px;
                                }
                                .swiper-container {}
                                .image-float {
                                    width: 50%;
                                    bottom: -280px;
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        #brands-content-2 {
                            padding: 0 20px;
                            margin-bottom: 50px!important;
                            div {
                                padding: 0 !important;
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                grid-column-gap: 10px;
                                grid-row-gap: 40px;
                                img {
                                    max-width: 70%;
                                    max-height: 65px;
                                    margin: auto;
                                    display: block
                                }
                            }
                        }
                        #section-4-interna .section-text {
                            .img-desc {
                                top: -80px!important;
                            }
                            .content-items {
                                padding: 40px 20px;
                                padding-top: 50px;
                                img {
                                    max-width: 50%;
                                }
                            }
                        }
                    }
                    &.page-template-template-clientes_bunge {
                        #section-1-interna {
                            margin-bottom: 75px!important;
                            figure img {
                                object-position: 23% center;
                            }
                        }
                        #section-2-interna {
                            margin-top: 50px;
                            padding-top: 35px!important;
                            .section-img .img-desc {
                                padding: 10px 10px!important;
                                width: calc(100% - 60px);
                                left: 30px !important;
                                transform: translateX(0%);
                            }
                            .section-text img {
                                width: 170px!important;
                                top: -68px!important;
                            }
                        }
                    }
                }
            }