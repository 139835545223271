        #interna.page-template-template-soluciones,
        [class*="page-template-template-soluciones"].page-child#interna {
            /* Section 1 */
            #section-1-interna {
                .img-kicker-container {
                    background: rgba(90, 187, 143, .9);
                    padding: 93px 20px;
                }
            }
            /* Section 2 - section-2-interna */
            #discover,
            #section-2-interna {
                padding-right: 30px;
                margin-bottom: 200px;
                .img-desc {
                    position: absolute;
                    top: 168px;
                    right: -40px;
                    transform: translate(0, 0);
                    img {
                        width: 131px;
                    }
                }
                .section-text {
                    padding-top: 182px;
                    padding-left: 30px;
                    h2 {
                        margin-bottom: 40px !important;
                    }
                    ul {
                        li {
                            margin-bottom: 30px;
                            strong {
                                text-transform: uppercase;
                                display: block;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                h2 {
                    color: #6de3ae;
                }
            }
            /* Section 3 */
            #implement,
            #section-3-interna {
                .img-desc {
                    top: -150px;
                    left: 100px;
                }
                .flex-container {
                    .section-text {
                        padding-left: 99px;
                        padding-top: 90px;
                        padding-bottom: 63px;
                        bottom: -60px;
                        h2 {
                            color: #6de3ae;
                            margin-bottom: 44px !important;
                        }
                    }
                }
            }
            /* Section 4 */
            #section-4-interna {
                background: rgba(106, 194, 154, .9);
                color: #ffffff;
                font-size: 20px;
                line-height: 30px;
                padding: 112px 0 120px;
                margin-bottom: -240px;
                top: 50px;
                max-width: initial;
                z-index: 7;
                .content-items {
                    max-width: 1542px;
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                    margin: auto;
                    .icon {
                        padding-bottom: 53px;
                        margin-bottom: 66px;
                        position: relative;
                        &:before {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            width: 80px;
                            height: 5px;
                            background: white;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        img {
                            width: auto;
                            margin: auto;
                        }
                    }
                }
                .inner-holder {
                    flex-basis: 444px;
                    padding: 0 24px;
                }
                h2 {
                    font-size: 45px;
                    font-weight: 900;
                    line-height: 1;
                    color: #353d44;
                    margin-bottom: 20px;
                }
                p.font-700 {
                    margin-bottom: 28px;
                }
            }
            /* Section 5 */
            #operate {
                .img-desc {
                    position: absolute;
                    top: 112px;
                    right: -40px;
                    z-index: 7;
                    transform: translate(0, 0);
                    img {
                        width: 144px;
                    }
                }
                .section-text {
                    padding-top: 180px;
                    h2 {
                        color: #6de3ae;
                        margin-bottom: 44px !important;
                    }
                    ul {
                        li {
                            margin-bottom: 30px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                            strong {
                                text-transform: uppercase;
                                display: block;
                            }
                        }
                    }
                }
            }
            /* Section 6 */
            #enable {
                padding-top: 190px;
                padding-left: 30px;
                margin-bottom: 300px;
                .img-desc {
                    top: -140px;
                    left: 100px;
                }
                .section-text {
                    padding-left: 100px;
                    padding-top: 134px;
                    padding-bottom: 75px;
                    bottom: -640px;
                    left: 0 !important;
                    h2 {
                        color: #6de3ae;
                        margin-bottom: 45px !important;
                    }
                    ul {
                        li {
                            margin-bottom: 30px;
                            strong {
                                text-transform: uppercase;
                                display: block;
                            }
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .btns {
                        .btn {
                            margin-right: 60px;
                            margin-top: 30px !important;
                            &:last-child {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
                .section-img {
                    max-width: 60%;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                .btn {
                    height: 61px;
                    font-size: 24px;
                    line-height: 61px;
                    padding: 0 30px;
                    background: #5dc295;
                    &:hover {
                        color: #5dc295;
                        border-color: #5dc295;
                        background: #ffffff;
                    }
                }
            }
            /* Section 7 */
            #section-7-interna {
                padding-right: 30px;
                margin-bottom: 160px;
                .img-desc {
                    top: 170px;
                    right: -40px;
                    img {
                        width: 146px;
                    }
                }
                .section-text {
                    padding-top: 185px;
                    padding-left: 30px;
                    h2 {
                        color: #6de3ae;
                        margin-bottom: 44px !important;
                    }
                }
                .btn {
                    height: 61px;
                    font-size: 24px;
                    line-height: 61px;
                    padding: 0 34px;
                    margin-top: 25px !important;
                }
            }
            // TRAINING 
            &.page-template-template-soluciones_training {
                #section-2-interna {
                    padding-right: 0px !important;
                    .flex-container {
                        .img-desc {
                            background: transparent;
                            padding: 0 !important;
                            height: auto;
                            width: auto;
                            top: 160px;
                            img {
                                width: auto;
                            }
                        }
                        h2 {
                            margin-bottom: 0 !important;
                        }
                        h3 {
                            margin-top: 0 !important;
                            color: #6de3ae;
                        }
                    }
                }
                #section-3-interna {
                    margin-bottom: 150px;
                    .img-desc {
                        width: 635px;
                        height: 235px;
                        text-transform: initial;
                        font-weight: 400;
                        left: -384px;
                        top: 25px;
                        font-size: 25px;
                        padding: 58px 34px 58px 64px;
                        background: rgba(53, 61, 68, 0.8);
                        z-index: 7;
                        text-align: left;
                        top: 40px;
                    }
                    .section-text {
                        padding-top: 261px;
                        padding-left: 30px !important;
                        .btn {
                            height: 60px;
                            line-height: 60px;
                            padding: 0 41px;
                            margin-top: 30px;
                        }
                        h2 {
                            margin-bottom: 0 !important;
                        }
                        h3 {
                            margin-top: 0 !important;
                            color: #6de3ae;
                        }
                    }
                    .section-img {
                        .overlay-text {
                            max-width: 635px;
                            position: absolute;
                            left: -384px;
                            top: 25px;
                            font-size: 25px;
                            padding: 58px 34px 58px 64px;
                            background: rgba(53, 61, 68, .8);
                            z-index: 7;
                        }
                        .overlay-certificated {
                            position: absolute;
                            left: 132px;
                            transform: translateY(100%);
                            bottom: 97px;
                        }
                    }
                }
            }
            // IROM
            &.page-template-template-soluciones_irom {
                #section-1-interna {
                    margin-bottom: 300px !important;
                }
                #section-2-interna {
                    margin-bottom: 300px !important;
                    padding-right: 0px !important;
                    .img-desc {
                        top: -140px;
                        right: 182px;
                        img {
                            width: auto;
                        }
                    }
                    h2 {
                        color: #fff;
                        margin-top: 0 !important;
                    }
                    .section-text {
                        max-width: 1122px;
                        position: absolute;
                        bottom: -420px;
                        color: #ffffff;
                        padding: 115px 94px 74px 60px!important;
                        z-index: 7;
                        background: rgba(54, 61, 68, .9);
                        .btn {
                            height: 58px;
                            line-height: 58px;
                            &:hover {
                                color: #5dc295;
                                border-color: #5dc295;
                                background: #ffffff;
                            }
                        }
                    }
                }
            }
            &.page-template-template-soluciones_perfbooster {
                #section-2-interna {
                    padding-right: 0px !important;
                    .section-text {
                        padding-top: 0px;
                        margin-top: -8px;
                        ul {
                            li {
                                margin-bottom: 0 !important;
                            }
                        }
                    }
                    .img-desc {
                        right: 113px;
                    }
                }
                #section-3-interna {
                    margin-bottom: 120px;
                    .section-text {
                        bottom: -230px !important;
                    }
                }
            }
            @media screen and (max-width: 1950px) {
                #section-1-interna {
                    margin-bottom: 230px!important;
                }
                #discover,
                #section-2-interna {
                    .img-desc {
                        right: -22px;
                    }
                    .section-text h2 {
                        margin-bottom: 24px !important;
                    }
                }
                #implement,
                #section-3-interna {
                    .flex-container {
                        .section-text {
                            padding-top: 70px;
                            h2 {
                                margin-bottom: 24px !important;
                            }
                        }
                    }
                }
                #section-4-interna {
                    margin-bottom: -290px!important;
                }
                #operate {
                    .img-desc {
                        right: -25px;
                    }
                    .section-text h2 {
                        margin-bottom: 24px!important;
                    }
                }
                #enable {
                    padding-top: 20px;
                    .section-text {
                        bottom: -480px;
                        h2 {
                            margin-bottom: 24px!important;
                        }
                    }
                }
                #section-7-interna {
                    margin-bottom: 160px!important;
                    .img-desc {
                        right: -20px;
                    }
                    .section-text {
                        h2 {
                            margin-bottom: 24px!important;
                        }
                    }
                }
                &.page-template-template-soluciones_irom {
                    #section-1-interna {
                        margin-bottom: 240px!important;
                    }
                    #section-2-interna {
                        margin-bottom: 220px !important;
                        .section-text {
                            bottom: -330px;
                        }
                    }
                }
                &.page-template-template-soluciones_training {
                    #section-3-interna {
                        .section-text {
                            padding-top: 261px;
                        }
                    }
                }
                &.page-template-template-soluciones_perfbooster {
                    #section-3-interna {
                        margin-bottom: 230px !important;
                    }
                }
            }
            @media screen and (max-width: 1600px) {
                #discover,
                #section-2-interna {
                    .section-text {
                        bottom: -385px;
                    }
                }
            }
            @media screen and (max-width: 1450px) {
                .img-desc {
                    &.icon-box {
                        width: 150px!important;
                        height: 150px!important;
                        img {
                            width: 60% !important;
                        }
                    }
                }
                #section-1-interna {
                    margin-bottom: 170px!important;
                }
                #discover,
                #section-2-interna {
                    .section-text {
                        padding-top: 120px;
                    }
                }
                #implement,
                #section-3-interna {
                    .img-desc {
                        top: -97px;
                        left: 60px;
                    }
                    .flex-container .section-text {
                        padding-left: 60px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
                #section-4-interna {
                    top: -20px;
                    padding: 70px 0 70px;
                    margin-bottom: -420px!important;
                    .content-items .icon {
                        padding-bottom: 25px;
                        margin-bottom: 32px;
                        img {
                            width: 85px;
                        }
                    }
                    h2 {
                        font-size: 28px;
                        line-height: 28px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                #operate {
                    .flex-container {
                        .section-text {
                            padding-top: 80px;
                        }
                    }
                }
                #enable {
                    margin-bottom: 130px!important;
                    .img-desc {
                        top: -100px;
                        left: 60px;
                    }
                    .section-text {
                        padding-left: 60px;
                        padding-top: 76px;
                        padding-bottom: 60px;
                        bottom: -540px;
                    }
                    .btns {
                        justify-content: space-between;
                        display: flex;
                        .btn {
                            padding: 0 24px;
                            height: 60px;
                            line-height: 60px;
                            font-size: 16px;
                            margin-right: 0 !important;
                        }
                    }
                }
                #section-7-interna {
                    .section-text {
                        padding-top: 120px;
                    }
                    .btn {
                        padding: 0 24px;
                        height: 60px;
                        line-height: 60px;
                        font-size: 16px;
                    }
                }
                &.page-template-template-soluciones_irom {
                    #section-1-interna {
                        margin-bottom: 170px!important;
                    }
                    #section-2-interna {
                        margin-bottom: 280px !important;
                        .img-desc {
                            top: -76px;
                            right: 145px;
                        }
                        .section-text {
                            padding: 105px 94px 74px 50px!important;
                            bottom: -406px;
                            h2 {
                                line-height: 34px!important;
                            }
                            .btn {
                                margin-top: 20px !important;
                            }
                        }
                    }
                }
                &.page-template-template-soluciones_training {
                    #section-1-interna {
                        margin-bottom: 170px!important;
                    }
                    #section-2-interna .flex-container {
                        .img-desc img {
                            width: 200px;
                        }
                    }
                    #section-3-interna {
                        .img-desc {
                            max-width: 480px;
                            left: -310px;
                            top: -25px;
                            font-size: 18px;
                            line-height: 24px;
                            padding: 40px;
                            height: auto;
                        }
                        .section-text {
                            padding-top: 180px;
                        }
                        .section-img {
                            .overlay-certificated img {
                                width: 80%;
                            }
                        }
                    }
                }
                &.page-template-template-soluciones_perfbooster {
                    #section-1-interna {
                        margin-bottom: 170px!important;
                    }
                    #section-2-interna {
                        .section-text {
                            ;
                            ul li {
                                margin-bottom: 5px !important;
                            }
                        }
                    }
                    #section-3-interna {
                        .section-text {
                            padding-top: 45px !important;
                            padding-bottom: 40px !important
                        }
                        .btn {
                            margin-top: 10px;
                        }
                    }
                }
            }
            @media screen and (max-width: 1280px) {
                &.page-template-template-soluciones_irom {
                    .flex-container.data-right.overlay-content .section-text {
                        max-width: 75%!important;
                    }
                    #section-1-interna {
                        margin-bottom: 240px!important;
                    }
                }
            }
            @media screen and (max-width: 1200px) {
                #section-3-interna .flex-container .section-text {
                    bottom: -130px;
                }
                #enable {
                    .flex-container {
                        padding-left: 30px !important;
                    }
                    .section-text {
                        max-width: 75%!important;
                    }
                    .btns {
                        .btn {
                            padding: 0 12px;
                        }
                    }
                }
                &.page-template-template-soluciones_training {
                    #section-3-interna {
                        .section-text {
                            padding-top: 150px;
                        }
                    }
                }
            }
            @media screen and (max-width: 900px) {
                #section-1-interna {
                    margin-bottom: 110px!important;
                }
                #discover {
                    padding-right: 0px;
                    .img-desc {
                        margin-left: -75px !important;
                    }
                }
                #implement,
                #section-3-interna {
                    .img-desc {
                        top: -96px !important;
                    }
                    .flex-container .section-text {
                        bottom: 40px;
                    }
                }
                #section-4-interna {
                    padding: 50px 0;
                    top: -55px;
                    margin-bottom: -130px!important;
                    .inner-holder {
                        flex-basis: initial;
                        padding: 0 20px;
                    }
                    .content-items {
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        .icon {
                            padding-bottom: 15px;
                            margin-bottom: 24px;
                            &:before {
                                height: 2px;
                                width: 50px;
                            }
                        }
                        &>div {
                            position: relative;
                            margin-bottom: 40px;
                            padding-bottom: 40px;
                            .font-700 {
                                margin-bottom: 20px !important;
                            }
                            p {
                                font-size: 15px;
                                line-height: 24px;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 50%;
                                height: 2px;
                                background: white;
                            }
                            &.licensing {
                                margin-bottom: 0 !important;
                                padding-bottom: 0 !important;
                                &:before {
                                    content: none !important;
                                }
                            }
                        }
                    }
                }
                #operate .flex-container .section-img {
                    .img-desc {
                        margin-left: -75px;
                    }
                }
                #enable {
                    padding-top: 0;
                    padding-left: 0;
                    margin-bottom: -32px!important;
                    .img-desc {
                        top: -132px !important;
                    }
                    .flex-container {
                        padding-left: 0 !important;
                        .section-text {
                            max-width: initial !important;
                            bottom: -20px !important;
                            left: 20px !important;
                        }
                    }
                    .btns {
                        justify-content: center;
                        .btn {
                            margin-right: 15px !important;
                            line-height: 20px;
                            font-size: 16px;
                            padding: 14px 10px !important;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            &:last-child {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
                #section-7-interna {
                    margin-bottom: 80px!important;
                    padding-right: 0 !important;
                    .flex-container {
                        text-align: center;
                    }
                    .btn {
                        margin-top: 10px!important;
                    }
                    .img-desc {
                        bottom: -40px!important;
                    }
                }
                &.page-template-template-soluciones_irom {
                    #section-1-interna {
                        margin-bottom: 110px!important;
                    }
                    #section-2-interna {
                        margin-bottom: 0px!important;
                        .img-desc {
                            top: -105px !important;
                            left: 50% !important;
                            right: auto !important;
                            transform: translateX(-50%);
                        }
                        .section-text {
                            padding: 80px 50px 50px 50px!important;
                            bottom: 0;
                            max-width: 100% !important;
                        }
                    }
                }
                &.page-template-template-soluciones_training {
                    #section-1-interna {
                        margin-bottom: 135px!important;
                    }
                    #section-2-interna {
                        margin-bottom: 60px !important;
                        .img-desc {
                            margin-left: -100px!important;
                        }
                        h3 {
                            text-align: center;
                            margin-top: 5px !important;
                        }
                    }
                    #section-3-interna {
                        margin-bottom: 60px !important;
                        .img-desc {
                            bottom: auto !important;
                            top: 50px!important;
                        }
                        h3 {
                            text-align: center;
                            margin-top: 5px !important;
                        }
                        .section-img .overlay-certificated {
                            width: 45%;
                            left: 30px;
                            bottom: 130px;
                            z-index: 7;
                            img {
                                width: 100%;
                            }
                        }
                        .section-text {
                            max-width: 100% !important;
                            text-align: center;
                            .btn {
                                margin-top: 10px;
                            }
                        }
                    }
                }
                &.page-template-template-soluciones_perfbooster {
                    #section-1-interna {
                        margin-bottom: 120px!important;
                    }
                    #section-2-interna {
                        .img-desc {
                            bottom: -48px!important;
                        }
                        .section-text {
                            bottom: 0px!important;
                        }
                    }
                    #section-3-interna {
                        margin-bottom: 0px!important;
                        .section-text {
                            bottom: 0 !important;
                            ul li {
                                margin-bottom: 5px !important;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 640px) {
                .img-desc.icon-box {
                    width: 100px!important;
                    height: 100px!important;
                }
                #discover .img-desc {
                    bottom: -35px !important;
                    margin-left: -50px!important;
                }
                #implement,
                #section-3-interna {
                    margin-bottom: 30px!important;
                    .img-desc {
                        top: -70px!important;
                    }
                    .flex-container {
                        .section-text {
                            bottom: -20px;
                            h2 {
                                margin-top: 10px !important;
                            }
                        }
                    }
                }
                #section-4-interna {
                    margin-bottom: -75px!important;
                    top: 0;
                    h2 {
                        font-size: 24px;
                        line-height: 24px;
                    }
                    .content-items>div {
                        margin-bottom: 30px;
                        padding-bottom: 30px
                    }
                }
                #operate .flex-container .section-img .img-desc {
                    margin-left: -50px;
                    bottom: -26px!important;
                }
                #enable {
                    margin-bottom: -60px!important;
                    .img-desc {
                        top: -80px!important;
                    }
                    .section-text {
                        .btns {
                            flex-direction: column;
                            margin-top: 30px !important;
                            .btn {
                                margin-right: 0 !important;
                                margin-top: 15px!important;
                                &:first-child {
                                    margin-top: 0 !important;
                                }
                            }
                        }
                        ul {
                            margin-top: 24px;
                        }
                    }
                }
                &.page-template-template-soluciones_irom {
                    #section-2-interna {
                        .img-desc {
                            top: -55px!important;
                        }
                        .section-text {
                            padding: 64px 20px 50px 20px!important;
                        }
                    }
                }
                &.page-template-template-soluciones_training {
                    #section-2-interna {
                        .flex-container {
                            .img-desc {
                                margin-left: -75px!important;
                                img {
                                    width: 140px;
                                }
                            }
                        }
                    }
                    #section-3-interna .section-img .img-desc {
                        left: 20px;
                        top: 120px;
                        max-width: 399px;
                        font-size: 15px;
                        line-height: 21px;
                        padding: 20px;
                    }
                }
                &.page-template-template-soluciones_perfbooster {
                    #section-2-interna {
                        .img-desc {
                            bottom: -30px!important;
                            left: 20px !important;
                        }
                    }
                    #section-3-interna {
                        margin-bottom: -20px!important;
                    }
                }
            }
            @media screen and (max-width: 499px) {
                .img-desc.icon-box {
                    width: 90px!important;
                    height: 90px!important;
                }
                &.page-template-template-soluciones_training {
                    #section-2-interna {
                        margin-bottom: 150px!important;
                        .section-text {
                            padding-top: 55px!important;
                        }
                    }
                    #section-3-interna {
                        .section-img {
                            .img-desc {
                                width: calc( 100% - 40px);
                                transform: translate(0);
                                left: 20px !important;
                                text-align: center;
                                top: -84px!important;
                            }
                            .overlay-certificated {
                                width: 160px;
                                left: 50%;
                                bottom: -90px;
                                z-index: 7;
                                transform: translateX(-50%) !important;
                            }
                        }
                        .section-text {
                            padding-top: 100px!important;
                        }
                    }
                }
            }
        }