#interna.page-template-template-tecnologias_clave {
    .img-kicker-container,
    .box-overlay {
        background: rgba(116, 53, 155, .85) !important;
    }
    .img-t-board,
    .img-r-board,
    .img-l-board {
        z-index: 0 !important;
    }
    .flex-container {
        .section-text,
        .section-overlay-text {
            img {
                margin-bottom: 58px;
            }
        }
    }
    #section-1-interna {
        .img-kicker-container {
            padding: 91px 20px;
        }
        .content-video {
            padding-bottom: 34.25%;
            video {
                width: 100%;
                height: auto;
            }
        }
    }
    #section-2-interna {
        margin-bottom: 160px !important;
        .flex-container.data-right {
            .section-text {
                padding-top: 170px;
            }
            .section-img {
                .effect-y-300 {
                    position: absolute;
                    top: 100px;
                    right: 352px;
                    z-index: 7;
                }
                .box-overlay {
                    width: 386px;
                    height: 237px;
                    top: 96px;
                }
            }
        }
    }
    #section-3-interna {
        margin-bottom: 260px;
        .flex-container.data-left {
            .section-text {
                padding: 113px 113px 45px 113px;
                margin-top: 365px;
                .box-overlay {
                    left: 113px;
                    transform: translate(0);
                    width: 100%;
                    max-width: 385px;
                    height: 185px;
                    top: -125px;
                }
            }
        }
    }
    #section-4-interna {
        margin-bottom: 160px;
        .flex-container.data-right {
            .section-text {
                padding-top: 158px;
            }
            .section-img {
                .effect-y-300 {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 7;
                }
                .box-overlay {
                    width: 386px;
                    height: 227px;
                    top: 168px;
                    right: -33px;
                }
            }
        }
    }
    #section-5-interna {
        margin-bottom: 220px;
        .flex-container.data-left.overlay-content {
            .section-text {
                padding: 110px;
                padding-top: 196px;
                padding-bottom: 56px;
                margin-top: 370px;
                h2 {
                    color: white;
                }
                .box-overlay {
                    width: 549px;
                    height: 267px;
                    transform: translate(0);
                    left: 105px;
                    top: -128px;
                }
            }
        }
    }
    #section-6-interna {
        margin-bottom: 200px;
        .flex-container.data-right.overlay-content .section-text {
            padding-left: 110px !important;
            padding-right: 110px !important;
            padding-top: 158px;
            padding-bottom: 90px;
            background: rgba(116, 53, 155, 0.9);
            margin-top: 335px;
            h2 {
                color: white;
            }
            .box-overlay {
                width: 455px;
                height: 237px;
                background: rgba(53, 61, 68, 0.9) !important;
                transform: translate(0);
                right: 130px;
                top: -134px;
            }
        }
    }
}

@media screen and (max-width: 1950px) {
    #interna.page-template-template-tecnologias_clave {
        #section-1-interna {
            margin-bottom: 220px!important;
        }
        #section-2-interna {
            margin-bottom: 100px!important;
            .flex-container.data-right .section-img .effect-y-300 {
                right: 302px;
                .box-overlay {
                    width: 320px;
                    height: 180px;
                }
            }
        }
        #section-3-interna {
            margin-bottom: 100px!important;
            .flex-container.data-left .section-text {
                margin-top: 300px;
                .box-overlay {
                    max-width: 300px;
                    height: 132px;
                    top: -62px;
                }
            }
        }
        #section-4-interna .flex-container.data-right .section-img .box-overlay {
            width: 330px;
            height: 185px;
            top: 190px;
            right: -14px;
        }
        #section-5-interna .flex-container.data-left.overlay-content .section-text {
            padding: 160px 110px 56px;
            margin-top: 310px;
            .box-overlay {
                width: 470px;
                height: 220px;
                left: 105px;
                top: -102px;
            }
        }
        #section-6-interna {
            margin-bottom: 200px!important;
        }
    }
}

@media screen and (max-width: 1450px) {
    #interna.page-template-template-tecnologias_clave {
        .flex-container {
            .section-text,
            .section-overlay-text {
                img {
                    margin-bottom: 30px;
                }
            }
        }
        #section-1-interna {
            margin-bottom: 160px!important;
        }
        #section-2-interna {
            .flex-container.data-right {
                .section-text {
                    padding-top: 125px;
                    img {
                        width: 244px !important;
                    }
                }
                .section-img {
                    .effect-y-300 {
                        right: 212px;
                        top: 88px;
                    }
                    .img-desc {
                        font-size: 18px;
                        line-height: 24px;
                        width: auto !important;
                        height: auto !important;
                        padding: 25px 20px;
                    }
                }
            }
        }
        #section-3-interna {
            .section-text {
                padding-bottom: 30px !important;
                padding: 70px 60px 45px !important;
                margin-top: 250px !important;
                .box-overlay {
                    font-size: 18px;
                    line-height: 24px;
                    width: auto !important;
                    height: auto !important;
                    top: -40px !important;
                    left: 60px !important;
                    padding: 25px 20px;
                }
                img {
                    width: 175px !important;
                }
            }
        }
        #section-4-interna {
            margin-bottom: 100px;
            .flex-container.data-right {
                .section-img .box-overlay {
                    width: 224px !important;
                }
                .section-text {
                    padding-top: 110px;
                    img {
                        width: 300px !important;
                    }
                }
                .box-overlay {
                    font-size: 18px;
                    line-height: 24px;
                    width: auto !important;
                    height: auto !important;
                    padding: 25px 20px;
                    top: 227px;
                    right: -17px;
                }
            }
        }
        #section-5-interna {
            margin-bottom: 110px;
            .flex-container.data-left.overlay-content .section-text {
                padding: 75px 60px 25px;
                margin-top: 260px;
                .box-overlay {
                    font-size: 18px;
                    line-height: 24px;
                    width: auto !important;
                    height: auto !important;
                    padding: 20px 10px;
                    left: 60px !important;
                    top: -86px !important;
                    max-width: 315px;
                }
                img {
                    width: 240px !important;
                    margin-top: 20px;
                }
            }
        }
        #section-6-interna {
            margin-bottom: 160px;
            .flex-container.data-right.overlay-content .section-text {
                padding-left: 80px!important;
                padding-right: 80px!important;
                padding-top: 90px !important;
                padding-bottom: 20px !important;
                margin-top: 275px;
                .box-overlay {
                    max-width: 300px !important;
                    font-size: 18px;
                    line-height: 24px;
                    width: auto !important;
                    height: auto !important;
                    padding: 25px 20px;
                    left: auto !important;
                    top: -60px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    #interna.page-template-template-tecnologias_clave {
        .effect-y-300 {
            position: initial !important;
        }
        .section-text img {
            margin-left: auto !important;
            margin-right: auto !important;
        }
        #section-1-interna {
            margin-bottom: 110px!important;
            .box-overlay {
                bottom: -50px!important;
                max-width: 220px !important;
            }
        }
        #section-2-interna {
            margin-bottom: 60px !important;
            .box-overlay {
                max-width: 200px !important;
                bottom: -35px!important;
            }
        }
        #section-3-interna {
            margin-bottom: 0px!important;
            .section-text {
                margin-top: 0px!important;
                padding-top: 80px !important;
                padding-bottom: 20px !important;
                .box-overlay {
                    bottom: auto !important;
                    top: -50px!important;
                    max-width: 200px !important;
                    left: 50% !important;
                    margin-left: -100px !important;
                }
            }
        }
        #section-4-interna {
            .img-desc {
                bottom: -30px!important;
            }
        }
        #section-5-interna {
            margin-bottom: 0px!important;
            .flex-container.data-left.overlay-content .section-text {
                padding-bottom: 30px !important;
                margin-top: -40px;
                .box-overlay {
                    top: -108px!important;
                    bottom: auto !important;
                    left: 50% !important;
                    transform: translateX(-50%);
                }
            }
        }
        #section-6-interna {
            margin-bottom: 30px!important;
            .flex-container.data-right.overlay-content .section-text {
                max-width: 100% !important;
                margin-top: 0 !important;
                padding-top: 80px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                .box-overlay {
                    top: -66px!important;
                    bottom: auto !important;
                    right: auto !important;
                    left: 50% !important;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

@media screen and (max-width: 640px) {
    #interna.page-template-template-tecnologias_clave {
        #section-1-interna {
            .content-video {
                padding-bottom: 0;
                height: 300px;
                video {
                    width: auto;
                    height: 100%;
                }
            }
        }
        #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
            width: 100% !important;
            max-width: 290px;
            width: 100% !important;
            max-width: 290px;
        }
    }
}

@media screen and (max-width: 480px) {
    #interna.page-template-template-tecnologias_clave {
        #section-1-interna {
            margin-bottom: 100px!important;
        }
        #section-2-interna .flex-container.data-right {
            .section-img .box-overlay {
                width: 165px!important;
            }
            .section-text {
                img {
                    width: 205px!important;
                }
            }
        }
        #section-3-interna {
            margin-bottom: 0px;
            .section-text {
                padding: 70px 20px 45px!important;
            }
        }
        #section-4-interna .flex-container.data-right {
            .section-img .box-overlay {
                width: 180px!important;
            }
            .section-text img {
                width: 220px!important;
            }
        }
        #section-5-interna {
            margin-bottom: 0;
            .flex-container.data-left.overlay-content .section-text {
                margin-top: 85px;
                img {
                    width: 175px!important;
                }
            }
        }
        #section-6-interna {
            margin-bottom: 0;
            .flex-container.data-right.overlay-content .section-text {
                margin-top: 40px !important;
                .box-overlay {
                    width: 220px!important;
                    right: auto !important;
                    left: 50% !important;
                }
            }
        }
    }
}