/* Nuevo footer */

.site-footer {
    padding: 0 30px 30px;
    color: #ffffff;
    z-index: 7;
    position: relative;
    .inner-holder {
        padding: 64px 20px;
        background: #363e44;
        position: relative;
        &>.flex-container {
            max-width: 1378px;
            justify-content: space-between;
            margin: 0 auto;
            align-items: center;
            width: 100%;
        }
    }
    .footer-logo {
        width: auto;
        padding: 0;
    }
    .footer-data {
        font-size: 18px;
        line-height: 1;
        text-align: right;
        align-items: center;
        .extra-holder {
            margin-right: 29px;
            .footer-links {
                letter-spacing: 0.23px;
                span {
                    padding: 0 5px;
                }
            }
            &>p.font-700 {
                margin-bottom: 7px;
            }
        }
    }
    .linkedin-link {
        width: 42px;
    }
}

@media screen and (max-width: 1500px) {
    .site-footer {
        .footer-data {
            font-size: 15px;
            width: calc(100% - 360px);
            display: flex;
            justify-content: flex-end;
        }
        .footer-logo {
            width: 360px;
            justify-content: center;
            display: flex;
            img {
                width: auto;
            }
        }
    }
}

@media screen and (max-width: 1220px) {
    .site-footer {
        .footer-logo {
            margin-left: 20px;
            width: auto;
        }
        .footer-data {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1150px) {
    .site-footer {
        .footer-data {
            font-size: 14px;
            .extra-holder {
                margin-right: 15px;
            }
        }
        .footer-logo {
            margin-left: 0;
            img {
                width: 140px;
            }
        }
    }
}

@media screen and (max-width: 930px) {
    /* Footer */
    .site-footer {
        padding: 0;
        .inner-holder {
            padding: 35px 20px;
            &>.flex-container {
                display: block;
            }
        }
        .footer-logo {
            margin-bottom: 40px;
            img {
                width: auto;
            }
        }
        .footer-data {
            justify-content: flex-start;
            .extra-holder {
                margin-right: 0;
                margin-left: 15px;
                text-align: left;
            }
        }
        .linkedin-link {
            order: -1;
        }
    }
}

@media screen and (max-width: 767px) {
    .site-footer {
        .footer-logo {
            margin-bottom: 35px;
        }
        .footer-data {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .extra-holder {
                text-align: center;
                margin: 0;
                margin-bottom: 20px;
                .footer-links {
                    flex-direction: column;
                    span {
                        display: none;
                    }
                    a {
                        margin-bottom: 7px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        .linkedin-link {
            order: 1;
        }
    }
}

@media screen and (max-width: 499px) {
    .site-footer .footer-logo img {
        width: 160px;
    }
}