#interna {
    color: #414d5b;
    .flex-container {
        position: relative;
        &.data-right {
            &.overlay-content {
                padding-right: 30px;
                justify-content: flex-start;
                .section-img {
                    position: relative !important;
                }
                .section-text {
                    max-width: 60%;
                    position: absolute;
                    left: auto;
                    right: 30px;
                    background: rgba(42, 43, 46, .8);
                    width: 100%;
                    z-index: 5;
                    max-width: 1120px !important;
                    color: white;
                    padding-left: 0 !important;
                }
            }
        }
        &.data-left {
            &.overlay-content {
                padding-left: 30px;
                justify-content: flex-end;
                .section-img {
                    position: relative !important;
                }
                .section-text {
                    max-width: 60%;
                    position: absolute;
                    left: 30px;
                    right: auto;
                    background: rgba(42, 43, 46, .8);
                    width: 100%;
                    z-index: 5;
                    max-width: 1120px !important;
                    color: white;
                    padding-right: 0;
                }
            }
        }
    }
    .img-desc {
        position: absolute;
        z-index: 5;
        font-size: 35px;
        line-height: 40px;
        font-weight: 700;
        text-transform: uppercase;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &.icon-box {
            width: 228px !important;
            height: 228px !important;
            text-align: center;
            display: flex;
            align-items: center;
            background: rgba(90, 187, 143, .85) !important;
            justify-content: center;
            padding: 0 !important;
        }
    }
    #section-1-interna {
        padding: 30px 30px 0;
        margin-bottom: 330px;
        max-width: initial;
        .img-kicker-container {
            max-width: 1152px;
            color: #ffffff;
            position: absolute;
            transform: translate(-50%, -60%);
            text-align: center;
            z-index: 7;
            left: 50%;
            width: 100%;
            h1 {
                font-size: 50px;
                line-height: 1;
                margin-bottom: 4px;
                text-transform: uppercase;
                font-weight: 900;
            }
            p {
                font-size: 40px;
                line-height: 50px;
                color: white;
            }
        }
    }
    @media screen and (max-width: 1950px) {
        .img-desc {
            font-size: 28px !important;
            line-height: 34px !important;
        }
        section {
            margin-bottom: 150px !important;
        }
        .flex-container {
            .section-text {
                .section-title {
                    font-size: 42px !important;
                    line-height: 42px!important;
                }
                p,
                ul li {
                    font-size: 18px;
                    line-height: 28px;
                }
                p {
                    margin-bottom: 28px;
                }
                ul li {
                    margin-bottom: 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .flex-container {
            &.data-right {
                .section-text {
                    padding-left: 40px!important;
                    padding-right: 30px;
                }
                &.overlay-content {
                    .section-img {}
                    .section-text {}
                }
            }
            &.data-left {
                .section-text {
                    padding-right: 40px!important;
                    padding-left: 30px;
                }
                &.overlay-content {
                    .section-img {}
                    .section-text {}
                }
            }
        }
        #section-1-interna {
            .img-kicker-container {
                padding: 50px !important;
                h1 {
                    font-size: 42px;
                }
                p {
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }
        #section-2-interna .section-text h2 {
            margin-bottom: 24px !important;
        }
    }
    @media screen and (max-width: 1450px) {
        section {
            margin-bottom: 105px!important;
        }
        .img-desc {
            font-size: 22px !important;
            line-height: 28px !important;
        }
        .flex-container {
            .section-text {
                .section-title {
                    font-size: 32px !important;
                    line-height: 32px!important;
                    margin-bottom: 20px!important;
                }
                p,
                ul li {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .flex-container {
            &.data-right {
                .section-text {}
                &.overlay-content {
                    .section-img {}
                    .section-text {
                        max-width: 60% !important;
                    }
                }
            }
            &.data-left {
                .section-text {}
                &.overlay-content {
                    .section-img {}
                    .section-text {
                        max-width: 60% !important;
                    }
                }
            }
        }
        #section-1-interna {
            .img-kicker-container {
                padding: 40px !important;
                max-width: 65%;
                h1 {
                    font-size: 35px;
                }
                p {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        br {
            display: none !important;
        }
        section {
            margin-bottom: 70px!important;
        }
        .img-desc {
            right: auto !important;
            left: 50% !important;
            transform: translateX(-50%);
            bottom: -60px!important;
            top: auto !important;
            font-size: 18px;
            line-height: 22px;
        }
        .flex-container {
            .section-text {
                padding-left: 20px!important;
                padding-right: 20px;
                padding-top: 40px !important;
                .section-title {
                    font-size: 28px!important;
                    line-height: 28px!important;
                    margin-bottom: 20px!important;
                    text-align: center;
                }
                p,
                ul li {
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                }
                p {
                    margin-bottom: 20px;
                }
                ul li {
                    text-align: left;
                    margin-bottom: 20px !important;
                }
            }
        }
        .flex-container {
            &.data-right {
                flex-direction: column;
                .section-text {
                    padding-left: 20px!important;
                    padding-right: 20px;
                }
            }
            &.overlay-content {
                .section-img {}
                .section-text {
                    width: calc(100% - 40px) !important;
                    max-width: initial !important;
                    left: 20px !important;
                    transform: translateX(0);
                    top: auto !important;
                    bottom: 0;
                    position: relative !important;
                    padding: 40px 20px !important;
                    margin-top: -30px;
                }
            }
            &.data-left {
                flex-direction: column;
                padding-left: 0 !important;
                .section-text {
                    padding-left: 20px!important;
                    padding-right: 20px;
                }
                &.overlay-content {
                    .section-img {}
                    .section-text {
                        width: calc(100% - 40px) !important;
                        max-width: initial !important;
                        left: 20px !important;
                        transform: translateX(0);
                        top: auto !important;
                        bottom: 0;
                        position: relative !important;
                        padding: 40px 20px !important;
                        margin-top: -30px;
                    }
                }
            }
        }
        #section-1-interna .img-kicker-container {
            padding: 20px!important;
            h1 {
                font-size: 28px;
            }
            p {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }
    @media screen and (max-width: 640px) {
        section {
            margin-bottom: 50px !important;
        }
        .img-desc {
            font-size: 15px !important;
            line-height: 18px !important;
        }
        .flex-container {
            .section-text {
                padding-top: 30px !important;
                .section-title {
                    font-size: 24px!important;
                    line-height: 24px!important;
                    margin-bottom: 15px!important;
                }
                p {
                    margin-bottom: 15px;
                }
            }
        }
        #section-1-interna {
            padding: 0;
            figure {
                height: 300px;
                position: relative;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .img-kicker-container {
                width: calc(100% - 40px);
                max-width: initial !important;
            }
        }
    }
    @media screen and (max-width: 360px) {
        #section-1-interna .img-kicker-container {
            padding: 20px 10px !important;
            h1 {
                font-size: 25px;
            }
        }
    }
}