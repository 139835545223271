    #interna.page-template-template-contenido_simple {
        .img-kicker-container,
        .box-overlay,
        .img-desc {
            background: rgba(73, 184, 239, .85) !important;
        }
        #section-1-interna {
            margin-bottom: 290px;
            .img-kicker-container {
                padding: 92px 20px;
            }
        }
        #section-contenido-simple {
            padding-bottom: 150px;
            .container {
                width: 100%;
                margin: auto;
                max-width: 1152px;
                strong {
                    font-weight: 900;
                }
                p {
                    font-size: 20px;
                    line-height: 30px;
                    margin-bottom: 30px;
                    br {
                        display: block !important;
                    }
                }
            }
        }
        @media screen and (max-width: 1950px) {
            #section-1-interna {
                margin-bottom: 230px!important;
            }
            #section-contenido-simple {
                padding-bottom: 0;
            }
        }
        @media screen and (max-width: 1450px) {
            .img-desc.icon-box {
                width: 150px!important;
                height: 150px!important;
                img {
                    width: 60%!important;
                }
            }
            #section-1-interna {
                margin-bottom: 160px!important;
            }
            #section-contenido-simple {
                .container {
                    p {
                        font-size: 15px;
                        line-height: 24px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
        @media screen and (max-width: 1190px) {
            #section-contenido-simple {
                .container {
                    padding: 0 20px;
                }
            }
        }
        @media screen and (max-width: 900px) {
            #section-1-interna {
                margin-bottom: 110px!important;
            }
        }
        @media screen and (max-width: 480px) {
            #section-contenido-simple {
                .container {
                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }