#interna.page-template-template-conocenos {
    .img-kicker-container,
    .box-overlay,
    .img-desc {
        background: rgba(73, 184, 239, .85) !important;
    }
    .img-desc.icon-box {
        width: 337px!important;
        height: 290px!important;
        padding: 0 !important;
        img {
            width: auto;
        }
    }
    .img-t-board,
    .img-r-board,
    .img-l-board {
        z-index: 0 !important;
    }
    #section-1-interna {
        .img-kicker-container {
            padding: 92px 20px;
        }
    }
    #section-2-interna {
        .section-img {
            .effect-y-300 {
                top: 215px;
                right: 302px;
                position: absolute;
            }
        }
        .section-text {
            padding-top: 0;
            padding-bottom: 140px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            h2 {
                color: #49b8ef;
                margin-bottom: 40px !important;
            }
            strong {
                text-transform: uppercase;
                font-size: 20px;
                line-height: 30px;
                display: block;
            }
            ul {
                margin-top: 32px;
                li {
                    margin-bottom: 35px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    #nuestros-lideres {
        margin-bottom: 230px;
        margin-top: -92px;
        z-index: 7;
        position: relative;
        .container {
            width: 100%;
            max-width: 1536px;
            margin: auto;
            h2 {
                background: rgba(73, 184, 239, .85)!important;
                color: #fff;
                font-size: 50px;
                line-height: 50px;
                max-width: 912px;
                margin: auto;
                text-align: center;
                text-transform: uppercase;
                font-weight: 900;
                padding: 47px 20px;
            }
            .group-lideres {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                margin-top: 100px;
                grid-row-gap: 85px;
                .item {
                    padding: 0 25px;
                    h3 {
                        font-size: 40px;
                        line-height: 45px;
                        color: #49b8ef;
                        font-weight: 900;
                        text-transform: uppercase;
                        margin-bottom: 34px;
                    }
                    strong {
                        font-size: 20px;
                        line-height: 30px;
                        text-transform: uppercase;
                        margin-bottom: 32px;
                        display: block;
                    }
                    p {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    #nuestra-cultura {
        margin-bottom: 180px;
        .section-text {
            padding-left: 82px;
            padding-top: 84px;
            padding-right: 80px!important;
            padding-bottom: 50px;
            bottom: -230px;
            .img-desc {
                width: 386px;
                height: 269px;
                font-weight: 400;
                flex-direction: column;
                text-align: left;
                text-transform: none;
                font-size: 25px;
                line-height: 30px;
                padding-left: 75px;
                padding-right: 60px;
                right: 192px;
                top: -190px;
            }
            h2 {
                color: #49b8ef;
                margin-top: 0 !important;
                margin-bottom: 45px !important;
            }
            p {
                margin-bottom: 35px;
            }
        }
    }
    #sumate-a-dirwa {
        margin-bottom: 180px;
        .container {
            max-width: 1152px;
            width: 100%;
            background: #49b8ef;
            text-align: center;
            color: white;
            margin: auto;
            padding: 0 100px;
            padding-bottom: 55px;
            padding-top: 68px;
            margin-bottom: -100px;
            h2 {
                color: #fff;
                font-size: 50px;
                line-height: 50px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 900;
                margin-bottom: 16px !important;
            }
            p {
                font-size: 20px;
                line-height: 30px;
            }
            .btn {
                font-size: 24px !important;
                margin-top: 45px !important;
            }
        }
    }
    @media screen and (max-width: 1950px) {
        #section-1-interna {
            margin-bottom: 230px!important;
        }
        #section-2-interna {
            margin-bottom: 0 !important;
            .section-img {
                .effect-y-300 {
                    right: 315px;
                    top: 160px;
                }
            }
        }
        #nuestros-lideres {
            margin-top: -91px !important;
        }
    }
    @media screen and (max-width: 1450px) {
        .img-desc.icon-box {
            width: 150px!important;
            height: 150px!important;
            img {
                width: 60%!important;
            }
        }
        #section-1-interna {
            margin-bottom: 190px!important;
        }
        #section-2-interna {
            .section-img .effect-y-300 {
                right: 130px;
                top: 142px;
            }
            .section-text {
                padding-bottom: 100px;
                h2 {
                    margin-bottom: 25px!important;
                }
                strong {
                    font-size: 16px;
                    line-height: 24px;
                }
                ul {
                    margin-top: 22px;
                    margin-bottom: 0 !important;
                    li {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        #nuestros-lideres {
            margin-top: -61px!important;
            .container {
                h2 {
                    padding: 34px 20px;
                    font-size: 32px!important;
                    line-height: 32px!important;
                }
                .group-lideres {
                    margin-top: 70px;
                    grid-row-gap: 30px;
                    .item {
                        padding: 0px 20px;
                        h3 {
                            font-size: 28px!important;
                            line-height: 28px!important;
                            margin-bottom: 20px;
                        }
                        strong {
                            font-size: 16px;
                            line-height: 24px;
                            margin-bottom: 20px;
                        }
                        p {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
        #nuestra-cultura .section-text {
            padding-left: 60px;
            padding-top: 60px;
            padding-right: 60px!important;
            padding-bottom: 20px;
            bottom: -156px;
            .img-desc {
                padding-left: 50px;
                padding-right: 40px;
                max-width: 300px;
                font-size: 20px!important;
                line-height: 26px!important;
                height: auto !important;
                padding-top: 35px;
                padding-bottom: 35px;
                top: -120px;
            }
        }
        #sumate-a-dirwa {
            .container {
                padding: 45px 200px 45px;
                .section-title {
                    font-size: 32px!important;
                    line-height: 32px!important;
                }
                p {
                    font-size: 16px;
                    line-height: 24px;
                }
                .btn {
                    font-size: 18px!important;
                    margin-top: 30px!important;
                }
            }
        }
    }
    @media screen and (max-width: 1240px) {
        #sumate-a-dirwa {
            padding: 0 30px;
        }
        #nuestra-cultura {
            .section-text {
                bottom: -230px;
                .img-desc {
                    padding: 30px 30px 30px 30px;
                    top: auto;
                    bottom: -124px;
                    right: -253px;
                }
            }
        }
    }
    @media screen and (max-width: 1040px) {
        #nuestros-lideres .container .group-lideres {
            grid-template-columns: 1fr 1fr 1fr;
        }
        #nuestra-cultura .section-text {
            padding-left: 40px;
            padding-top: 50px;
            padding-right: 40px!important;
            padding-bottom: 10px;
            bottom: -270px;
            .img-desc {
                right: -280px;
            }
            h2 {
                margin-bottom: 25px!important;
            }
            p {
                margin-bottom: 25px;
            }
        }
    }
    @media screen and (max-width: 900px) {
        #section-1-interna {
            margin-bottom: 110px!important;
        }
        #section-2-interna {
            .section-img {
                .effect-y-300 {
                    top: auto !important;
                    bottom: 0 !important;
                    right: 100px;
                }
            }
            .section-text {
                strong {
                    font-size: 15px;
                    line-height: 24px;
                    text-align: center;
                }
            }
        }
        #nuestros-lideres {
            .container {
                h2 {
                    font-size: 28px!important;
                    line-height: 28px!important;
                    width: calc(100% - 40px);
                }
                .group-lideres {
                    grid-template-columns: 1fr 1fr;
                    grid-row-gap: 40px;
                    margin-top: 50px;
                    .item {
                        h3 {
                            font-size: 24px!important;
                            line-height: 24px!important;
                            margin-bottom: 15px;
                        }
                        strong {
                            font-size: 15px;
                            line-height: 24px;
                            margin-bottom: 15px;
                        }
                        p {
                            font-size: 15px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        #nuestra-cultura {
            margin-bottom: -25px !important;
            .section-text {
                bottom: 0;
                .img-desc {
                    font-size: 15px!important;
                    line-height: 18px!important;
                    padding: 20px!important;
                    bottom: auto !important;
                    left: 170px !important;
                    top: -60px !important;
                }
            }
        }
        #sumate-a-dirwa {
            margin-bottom: 140px!important;
            .container {
                padding: 40px 20px;
                .btn {
                    font-size: 16px!important;
                }
            }
        }
    }
    @media screen and (max-width: 640px) {
        #nuestros-lideres {
            .container {
                h2 {
                    padding: 25px 20px;
                    font-size: 24px!important;
                    line-height: 24px!important
                }
                .group-lideres {
                    grid-template-columns: 1fr;
                    margin-top: 30px;
                    text-align: center;
                    .item {
                        h3 {
                            font-size: 20px!important;
                            line-height: 20px!important;
                            margin-bottom: 5px;
                        }
                        strong {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
        #nuestra-cultura {
            margin-bottom: 30px!important;
            bottom: -80px;
            .section-text {
                padding-bottom: 10px !important;
            }
        }
        #sumate-a-dirwa {
            .container {
                .section-title {
                    font-size: 24px!important;
                    line-height: 24px!important;
                }
                p {
                    font-size: 15px;
                    line-height: 24px;
                }
                .btn {
                    font-size: 15px!important;
                    height: 50px;
                    line-height: 50px;
                }
            }
        }
    }
    @media screen and (max-width: 499px) {
        .img-desc.icon-box {
            width: 90px!important;
            height: 90px!important;
        }
        #section-2-interna .section-img .effect-y-300 {
            bottom: 15px!important;
            right: auto;
            left: 70px;
        }
        #nuestra-cultura .section-text {
            bottom: -60px;
            padding-right: 20px !important;
            .img-desc {
                left: 50%!important;
                top: -36px!important;
            }
        }
    }
}