    .site-header {
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        * {
            transition: .5s;
        }
        &.scroll {
            .menu-toggle-container {
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60px;
                height: 60px;
                .toggle-container {
                    transform: scale(.8);
                }
            }
            .globe {
                width: 60px;
                height: 60px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .icon {
                    transform: scale(.8);
                }
            }
        }
    }
    
    header {
        .menu-toggle-container-map {
            position: relative;
            padding: 17px 0 0 15px;
            background: white !important;
            transition: 0s;
            width: 60px;
            height: 60px;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 90;
            .toggle-container-map {
                margin-top: 5px;
                .text {
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0;
                }
                .toggle-bar:first-child {
                    transform: rotate(45deg) translate(4px, 5px);
                }
                .toggle-bar:nth-child(2) {
                    opacity: 0;
                    visibility: hidden;
                    transition: initial;
                }
                .toggle-bar:nth-child(3) {
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
    }
    
    .main-container-ubicacion {
        min-height: 100vh;
        height: 100vh;
        background-image: radial-gradient( circle, #3d4246, #222529);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        transform: translateX(100%);
        transition: .3s;
        &.active-menu {
            transform: translateX(0%);
        }
        .accordion {
            display: none;
        }
        .no-accor {
            display: block;
        }
        // visibility: hidden;
        .wrapper {
            position: relative;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            height: 100%;
            max-width: 1368px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;
        }
        .container--box {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .container--img {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            z-index: -10;
            width: 100%;
        }
        .mapa {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;
        }
        .box {
            background: rgba(54, 59, 63, .7);
            width: 458px;
            color: #fff;
            padding: 34px 40px;
            border: 1px solid rgba(24, 26, 27, .3);
            box-shadow: 8px 8px 100px rgba(0, 0, 0, .7);
            transition: .3s;
            &.box--brasil:hover {
                background: rgba(196, 160, 13, .7);
            }
            &.box--usa:hover {
                background: rgba(0, 112, 195, .75);
            }
            &.box--argentina:hover {
                background: rgba(159, 83, 216, .75);
            }
            &.box--chile:hover {
                background: rgba(255, 0, 88, .75);
            }
            &.box--mexico:hover {
                background: rgba(0, 227, 154, .75);
            }
        }
        .box .font-700,
        .box .font-300 {
            width: 100%;
        }
        .active {
            background: rgba(196, 160, 13, .7);
            margin-right: 230px;
        }
        .zona {
            color: #fff;
            padding: 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-content: space-around;
            position: absolute;
            left: 0;
            top: 145px;
            line-height: 1.5;
            padding: 0;
            align-items: center;
        }
        .zona__icon {
            width: 23px;
            height: 23px;
            background: #0066ad;
            border-radius: 50%;
            margin-right: 10px;
        }
        @media screen and (max-width: 1440px) {
            .wrapper {
                font-size: 11px;
                max-width: 1040px;
            }
            .zona {
                left: 50px;
                top: 20px;
            }
            .mapa {
                width: 100%;
            }
            .box {
                width: 330px;
                padding: 18px 25px;
            }
            .container--box {
                margin-bottom: 15px;
            }
        }
        @media screen and (max-width: 1440px) and (max-height: 600px) {
            .wrapper {
                flex-wrap: wrap;
                align-items: flex-end;
                justify-content: space-around;
            }
            .active {
                background: rgba(196, 160, 13, .7);
                margin-right: 0px;
            }
            .container--box {
                margin: 10px 0;
            }
        }
        @media screen and (min-width: 1440px) and (max-height: 915px) {
            .wrapper {
                flex-wrap: wrap;
                align-items: flex-end;
                justify-content: space-around;
            }
            .active {
                background: rgba(196, 160, 13, .7);
                margin-right: 0px;
            }
            .container--box {
                margin: 10px 0;
            }
        }
        @media screen and (max-width: 1080px) {
            .wrapper {
                padding: 0 20px;
            }
            .active {
                margin-right: 100px;
            }
            .zona {
                left: 100px;
            }
        }
        @media screen and (max-width: 640px) {
            height: 100%;
            overflow-y: scroll;
            .active {
                margin-right: 0;
            }
            .wrapper {
                align-items: center;
                justify-content: flex-end;
            }
            .zona {
                left: 50%;
                transform: translateX(-50%);
                top: 20px;
            }
            .mapa {
                width: 100%;
                top: 147px;
                opacity: .5;
            }
            .container--box {
                &:first-child {
                    margin-top: 190px;
                }
            }
            .box.box--usa {
                margin-top: 230px;
            }
            .box.box--chile {
                margin-bottom: 40px;
            }
            .no-accor {
                display: none;
            }
            .accordion {
                display: block;
                margin-right: 0px;
                margin-bottom: 15px;
                .active {
                    margin-right: 0 !important;
                }
                .accordion-item {
                    .icon {
                        left: 10px;
                        width: 20px;
                        height: 20px;
                        &:after,
                        &:before {
                            width: .6rem;
                            height: 0.1rem;
                        }
                    }
                    .heading {
                        padding: 14px 14px 14px 48px;
                    }
                }
            }
        }
        @media screen and (max-width: 640px) and (max-height: 600px) {
            .wrapper {
                flex-wrap: nowrap;
            }
        }
        @media screen and (max-width: 380px) {
            .container--box {
                width: 100%;
            }
            .box {
                text-align: center;
                width: 100%;
            }
            .zona {
                // top: 90px;
                display: flex;
                width: 100%;
                justify-content: center;
            }
        }
        @media screen and (min-width: 720px) and (max-height: 600px) {
            .wrapper {
                align-items: flex-end;
                .no-accor {
                    display: none;
                }
                .accordion {
                    display: block;
                    margin-right: 30px;
                    .active {
                        margin-right: 0 !important;
                    }
                }
            }
        }
    }
    
    .main-container-sumate,
    .main-container-contacto {
        min-height: 100vh;
        height: 100vh;
        background: white;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 100;
        transform: translateX(100%);
        transition: .3s;
        &.active-menu {
            transform: translateX(0%);
        }
        &>img {
            width: 100%;
            height: 100%;
            position: absolute;
            object-fit: cover;
            object-position: right center;
        }
        .section-form {
            height: 100%;
            width: 59.077%;
            background: rgba(132, 141, 152, .3);
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 192px;
            z-index: 7;
            position: relative;
            .cel,
            .planta {
                width: auto;
                position: absolute;
                right: 0;
                transform: translate(50%, -50%);
            }
            .planta {
                top: 20%;
            }
            .cel {
                top: 50%;
            }
            .content-form {
                width: 100%;
                max-width: 684px;
                text-align: right;
                img {
                    width: auto;
                    display: block;
                    margin: auto;
                    margin-bottom: 100px;
                }
                h4 {
                    font-size: 50px;
                    font-weight: 900;
                    line-height: 60px;
                    color: #626972;
                    text-align: left;
                }
                form {
                    margin-top: 50px;
                    margin-bottom: 20px;
                    &>div {
                        display: flex;
                        height: 50px;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        input,
                        .select-field {
                            width: 398px;
                            border: 1px solid #9fa1a5;
                            height: 100%;
                            padding-left: 20px;
                            font-size: 20px;
                            line-height: 30px;
                            color: #414d5b;
                            font-family: 'Termina';
                            outline: none;
                        }
                        label {
                            font-size: 20px;
                            line-height: 30px;
                            color: #414d5b;
                        }
                        textarea {
                            width: 398px;
                            height: 113px;
                            align-self: end;
                            padding-left: 20px;
                            font-size: 20px;
                            line-height: 26px;
                            color: #414d5b;
                            font-family: Termina;
                            outline: none;
                            resize: none;
                            border: 1px solid #9fa1a5;
                            padding-top: 10px;
                        }
                    }
                }
                .btn {
                    display: inline-block;
                    position: relative;
                    transition: .1s !important;
                    * {
                        transition: .1s !important;
                    }
                    .lds-ellipsis {
                        position: absolute;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        display: none;
                    }
                    &.sending {
                        background: #49b8ef !important;
                    }
                }
                .notice-success {
                    color: #626972;
                    font-size: 32px;
                    text-align: center;
                    line-height: 40px;
                    font-weight: 900;
                    text-transform: uppercase;
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1950px) {
            .section-form {
                padding-right: 0;
                .content-form {
                    width: 100%;
                    max-width: 684px;
                    margin: auto;
                    img {
                        margin: auto auto 60px;
                        width: 300px;
                    }
                    h4 {
                        line-height: 42px;
                        font-size: 36px;
                    }
                    form {
                        margin-top: 40px;
                        &>div {
                            label {
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1450px) {
            .section-form {
                .content-form {
                    img {
                        width: 240px;
                        margin: auto auto 40px;
                    }
                    h4 {
                        font-size: 32px;
                        line-height: 32px;
                    }
                    form {
                        &>div {
                            height: 45px;
                            label {
                                font-size: 16px;
                                line-height: 24px;
                            }
                            input,
                            .select-field,
                            textarea {
                                font-size: 16px;
                                line-height: 24px;
                            }
                            .select-field .dropdown .select {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1280px) {
            .section-form .content-form {
                padding: 0 20px;
                max-width: 100%;
                h4 {}
                form {
                    &>div {
                        label {
                            width: 250px;
                            text-align: left;
                        }
                        textarea,
                        input,
                        .select-field {
                            width: calc(100% - 250px);
                        }
                    }
                }
                .notice-success {
                    font-size: 26px;
                    line-height: 30px;
                }
            }
        }
        @media screen and (max-width: 900px) {
            &>img {
                opacity: 0.1;
            }
            .section-form {
                width: 100%;
                .content-form {
                    h4 {
                        text-align: center;
                        font-size: 24px;
                        line-height: 24px;
                    }
                    img {
                        width: 200px;
                        margin: auto auto 30px;
                    }
                    form {
                        margin-top: 30px;
                        &>div {
                            label {
                                font-size: 15px;
                                width: 220px;
                            }
                            textarea,
                            input,
                            .select-field {
                                width: calc(100% - 220px);
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 499px) {
            .section-form .content-form {
                text-align: center;
                img {
                    width: 160px;
                    margin: auto auto 20px;
                }
                form {
                    margin-top: 20px;
                    margin-bottom: 30px;
                    &>div {
                        height: auto;
                        flex-direction: column;
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                        label {
                            width: 100% !important;
                            text-align: center;
                        }
                        textarea,
                        input,
                        .select-field {
                            height: 35px;
                            width: 100%;
                            max-width: 300px;
                            .dropdown .select>i {
                                width: 34px;
                                height: 34px;
                            }
                        }
                        textarea {
                            height: 70px;
                            display: block;
                            margin: auto;
                        }
                        .btn {
                            height: 50px;
                            line-height: 50px;
                        }
                    }
                }
                .notice-success {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
        @media screen and (max-width: 499px) and (max-height: 600px) {
            overflow-y: scroll;
            .section-form {
                padding: 70px 0px;
                min-height: initial !important;
                height: auto !important;
            }
        }
    }
    
    .main-container-contacto {
        background: rgb(255, 255, 255);
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(177, 177, 177, 1) 61%, rgba(173, 173, 173, 1) 90%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(177, 177, 177, 1) 61%, rgba(173, 173, 173, 1) 90%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 31%, rgba(177, 177, 177, 1) 61%, rgba(173, 173, 173, 1) 90%);
        .form_contacto {
            bottom: 0;
            width: auto;
            position: absolute;
            right: 0;
            transform: translateX(34%);
            height: auto;
        }
        .section-form .content-form {
            &>p {
                font-size: 20px;
                line-height: 30px;
                color: #414d5b;
                margin-top: 17px;
            }
            form>div:last-child {
                margin-bottom: 82px;
            }
        }
        @media screen and (max-width: 1950px) {
            .form_contacto {
                transform: translateX(50%);
            }
            .section-form {
                .planta {
                    width: 220px;
                }
                .cel {
                    width: 180px;
                }
            }
        }
        @media screen and (max-width: 1450px) {
            .form_contacto {
                transform: translateX(36%);
                width: 50%;
            }
            .section-form {
                .planta {
                    width: 115px;
                }
                .cel {
                    width: 100px;
                }
                .content-form {
                    form {
                        margin-top: 20px;
                    }
                    &>p {
                        font-size: 16px;
                        line-height: 24px;
                        text-align: left;
                    }
                }
            }
        }
        @media screen and (max-width: 1280px) {
            .section-form {
                .planta {
                    right: -20px;
                }
                .cel {
                    right: -33px;
                }
            }
        }
        @media screen and (max-width: 900px) {
            background: #d8dcdf;
            .form_contacto {
                display: none;
            }
            .section-form {
                .planta {
                    transform: translate(0);
                    top: 20px;
                    right: 10px;
                    width: 80px;
                    opacity: .2;
                }
                .cel {
                    transform: translate(0);
                    top: auto !important;
                    right: auto;
                    width: 90px;
                    bottom: -5px !important;
                    left: 10px;
                    opacity: .2;
                }
            }
        }
        @media screen and (max-height: 1020px) {
            .section-form .cel {
                top: 70%;
            }
        }
    }
    
    .menu-toggle-container {
        width: 90px;
        height: 88px;
        padding: 34px 0 0 34px;
        background: #ffffff;
        z-index: 2;
        // transition: initial;
        transition: .3s;
    }
    
    .toggle-container {
        transition: 1s;
        cursor: pointer;
        .text {
            font-size: 9px;
            letter-spacing: -0.3px;
            color: #414d5b;
            position: relative;
            left: -1px;
        }
        &.open {
            .toggle-bar {
                &:first-child {
                    transform: rotate(45deg) translate(4px, 5px);
                }
                &:nth-child(2) {
                    opacity: 0;
                    visibility: hidden;
                    transition: initial;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
    }
    
    .toggle-bar {
        width: 33px;
        height: 3px;
        background: #414d5b;
        margin-bottom: 5px;
        &:nth-child(3) {
            margin-bottom: 1px;
        }
    }
    
    .globe {
        width: 90px;
        height: 88px;
        padding: 34px 34px 0 0px;
        cursor: pointer;
        background: #ffffff;
        display: flex;
        justify-content: flex-end;
        .icon {
            width: 36px;
            height: 36px;
            transition: 1s;
        }
    }
    
    .menu {
        position: fixed;
        top: 0;
        width: 100%;
        visibility: hidden;
        &--active {
            visibility: visible;
        }
        &__left,
        &__right {
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            float: left;
            // width: 50%;
            height: 100vh;
            overflow: hidden;
        }
        &__right {
            margin: 0;
            padding: 0;
            background: white;
            list-style-type: none;
            transform: translate3d(0, -100%, 0);
            transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
            width: 38.125%;
            img {
                width: auto;
            }
            h4 {
                color: #626972;
                font-size: 30px;
                line-height: 85px;
                text-transform: uppercase;
                font-weight: 700;
                margin-top: 115px;
                margin-bottom: 23px;
            }
            ul {
                li {
                    text-align: center;
                    margin-bottom: 50px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    a {
                        color: #626972;
                        font-size: 30px;
                        line-height: 36px;
                        transition: .3s;
                        font-weight: 300;
                        text-align: center;
                        &:hover,
                        &.active {
                            color: #61b2e5;
                        }
                        &.active {
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                }
            }
            &--active {
                transform: translate3d(0, 0, 0) !important;
            }
        }
        &__left {
            background: #ced1d6;
            transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
            transform: translate3d(0, 100%, 0);
            width: 61.875%;
            &--active {
                transform: translate3d(0, 0, 0) !important;
            }
            img {
                width: auto;
            }
            &>div {
                display: flex;
                flex-direction: column;
                max-width: 622px;
                align-items: center;
                margin-top: 130px;
                a {
                    font-size: 44px;
                    color: white;
                    line-height: 85px;
                    font-weight: 800;
                    text-align: center;
                    text-transform: uppercase;
                    line-height: 50px;
                    margin-bottom: 35px;
                    transition: .3s;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover,
                    &.active {
                        color: #626972;
                    }
                }
            }
        }
        &__item {
            transform: translate3d(500px, 0, 0);
            transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
            &--active {
                transform: translate3d(0, 0, 0);
            }
        }
        // add delay to each menu item
        @for $x from 1 through 6 {
            .menu__item:nth-child(#{$x}) {
                transition-delay: $x * .1s;
            }
        }
    }
    
    @media screen and (max-width: 1440px) {
        .site-header {
            .menu {
                &__right {
                    img {
                        width: 45px;
                    }
                    h4 {
                        margin-top: 54px;
                        margin-bottom: 15px;
                    }
                    ul {
                        li {
                            margin-bottom: 25px;
                            a {
                                font-size: 24px;
                                line-height: 30px;
                            }
                        }
                    }
                }
                &__left {
                    img {
                        width: 240px;
                    }
                    &>div {
                        margin-top: 50px;
                        a {
                            font-size: 32px;
                            line-height: 38px;
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 1080px) {
        .site-header {
            .menu {
                &__right {
                    img {
                        width: 30px;
                    }
                    h4 {
                        font-size: 22px;
                        line-height: 60px;
                        margin-top: 30px;
                        margin-bottom: 30px;
                    }
                    ul {
                        li {
                            margin-bottom: 18px;
                            a {
                                font-size: 18px;
                                line-height: 25px;
                            }
                        }
                    }
                }
                &__left {
                    img {
                        width: 190px;
                    }
                    &>div {
                        margin-top: 60px;
                        a {
                            font-size: 24px;
                            line-height: 28px;
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 900px) {
        .site-header {
            * {
                transition: .5s;
            }
        }
    }
    
    @media screen and (max-width: 767px) {
        .site-header {
            .menu {
                &__right {
                    transform: translate3d(0, 100%, 0);
                    width: 100%;
                    height: auto;
                    padding: 40px 0;
                    img {}
                    h4 {}
                    ul {
                        li {
                            a {}
                        }
                    }
                }
                &__left {
                    transform: translate3d(0, -100%, 0);
                    width: 100%;
                    height: auto;
                    padding-top: 80px;
                    padding-bottom: 40px;
                    img {}
                    &>div {
                        a {}
                    }
                }
            }
        }
        body.no-scroll {
            .menu.menu--active {
                overflow-y: scroll;
                height: 100vh;
            }
        }
    }
    
    @media screen and (max-width: 640px) {
        .menu-toggle-container {
            padding: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            .toggle-container {
                transform: scale(0.8);
            }
        }
        .globe {
            width: 60px;
            height: 60px;
            padding: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            .icon {
                width: 30px;
                height: 30px;
                -webkit-transform: scale(0.8)!important;
                -ms-transform: scale(0.8)!important;
                transform: scale(0.8)!important;
            }
        }
    }
    
    @media screen and (max-width: 499px) {
        .site-header {
            .menu__left {
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 50px;
                padding-bottom: 20px;
                img {
                    width: 140px;
                }
                &>div {
                    margin-top: 50px;
                    a {
                        font-size: 20px;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                }
            }
            .menu__right {
                padding-left: 20px;
                padding-right: 20px;
                h4 {
                    font-size: 18px;
                    line-height: 50px;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                ul {
                    li {
                        margin-bottom: 14px;
                        a {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }
    
    @media screen and (max-width: 480px) {
        main {
            overflow: hidden;
        }
    }