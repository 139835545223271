@charset "UTF-8";
/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
  * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
  */
audio,
canvas,
video {
  display: inline-block; }

/**
  * Prevent modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
  * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
  * Known issue: no IE 6 support.
  */
[hidden] {
  display: none; }

/* ==========================================================================
    Base
    ========================================================================== */
/**
  * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
  *    `em` units.
  * 2. Prevent iOS text size adjust after orientation change, without disabling
  *    user zoom.
  */
html {
  font-size: 100%;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
  * Address `font-family` inconsistency between `textarea` and other form
  * elements.
  */
html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

/**
  * Address margins handled incorrectly in IE 6/7.
  */
blockquote,
body {
  margin: 0; }

/* ==========================================================================
    Links
    ========================================================================== */
/**
  * Address `outline` inconsistency between Chrome and other browsers.
  */
a {
  text-decoration: none; }

a:focus {
  outline: thin dotted; }

/**
  * Improve readability when focused and also mouse hovered in all browsers.
  */
a:active,
a:hover {
  outline: 0; }

/* ==========================================================================
    Typography
    ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal; }

/**
  * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
  */
abbr[title] {
  border-bottom: 1px dotted; }

/**
  * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
  */
b,
strong {
  font-weight: bold; }

/**
  * Address styling not present in Safari 5 and Chrome.
  */
dfn {
  font-style: italic; }

/**
  * Address differences between Firefox and other browsers.
  * Known issue: no IE 6/7 normalization.
  */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
  * Address styling not present in IE 6/7/8/9.
  */
mark {
  background: #ff0;
  color: #000; }

/**
  * Address margins set differently in IE 6/7.
  */
pre {
  margin: 0; }

/**
  * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
  */
code,
kbd,
pre,
samp {
  font-family: monospace;
  _font-family: 'courier new', monospace; }

/**
  * Address CSS quotes not supported in IE 6/7.
  */
q {
  quotes: none; }

/**
  * Address `quotes` property not supported in Safari 4.
  */
q:before,
q:after {
  content: '';
  content: none; }

/**
  * Address inconsistent and variable font size in all browsers.
  */
small {
  font-size: 80%; }

/**
  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
  */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* ==========================================================================
    Lists
    ========================================================================== */
/**
  * Address margins set differently in IE 6/7.
  */
dd,
dl,
menu,
ol,
ul {
  margin: 0; }

/**
  * Address paddings set differently in IE 6/7.
  */
menu,
ol,
ul {
  padding: 0;
  list-style: none;
  list-style-image: none; }

/* ==========================================================================
    Embedded content
    ========================================================================== */
/**
  * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
  * 2. Improve image quality when scaled in IE 7.
  */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
  vertical-align: top; }

/**
  * Correct overflow displayed oddly in IE 9.
  */
svg:not(:root) {
  overflow: hidden; }

/* ==========================================================================
    Figures
    ========================================================================== */
/**
  * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
  */
figure {
  margin: 0; }

/* ==========================================================================
    Forms
    ========================================================================== */
/**
  * Correct margin displayed oddly in IE 6/7.
  */
form {
  margin: 0; }

/**
  * Define consistent border, margin, and padding.
  */
p,
fieldset {
  border: none;
  margin: 0;
  padding: 0; }

/**
  * 1. Correct color not being inherited in IE 6/7/8/9.
  * 2. Correct text not wrapping in Firefox 3.
  */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */ }

/**
  * 1. Correct font size not being inherited in all browsers.
  * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
  *    and Chrome.
  * 3. Improve appearance and consistency in all browsers.
  */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */ }

/**
  * Address Firefox 3+ setting `line-height` on `input` using `!important` in
  * the UA stylesheet.
  */
button,
input {
  line-height: normal; }

/**
  * Address inconsistent `text-transform` inheritance for `button` and `select`.
  * All other form control elements do not inherit `text-transform` values.
  * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
  * Correct `select` style inheritance in Firefox 4+ and Opera.
  */
button,
select {
  text-transform: none; }

/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *    and `video` controls.
  * 2. Correct inability to style clickable `input` types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *    `input` and others.
  * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
  *    Known issue: inner spacing remains in IE 6.
  */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
  * Re-set default cursor for disabled elements.
  */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
  * 1. Address box sizing set to content-box in IE 8/9.
  * 2. Remove excess padding in IE 8/9.
  */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
  * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
  *    (include `-moz` to future-proof).
  */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
  * Remove inner padding and search cancel button in Safari 5 and Chrome
  * on OS X.
  */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
  * Remove inner padding and border in Firefox 3+.
  */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
  * 1. Remove default vertical scrollbar in IE 6/7/8/9.
  * 2. Improve readability and alignment in all browsers.
  */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/* ==========================================================================
    Tables
    ========================================================================== */
/**
  * Remove most spacing between table cells.
  */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Clases generales */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Termina'; }

@font-face {
  font-family: 'Termina';
  src: url("fonts/Termina-Light.otf") format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Termina';
  src: url("fonts/Termina-Regular.otf") format("truetype");
  font-weight: normal; }

@font-face {
  font-family: 'Termina';
  src: url("fonts/Termina-Medium.otf") format("truetype");
  font-weight: 600; }

@font-face {
  font-family: 'Termina';
  src: url("fonts/Termina-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Termina';
  src: url("fonts/Termina-Heavy.otf") format("truetype");
  font-weight: 900; }

.no-scroll {
  overflow-y: hidden; }

.inner-container {
  max-width: 1920px;
  margin: 0 auto; }

.clearfix:before,
.clearfix:after {
  content: "\0020";
  display: block;
  height: 0; }

.clearfix:after {
  clear: both; }

.clearfix {
  zoom: 1; }

.cover-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }

.bg-transparent {
  background: transparent !important; }

body {
  overflow-x: hidden; }

button,
input {
  border: 0;
  margin: 0;
  padding: 0;
  display: block; }

img {
  width: 100%;
  height: auto;
  display: block; }

a {
  color: inherit; }

.btn {
  height: 70px;
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 33px;
  border: 2px solid transparent;
  transition: .3s; }
  .btn.btn-green {
    background: #5dc295;
    border-color: #5dc295; }
    .btn.btn-green:hover {
      background: white;
      color: #5dc295; }
  .btn.btn-pink {
    background: #e40039;
    border-color: #e40039; }
    .btn.btn-pink:hover {
      background: white;
      color: #e40039; }
  .btn.btn-blue {
    background: #353d44;
    border-color: #353d44; }
    .btn.btn-blue:hover {
      background: white;
      color: #353d44; }
  .btn.btn-blue-light {
    background: #49b8ef;
    border-color: #49b8ef; }
    .btn.btn-blue-light:hover {
      background: white;
      color: #49b8ef; }
  .btn.btn-yellow {
    background: #e9bc00;
    border-color: #e9bc00; }
    .btn.btn-yellow:hover {
      background: white;
      color: #e9bc00; }

.btn:hover {
  transition: .3s; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.clear-me {
  clear: both; }

/* ACORDEON */
.accordion {
  width: 100%;
  max-width: 350px; }

.accordion-item {
  position: relative; }

.accordion-item.active {
  background: transparent !important; }
  .accordion-item.active .icon {
    background: #fefefe; }
  .accordion-item.active .heading.usa {
    background: rgba(0, 112, 195, 0.75); }
  .accordion-item.active .heading.mexico {
    background: rgba(0, 227, 154, 0.75); }
  .accordion-item.active .heading.brasil {
    background: rgba(196, 160, 13, 0.7); }
  .accordion-item.active .heading.argentina {
    background: rgba(159, 83, 216, 0.75); }
  .accordion-item.active .heading.chile {
    background: rgba(255, 0, 88, 0.75); }

.accordion-item.active .icon:before {
  background: #c1bb9e; }

.accordion-item.active .icon:after {
  width: 0; }

.accordion-item .heading {
  display: block;
  background: rgba(54, 59, 63, 0.7);
  padding: 34px 40px;
  border: 1px solid rgba(24, 26, 27, 0.3);
  box-shadow: 8px 8px 100px rgba(0, 0, 0, 0.7);
  transition: .3s;
  color: white;
  margin: 0;
  padding: 18px 25px;
  margin-right: 0;
  width: 100%;
  padding-left: 60px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative; }

.accordion-item .heading:hover .icon:before,
.accordion-item .heading:hover .icon:after {
  background: #c1bb9e; }

.accordion-item .icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 38px;
  height: 38px;
  border: 2px solid #fefefe;
  border-radius: 3px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }

.accordion-item .icon:before,
.accordion-item .icon:after {
  content: '';
  width: 1.25rem;
  height: 0.25rem;
  position: absolute;
  border-radius: 3px;
  left: 50%;
  top: 50%;
  transition: 0.3s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: white; }

.accordion-item .icon:after {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
  z-index: -1; }

.accordion-item .content {
  display: none;
  background: #363b3f; }
  .accordion-item .content br {
    display: block !important; }

.accordion-item .content p {
  margin-top: 0;
  padding: 20px 20px;
  color: white; }

/* SELECTS */
/* ===== Select Box ===== */
.select-field {
  background: white;
  padding-left: 0 !important;
  outline: none; }
  .select-field .dropdown {
    display: inline-block;
    transition: all .5s ease;
    position: relative;
    color: #474747;
    height: 100%;
    text-align: left;
    width: 100%;
    outline: none; }
  .select-field .dropdown .select {
    cursor: pointer;
    display: block;
    height: 100%;
    font-size: 20px;
    line-height: 30px;
    color: #414d5b;
    font-family: Termina;
    outline: none;
    display: flex;
    align-items: center;
    padding-left: 20px;
    outline: none; }
  .select-field .dropdown .select > i {
    cursor: pointer;
    transition: all .3s ease-in-out;
    position: absolute;
    right: 0;
    background: #9fa1a5;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: white;
    outline: none; }
  .select-field .dropdown:hover {
    box-shadow: 0 0 4px #cccccc; }
  .select-field .dropdown:active {
    background-color: #f8f8f8; }
  .select-field .dropdown.active:hover,
  .select-field .dropdown.active {
    box-shadow: 0 0 4px #cccccc;
    border-radius: 5px 5px 0 0;
    background-color: #f8f8f8; }
  .select-field .dropdown .select > i:before {
    transition: .3s; }
  .select-field .dropdown.active .select > i:before {
    transform: rotate(-180deg); }
  .select-field .dropdown .dropdown-menu {
    position: absolute;
    background-color: #fff;
    width: 100%;
    left: 0;
    margin-top: 1px;
    box-shadow: 0 1px 2px #cccccc;
    border-radius: 0 1px 5px 5px;
    overflow: hidden;
    display: none;
    max-height: 180px;
    overflow-y: auto;
    z-index: 9; }
  .select-field .dropdown .dropdown-menu li {
    padding: 10px;
    transition: all .2s ease-in-out;
    cursor: pointer;
    padding-left: 20px; }
  .select-field .dropdown .dropdown-menu {
    padding: 0;
    list-style: none; }
  .select-field .dropdown .dropdown-menu li:hover {
    background-color: #f2f2f2; }
  .select-field .dropdown .dropdown-menu li:active {
    background-color: #e2e2e2; }

.mobile-499 {
  display: none; }

.desktop-499 {
  display: block; }

/* Posicionamientos */
.block {
  display: block; }

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.center-me-x-y {
  position: absolute;
  top: 50%;
  left: 50%;
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.center-me-x {
  position: absolute;
  left: 50%;
  -o-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.center-me-y {
  position: absolute;
  top: 50%;
  left: 0;
  -o-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

/* Clases para fuentes */
.font-300 {
  font-weight: 300; }

.font-400 {
  font-weight: 400; }

.font-700 {
  font-weight: 700; }

.font-900 {
  font-weight: 900; }

.uppercase {
  text-transform: uppercase; }

/* Colores */
.purple {
  color: #74359b; }

.orange {
  color: #e9bd30; }

.orange-bg {
  background-color: #e9bd30; }

.green {
  color: #5dc295; }

.green-bg {
  background-color: #5dc295; }

.error input,
.error .select-field {
  border-color: #ff3b3b !important; }

.error label {
  color: #ff3b3b !important; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(19px, 0); } }

.img-l-board,
.img-r-board,
.img-t-board {
  position: absolute;
  background: #fff;
  transform: scaleX(100);
  top: -1px; }

.img-l-board {
  width: 10px;
  height: 201%;
  top: 0;
  left: -2px;
  transform-origin: left;
  z-index: 3;
  transform: scaleX(40);
  height: 100%; }

.img-r-board {
  width: 10px;
  height: 201%;
  right: -2px;
  top: 0;
  transform-origin: right;
  z-index: 1;
  transform: scaleX(40);
  height: 100%; }

.img-t-board {
  height: 10px;
  width: 100%;
  left: 0;
  -webkit-transform: scaleY(100);
  transform: scaleY(100);
  -webkit-transform-origin: top;
  transform-origin: top;
  z-index: 3;
  transform: scaleY(25); }

section:not(#section-1-interna) {
  max-width: 1950px;
  margin: auto; }

.flex-container.data-right {
  justify-content: flex-start; }
  .flex-container.data-right .section-text {
    padding-left: 65px !important;
    max-width: 693px !important; }
  .flex-container.data-right.overlay-content {
    padding-right: 30px;
    justify-content: flex-end; }
    .flex-container.data-right.overlay-content .section-img {
      max-width: 60%;
      position: absolute;
      top: 0;
      right: auto;
      left: 0; }

.flex-container.data-left {
  justify-content: flex-end; }
  .flex-container.data-left .section-text {
    padding-right: 65px !important;
    max-width: 693px !important; }
  .flex-container.data-left.overlay-content {
    padding-left: 30px;
    justify-content: flex-start; }
    .flex-container.data-left.overlay-content .section-img {
      max-width: 60%;
      position: absolute;
      top: 0;
      right: 0; }

.flex-container .section-text img,
.flex-container .section-overlay-text img {
  width: auto !important; }

.flex-container .section-text .bold-text,
.flex-container .section-overlay-text .bold-text {
  font-size: 20px;
  line-height: 30px;
  display: block;
  font-weight: 700;
  margin-bottom: 25px !important; }

.flex-container .section-text ul,
.flex-container .section-overlay-text ul {
  padding-left: 0;
  margin-bottom: 30px; }
  .flex-container .section-text ul li,
  .flex-container .section-overlay-text ul li {
    font-size: 20px;
    line-height: 30px;
    position: relative; }
    .flex-container .section-text ul li:before,
    .flex-container .section-overlay-text ul li:before {
      content: '•';
      position: absolute;
      top: 0px;
      left: 0;
      font-weight: bold; }
    .flex-container .section-text ul li:first-letter,
    .flex-container .section-overlay-text ul li:first-letter {
      padding-left: 12px; }

.flex-container .section-text p,
.flex-container .section-overlay-text p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 30px; }

.flex-container .section-text .section-title,
.flex-container .section-text h3,
.flex-container .section-overlay-text .section-title,
.flex-container .section-overlay-text h3 {
  max-width: initial !important;
  line-height: 60px;
  margin-top: 62px; }
  .flex-container .section-text .section-title:first-child,
  .flex-container .section-text h3:first-child,
  .flex-container .section-overlay-text .section-title:first-child,
  .flex-container .section-overlay-text h3:first-child {
    margin-top: 0; }

.flex-container .section-text h3,
.flex-container .section-overlay-text h3 {
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 38px !important; }

.flex-container .section-text .section-title,
.flex-container .section-overlay-text .section-title {
  margin-bottom: 25px !important;
  font-size: 50px;
  font-weight: 900;
  line-height: 50px !important; }

.flex-container .section-text .btn,
.flex-container .section-overlay-text .btn {
  margin-top: 50px; }

.flex-container .section-img {
  position: relative; }

.section-vdo {
  width: 100%;
  max-width: 1152px; }

.sound-content {
  width: 60px;
  height: 60px;
  background: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61b2e5;
  font-size: 24px;
  border: 3px solid #61b2e5;
  cursor: pointer;
  transition: .5s;
  z-index: 10;
  right: 20px;
  bottom: 20px; }

.content-video {
  position: relative;
  padding-bottom: 71.25%;
  /* padding-top: 25px; */
  height: 0;
  overflow: hidden; }
  .content-video video {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .content-video .play_video {
    width: 60px;
    height: 60px;
    background: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #61b2e5;
    font-size: 24px;
    border: 3px solid #61b2e5;
    display: none;
    cursor: pointer;
    transition: .5s;
    z-index: 10; }
    .content-video .play_video.running {
      transform: scale(0.8);
      right: 10px;
      left: auto;
      bottom: 10px;
      top: auto; }
  .content-video .fa.fa-play {
    margin-left: 3px; }

@media screen and (max-width: 1440px) {
  .flex-container .section-text p,
  .flex-container .section-overlay-text p {
    font-size: 15px;
    line-height: 24px; }
  .flex-container .section-text ul li,
  .flex-container .section-overlay-text ul li {
    font-size: 15px;
    line-height: 24px; }
  .flex-container .section-text .section-title,
  .flex-container .section-text h3,
  .flex-container .section-overlay-text .section-title,
  .flex-container .section-overlay-text h3 {
    font-size: 35px !important;
    line-height: 42px !important;
    margin-bottom: 30px !important; }
    .flex-container .section-text .section-title br,
    .flex-container .section-text h3 br,
    .flex-container .section-overlay-text .section-title br,
    .flex-container .section-overlay-text h3 br {
      display: none; }
  .flex-container .section-text .bold-text,
  .flex-container .section-overlay-text .bold-text {
    font-size: 15px;
    line-height: 24px; }
  .flex-container .section-overlay-text {
    padding: 80px !important; }
  .btn {
    font-size: 16px !important;
    height: 60px;
    line-height: 60px;
    padding: 0 24px; }
  /* Titulos - subtitulos */
  .flex-container.data-right {
    justify-content: flex-start; }
    .flex-container.data-right .section-text {
      padding-left: 30px !important; }
  .flex-container.data-left {
    justify-content: flex-end; }
    .flex-container.data-left .section-text {
      padding-right: 30px !important; } }

@media screen and (max-width: 1280px) {
  .flex-container .section-text .section-title,
  .flex-container .section-text h3,
  .flex-container .section-overlay-text .section-title,
  .flex-container .section-overlay-text h3 {
    font-size: 28px !important;
    line-height: 30px !important;
    margin-bottom: 20px !important; } }

@media screen and (max-width: 900px) {
  .content-video .play_video {
    display: flex; }
  .sound-content {
    display: none; }
  .flex-container.data-left .section-img {
    order: -1 !important; }
  .flex-container.data-left .section-text {
    padding-right: 20px !important; }
  .flex-container.data-left.overlay-content {
    padding-left: 0px; }
    .flex-container.data-left.overlay-content .section-img {
      position: relative !important;
      width: 100%;
      max-width: 100% !important; }
    .flex-container.data-left.overlay-content .section-overlay-text {
      margin-top: 0 !important;
      width: calc( 100% - 60px) !important;
      max-width: 100% !important;
      left: 30px; }
  .flex-container.data-right .section-text {
    max-width: initial !important;
    padding-left: 20px !important; }
  .flex-container.data-right.overlay-content {
    padding-right: 0px !important;
    justify-content: flex-start; }
    .flex-container.data-right.overlay-content .section-img {
      position: relative !important;
      width: 100%;
      max-width: 100% !important;
      order: -1 !important; }
    .flex-container.data-right.overlay-content .section-overlay-text {
      margin-top: 0 !important;
      width: calc( 100% - 60px) !important;
      max-width: 100% !important;
      left: 30px; }
  .flex-container .section-text,
  .flex-container .section-overlay-text {
    padding-top: 50px !important; }
    .flex-container .section-text .bold-text,
    .flex-container .section-overlay-text .bold-text {
      margin-bottom: 20px !important; }
    .flex-container .section-text ul,
    .flex-container .section-overlay-text ul {
      max-width: 500px;
      margin: auto;
      text-align: left;
      margin-bottom: 20px !important; }
    .flex-container .section-text .section-title,
    .flex-container .section-text h3,
    .flex-container .section-overlay-text .section-title,
    .flex-container .section-overlay-text h3 {
      margin-bottom: 20px !important;
      margin-top: 40px !important; }
      .flex-container .section-text .section-title:first-child,
      .flex-container .section-text h3:first-child,
      .flex-container .section-overlay-text .section-title:first-child,
      .flex-container .section-overlay-text h3:first-child {
        margin-top: 0px !important; }
  .flex-container .section-overlay-text {
    position: relative !important;
    bottom: 130px !important;
    padding: 50px 40px !important; } }

@media screen and (max-width: 480px) {
  .mobile-499 {
    display: block; }
  .desktop-499 {
    display: none; } }

@media screen and (max-width: 480px) {
  .content-video .play_video {
    width: 40px;
    height: 40px;
    font-size: 16px; }
  .flex-container .section-overlay-text {
    bottom: 20px !important; }
  .flex-container.data-left.overlay-content .section-overlay-text {
    width: calc( 100% - 40px) !important;
    left: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .flex-container .section-text .section-title,
  .flex-container .section-text h3 {
    font-size: 22px !important;
    line-height: 24px !important;
    margin-bottom: 20px !important; } }

.site-header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99; }
  .site-header * {
    transition: .5s; }
  .site-header.scroll .menu-toggle-container {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px; }
    .site-header.scroll .menu-toggle-container .toggle-container {
      transform: scale(0.8); }
  .site-header.scroll .globe {
    width: 60px;
    height: 60px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .site-header.scroll .globe .icon {
      transform: scale(0.8); }

header .menu-toggle-container-map {
  position: relative;
  padding: 17px 0 0 15px;
  background: white !important;
  transition: 0s;
  width: 60px;
  height: 60px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 90; }
  header .menu-toggle-container-map .toggle-container-map {
    margin-top: 5px; }
    header .menu-toggle-container-map .toggle-container-map .text {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      opacity: 0; }
    header .menu-toggle-container-map .toggle-container-map .toggle-bar:first-child {
      transform: rotate(45deg) translate(4px, 5px); }
    header .menu-toggle-container-map .toggle-container-map .toggle-bar:nth-child(2) {
      opacity: 0;
      visibility: hidden;
      transition: initial; }
    header .menu-toggle-container-map .toggle-container-map .toggle-bar:nth-child(3) {
      transform: rotate(-45deg) translate(6px, -7px); }

.main-container-ubicacion {
  min-height: 100vh;
  height: 100vh;
  background-image: radial-gradient(circle, #3d4246, #222529);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transform: translateX(100%);
  transition: .3s; }
  .main-container-ubicacion.active-menu {
    transform: translateX(0%); }
  .main-container-ubicacion .accordion {
    display: none; }
  .main-container-ubicacion .no-accor {
    display: block; }
  .main-container-ubicacion .wrapper {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-width: 1368px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    overflow: hidden; }
  .main-container-ubicacion .container--box {
    margin-bottom: 24px; }
    .main-container-ubicacion .container--box:last-child {
      margin-bottom: 0; }
  .main-container-ubicacion .container--img {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: -10;
    width: 100%; }
  .main-container-ubicacion .mapa {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto; }
  .main-container-ubicacion .box {
    background: rgba(54, 59, 63, 0.7);
    width: 458px;
    color: #fff;
    padding: 34px 40px;
    border: 1px solid rgba(24, 26, 27, 0.3);
    box-shadow: 8px 8px 100px rgba(0, 0, 0, 0.7);
    transition: .3s; }
    .main-container-ubicacion .box.box--brasil:hover {
      background: rgba(196, 160, 13, 0.7); }
    .main-container-ubicacion .box.box--usa:hover {
      background: rgba(0, 112, 195, 0.75); }
    .main-container-ubicacion .box.box--argentina:hover {
      background: rgba(159, 83, 216, 0.75); }
    .main-container-ubicacion .box.box--chile:hover {
      background: rgba(255, 0, 88, 0.75); }
    .main-container-ubicacion .box.box--mexico:hover {
      background: rgba(0, 227, 154, 0.75); }
  .main-container-ubicacion .box .font-700,
  .main-container-ubicacion .box .font-300 {
    width: 100%; }
  .main-container-ubicacion .active {
    background: rgba(196, 160, 13, 0.7);
    margin-right: 230px; }
  .main-container-ubicacion .zona {
    color: #fff;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    position: absolute;
    left: 0;
    top: 145px;
    line-height: 1.5;
    padding: 0;
    align-items: center; }
  .main-container-ubicacion .zona__icon {
    width: 23px;
    height: 23px;
    background: #0066ad;
    border-radius: 50%;
    margin-right: 10px; }
  @media screen and (max-width: 1440px) {
    .main-container-ubicacion .wrapper {
      font-size: 11px;
      max-width: 1040px; }
    .main-container-ubicacion .zona {
      left: 50px;
      top: 20px; }
    .main-container-ubicacion .mapa {
      width: 100%; }
    .main-container-ubicacion .box {
      width: 330px;
      padding: 18px 25px; }
    .main-container-ubicacion .container--box {
      margin-bottom: 15px; } }
  @media screen and (max-width: 1440px) and (max-height: 600px) {
    .main-container-ubicacion .wrapper {
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-around; }
    .main-container-ubicacion .active {
      background: rgba(196, 160, 13, 0.7);
      margin-right: 0px; }
    .main-container-ubicacion .container--box {
      margin: 10px 0; } }
  @media screen and (min-width: 1440px) and (max-height: 915px) {
    .main-container-ubicacion .wrapper {
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-around; }
    .main-container-ubicacion .active {
      background: rgba(196, 160, 13, 0.7);
      margin-right: 0px; }
    .main-container-ubicacion .container--box {
      margin: 10px 0; } }
  @media screen and (max-width: 1080px) {
    .main-container-ubicacion .wrapper {
      padding: 0 20px; }
    .main-container-ubicacion .active {
      margin-right: 100px; }
    .main-container-ubicacion .zona {
      left: 100px; } }
  @media screen and (max-width: 640px) {
    .main-container-ubicacion {
      height: 100%;
      overflow-y: scroll; }
      .main-container-ubicacion .active {
        margin-right: 0; }
      .main-container-ubicacion .wrapper {
        align-items: center;
        justify-content: flex-end; }
      .main-container-ubicacion .zona {
        left: 50%;
        transform: translateX(-50%);
        top: 20px; }
      .main-container-ubicacion .mapa {
        width: 100%;
        top: 147px;
        opacity: .5; }
      .main-container-ubicacion .container--box:first-child {
        margin-top: 190px; }
      .main-container-ubicacion .box.box--usa {
        margin-top: 230px; }
      .main-container-ubicacion .box.box--chile {
        margin-bottom: 40px; }
      .main-container-ubicacion .no-accor {
        display: none; }
      .main-container-ubicacion .accordion {
        display: block;
        margin-right: 0px;
        margin-bottom: 15px; }
        .main-container-ubicacion .accordion .active {
          margin-right: 0 !important; }
        .main-container-ubicacion .accordion .accordion-item .icon {
          left: 10px;
          width: 20px;
          height: 20px; }
          .main-container-ubicacion .accordion .accordion-item .icon:after, .main-container-ubicacion .accordion .accordion-item .icon:before {
            width: .6rem;
            height: 0.1rem; }
        .main-container-ubicacion .accordion .accordion-item .heading {
          padding: 14px 14px 14px 48px; } }
  @media screen and (max-width: 640px) and (max-height: 600px) {
    .main-container-ubicacion .wrapper {
      flex-wrap: nowrap; } }
  @media screen and (max-width: 380px) {
    .main-container-ubicacion .container--box {
      width: 100%; }
    .main-container-ubicacion .box {
      text-align: center;
      width: 100%; }
    .main-container-ubicacion .zona {
      display: flex;
      width: 100%;
      justify-content: center; } }
  @media screen and (min-width: 720px) and (max-height: 600px) {
    .main-container-ubicacion .wrapper {
      align-items: flex-end; }
      .main-container-ubicacion .wrapper .no-accor {
        display: none; }
      .main-container-ubicacion .wrapper .accordion {
        display: block;
        margin-right: 30px; }
        .main-container-ubicacion .wrapper .accordion .active {
          margin-right: 0 !important; } }

.main-container-sumate,
.main-container-contacto {
  min-height: 100vh;
  height: 100vh;
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transform: translateX(100%);
  transition: .3s; }
  .main-container-sumate.active-menu,
  .main-container-contacto.active-menu {
    transform: translateX(0%); }
  .main-container-sumate > img,
  .main-container-contacto > img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: right center; }
  .main-container-sumate .section-form,
  .main-container-contacto .section-form {
    height: 100%;
    width: 59.077%;
    background: rgba(132, 141, 152, 0.3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 192px;
    z-index: 7;
    position: relative; }
    .main-container-sumate .section-form .cel,
    .main-container-sumate .section-form .planta,
    .main-container-contacto .section-form .cel,
    .main-container-contacto .section-form .planta {
      width: auto;
      position: absolute;
      right: 0;
      transform: translate(50%, -50%); }
    .main-container-sumate .section-form .planta,
    .main-container-contacto .section-form .planta {
      top: 20%; }
    .main-container-sumate .section-form .cel,
    .main-container-contacto .section-form .cel {
      top: 50%; }
    .main-container-sumate .section-form .content-form,
    .main-container-contacto .section-form .content-form {
      width: 100%;
      max-width: 684px;
      text-align: right; }
      .main-container-sumate .section-form .content-form img,
      .main-container-contacto .section-form .content-form img {
        width: auto;
        display: block;
        margin: auto;
        margin-bottom: 100px; }
      .main-container-sumate .section-form .content-form h4,
      .main-container-contacto .section-form .content-form h4 {
        font-size: 50px;
        font-weight: 900;
        line-height: 60px;
        color: #626972;
        text-align: left; }
      .main-container-sumate .section-form .content-form form,
      .main-container-contacto .section-form .content-form form {
        margin-top: 50px;
        margin-bottom: 20px; }
        .main-container-sumate .section-form .content-form form > div,
        .main-container-contacto .section-form .content-form form > div {
          display: flex;
          height: 50px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px; }
          .main-container-sumate .section-form .content-form form > div:last-child,
          .main-container-contacto .section-form .content-form form > div:last-child {
            margin-bottom: 0; }
          .main-container-sumate .section-form .content-form form > div input,
          .main-container-sumate .section-form .content-form form > div .select-field,
          .main-container-contacto .section-form .content-form form > div input,
          .main-container-contacto .section-form .content-form form > div .select-field {
            width: 398px;
            border: 1px solid #9fa1a5;
            height: 100%;
            padding-left: 20px;
            font-size: 20px;
            line-height: 30px;
            color: #414d5b;
            font-family: 'Termina';
            outline: none; }
          .main-container-sumate .section-form .content-form form > div label,
          .main-container-contacto .section-form .content-form form > div label {
            font-size: 20px;
            line-height: 30px;
            color: #414d5b; }
          .main-container-sumate .section-form .content-form form > div textarea,
          .main-container-contacto .section-form .content-form form > div textarea {
            width: 398px;
            height: 113px;
            align-self: end;
            padding-left: 20px;
            font-size: 20px;
            line-height: 26px;
            color: #414d5b;
            font-family: Termina;
            outline: none;
            resize: none;
            border: 1px solid #9fa1a5;
            padding-top: 10px; }
      .main-container-sumate .section-form .content-form .btn,
      .main-container-contacto .section-form .content-form .btn {
        display: inline-block;
        position: relative;
        transition: .1s !important; }
        .main-container-sumate .section-form .content-form .btn *,
        .main-container-contacto .section-form .content-form .btn * {
          transition: .1s !important; }
        .main-container-sumate .section-form .content-form .btn .lds-ellipsis,
        .main-container-contacto .section-form .content-form .btn .lds-ellipsis {
          position: absolute;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          display: none; }
        .main-container-sumate .section-form .content-form .btn.sending,
        .main-container-contacto .section-form .content-form .btn.sending {
          background: #49b8ef !important; }
      .main-container-sumate .section-form .content-form .notice-success,
      .main-container-contacto .section-form .content-form .notice-success {
        color: #626972;
        font-size: 32px;
        text-align: center;
        line-height: 40px;
        font-weight: 900;
        text-transform: uppercase;
        display: none; }
  @media screen and (max-width: 1950px) {
    .main-container-sumate .section-form,
    .main-container-contacto .section-form {
      padding-right: 0; }
      .main-container-sumate .section-form .content-form,
      .main-container-contacto .section-form .content-form {
        width: 100%;
        max-width: 684px;
        margin: auto; }
        .main-container-sumate .section-form .content-form img,
        .main-container-contacto .section-form .content-form img {
          margin: auto auto 60px;
          width: 300px; }
        .main-container-sumate .section-form .content-form h4,
        .main-container-contacto .section-form .content-form h4 {
          line-height: 42px;
          font-size: 36px; }
        .main-container-sumate .section-form .content-form form,
        .main-container-contacto .section-form .content-form form {
          margin-top: 40px; }
          .main-container-sumate .section-form .content-form form > div label,
          .main-container-contacto .section-form .content-form form > div label {
            font-size: 18px;
            line-height: 26px; } }
  @media screen and (max-width: 1450px) {
    .main-container-sumate .section-form .content-form img,
    .main-container-contacto .section-form .content-form img {
      width: 240px;
      margin: auto auto 40px; }
    .main-container-sumate .section-form .content-form h4,
    .main-container-contacto .section-form .content-form h4 {
      font-size: 32px;
      line-height: 32px; }
    .main-container-sumate .section-form .content-form form > div,
    .main-container-contacto .section-form .content-form form > div {
      height: 45px; }
      .main-container-sumate .section-form .content-form form > div label,
      .main-container-contacto .section-form .content-form form > div label {
        font-size: 16px;
        line-height: 24px; }
      .main-container-sumate .section-form .content-form form > div input,
      .main-container-sumate .section-form .content-form form > div .select-field,
      .main-container-sumate .section-form .content-form form > div textarea,
      .main-container-contacto .section-form .content-form form > div input,
      .main-container-contacto .section-form .content-form form > div .select-field,
      .main-container-contacto .section-form .content-form form > div textarea {
        font-size: 16px;
        line-height: 24px; }
      .main-container-sumate .section-form .content-form form > div .select-field .dropdown .select,
      .main-container-contacto .section-form .content-form form > div .select-field .dropdown .select {
        font-size: 16px;
        line-height: 24px; } }
  @media screen and (max-width: 1280px) {
    .main-container-sumate .section-form .content-form,
    .main-container-contacto .section-form .content-form {
      padding: 0 20px;
      max-width: 100%; }
      .main-container-sumate .section-form .content-form form > div label,
      .main-container-contacto .section-form .content-form form > div label {
        width: 250px;
        text-align: left; }
      .main-container-sumate .section-form .content-form form > div textarea,
      .main-container-sumate .section-form .content-form form > div input,
      .main-container-sumate .section-form .content-form form > div .select-field,
      .main-container-contacto .section-form .content-form form > div textarea,
      .main-container-contacto .section-form .content-form form > div input,
      .main-container-contacto .section-form .content-form form > div .select-field {
        width: calc(100% - 250px); }
      .main-container-sumate .section-form .content-form .notice-success,
      .main-container-contacto .section-form .content-form .notice-success {
        font-size: 26px;
        line-height: 30px; } }
  @media screen and (max-width: 900px) {
    .main-container-sumate > img,
    .main-container-contacto > img {
      opacity: 0.1; }
    .main-container-sumate .section-form,
    .main-container-contacto .section-form {
      width: 100%; }
      .main-container-sumate .section-form .content-form h4,
      .main-container-contacto .section-form .content-form h4 {
        text-align: center;
        font-size: 24px;
        line-height: 24px; }
      .main-container-sumate .section-form .content-form img,
      .main-container-contacto .section-form .content-form img {
        width: 200px;
        margin: auto auto 30px; }
      .main-container-sumate .section-form .content-form form,
      .main-container-contacto .section-form .content-form form {
        margin-top: 30px; }
        .main-container-sumate .section-form .content-form form > div label,
        .main-container-contacto .section-form .content-form form > div label {
          font-size: 15px;
          width: 220px; }
        .main-container-sumate .section-form .content-form form > div textarea,
        .main-container-sumate .section-form .content-form form > div input,
        .main-container-sumate .section-form .content-form form > div .select-field,
        .main-container-contacto .section-form .content-form form > div textarea,
        .main-container-contacto .section-form .content-form form > div input,
        .main-container-contacto .section-form .content-form form > div .select-field {
          width: calc(100% - 220px); } }
  @media screen and (max-width: 499px) {
    .main-container-sumate .section-form .content-form,
    .main-container-contacto .section-form .content-form {
      text-align: center; }
      .main-container-sumate .section-form .content-form img,
      .main-container-contacto .section-form .content-form img {
        width: 160px;
        margin: auto auto 20px; }
      .main-container-sumate .section-form .content-form form,
      .main-container-contacto .section-form .content-form form {
        margin-top: 20px;
        margin-bottom: 30px; }
        .main-container-sumate .section-form .content-form form > div,
        .main-container-contacto .section-form .content-form form > div {
          height: auto;
          flex-direction: column; }
          .main-container-sumate .section-form .content-form form > div:last-child,
          .main-container-contacto .section-form .content-form form > div:last-child {
            margin-bottom: 0 !important; }
          .main-container-sumate .section-form .content-form form > div label,
          .main-container-contacto .section-form .content-form form > div label {
            width: 100% !important;
            text-align: center; }
          .main-container-sumate .section-form .content-form form > div textarea,
          .main-container-sumate .section-form .content-form form > div input,
          .main-container-sumate .section-form .content-form form > div .select-field,
          .main-container-contacto .section-form .content-form form > div textarea,
          .main-container-contacto .section-form .content-form form > div input,
          .main-container-contacto .section-form .content-form form > div .select-field {
            height: 35px;
            width: 100%;
            max-width: 300px; }
            .main-container-sumate .section-form .content-form form > div textarea .dropdown .select > i,
            .main-container-sumate .section-form .content-form form > div input .dropdown .select > i,
            .main-container-sumate .section-form .content-form form > div .select-field .dropdown .select > i,
            .main-container-contacto .section-form .content-form form > div textarea .dropdown .select > i,
            .main-container-contacto .section-form .content-form form > div input .dropdown .select > i,
            .main-container-contacto .section-form .content-form form > div .select-field .dropdown .select > i {
              width: 34px;
              height: 34px; }
          .main-container-sumate .section-form .content-form form > div textarea,
          .main-container-contacto .section-form .content-form form > div textarea {
            height: 70px;
            display: block;
            margin: auto; }
          .main-container-sumate .section-form .content-form form > div .btn,
          .main-container-contacto .section-form .content-form form > div .btn {
            height: 50px;
            line-height: 50px; }
      .main-container-sumate .section-form .content-form .notice-success,
      .main-container-contacto .section-form .content-form .notice-success {
        font-size: 16px;
        line-height: 20px; } }
  @media screen and (max-width: 499px) and (max-height: 600px) {
    .main-container-sumate,
    .main-container-contacto {
      overflow-y: scroll; }
      .main-container-sumate .section-form,
      .main-container-contacto .section-form {
        padding: 70px 0px;
        min-height: initial !important;
        height: auto !important; } }

.main-container-contacto {
  background: white;
  background: -moz-linear-gradient(left, white 0%, white 31%, #b1b1b1 61%, #adadad 90%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, white 0%, white 31%, #b1b1b1 61%, #adadad 90%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, white 0%, white 31%, #b1b1b1 61%, #adadad 90%); }
  .main-container-contacto .form_contacto {
    bottom: 0;
    width: auto;
    position: absolute;
    right: 0;
    transform: translateX(34%);
    height: auto; }
  .main-container-contacto .section-form .content-form > p {
    font-size: 20px;
    line-height: 30px;
    color: #414d5b;
    margin-top: 17px; }
  .main-container-contacto .section-form .content-form form > div:last-child {
    margin-bottom: 82px; }
  @media screen and (max-width: 1950px) {
    .main-container-contacto .form_contacto {
      transform: translateX(50%); }
    .main-container-contacto .section-form .planta {
      width: 220px; }
    .main-container-contacto .section-form .cel {
      width: 180px; } }
  @media screen and (max-width: 1450px) {
    .main-container-contacto .form_contacto {
      transform: translateX(36%);
      width: 50%; }
    .main-container-contacto .section-form .planta {
      width: 115px; }
    .main-container-contacto .section-form .cel {
      width: 100px; }
    .main-container-contacto .section-form .content-form form {
      margin-top: 20px; }
    .main-container-contacto .section-form .content-form > p {
      font-size: 16px;
      line-height: 24px;
      text-align: left; } }
  @media screen and (max-width: 1280px) {
    .main-container-contacto .section-form .planta {
      right: -20px; }
    .main-container-contacto .section-form .cel {
      right: -33px; } }
  @media screen and (max-width: 900px) {
    .main-container-contacto {
      background: #d8dcdf; }
      .main-container-contacto .form_contacto {
        display: none; }
      .main-container-contacto .section-form .planta {
        transform: translate(0);
        top: 20px;
        right: 10px;
        width: 80px;
        opacity: .2; }
      .main-container-contacto .section-form .cel {
        transform: translate(0);
        top: auto !important;
        right: auto;
        width: 90px;
        bottom: -5px !important;
        left: 10px;
        opacity: .2; } }
  @media screen and (max-height: 1020px) {
    .main-container-contacto .section-form .cel {
      top: 70%; } }

.menu-toggle-container {
  width: 90px;
  height: 88px;
  padding: 34px 0 0 34px;
  background: #ffffff;
  z-index: 2;
  transition: .3s; }

.toggle-container {
  transition: 1s;
  cursor: pointer; }
  .toggle-container .text {
    font-size: 9px;
    letter-spacing: -0.3px;
    color: #414d5b;
    position: relative;
    left: -1px; }
  .toggle-container.open .toggle-bar:first-child {
    transform: rotate(45deg) translate(4px, 5px); }
  .toggle-container.open .toggle-bar:nth-child(2) {
    opacity: 0;
    visibility: hidden;
    transition: initial; }
  .toggle-container.open .toggle-bar:nth-child(3) {
    transform: rotate(-45deg) translate(6px, -7px); }

.toggle-bar {
  width: 33px;
  height: 3px;
  background: #414d5b;
  margin-bottom: 5px; }
  .toggle-bar:nth-child(3) {
    margin-bottom: 1px; }

.globe {
  width: 90px;
  height: 88px;
  padding: 34px 34px 0 0px;
  cursor: pointer;
  background: #ffffff;
  display: flex;
  justify-content: flex-end; }
  .globe .icon {
    width: 36px;
    height: 36px;
    transition: 1s; }

.menu {
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden; }
  .menu--active {
    visibility: visible; }
  .menu__left, .menu__right {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    float: left;
    height: 100vh;
    overflow: hidden; }
  .menu__right {
    margin: 0;
    padding: 0;
    background: white;
    list-style-type: none;
    transform: translate3d(0, -100%, 0);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    width: 38.125%; }
    .menu__right img {
      width: auto; }
    .menu__right h4 {
      color: #626972;
      font-size: 30px;
      line-height: 85px;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 115px;
      margin-bottom: 23px; }
    .menu__right ul li {
      text-align: center;
      margin-bottom: 50px; }
      .menu__right ul li:last-child {
        margin-bottom: 0; }
      .menu__right ul li a {
        color: #626972;
        font-size: 30px;
        line-height: 36px;
        transition: .3s;
        font-weight: 300;
        text-align: center; }
        .menu__right ul li a:hover, .menu__right ul li a.active {
          color: #61b2e5; }
        .menu__right ul li a.active {
          text-transform: uppercase;
          font-weight: 600; }
    .menu__right--active {
      transform: translate3d(0, 0, 0) !important; }
  .menu__left {
    background: #ced1d6;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    transform: translate3d(0, 100%, 0);
    width: 61.875%; }
    .menu__left--active {
      transform: translate3d(0, 0, 0) !important; }
    .menu__left img {
      width: auto; }
    .menu__left > div {
      display: flex;
      flex-direction: column;
      max-width: 622px;
      align-items: center;
      margin-top: 130px; }
      .menu__left > div a {
        font-size: 44px;
        color: white;
        line-height: 85px;
        font-weight: 800;
        text-align: center;
        text-transform: uppercase;
        line-height: 50px;
        margin-bottom: 35px;
        transition: .3s; }
        .menu__left > div a:last-child {
          margin-bottom: 0; }
        .menu__left > div a:hover, .menu__left > div a.active {
          color: #626972; }
  .menu__item {
    transform: translate3d(500px, 0, 0);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1); }
    .menu__item--active {
      transform: translate3d(0, 0, 0); }
  .menu .menu__item:nth-child(1) {
    transition-delay: 0.1s; }
  .menu .menu__item:nth-child(2) {
    transition-delay: 0.2s; }
  .menu .menu__item:nth-child(3) {
    transition-delay: 0.3s; }
  .menu .menu__item:nth-child(4) {
    transition-delay: 0.4s; }
  .menu .menu__item:nth-child(5) {
    transition-delay: 0.5s; }
  .menu .menu__item:nth-child(6) {
    transition-delay: 0.6s; }

@media screen and (max-width: 1440px) {
  .site-header .menu__right img {
    width: 45px; }
  .site-header .menu__right h4 {
    margin-top: 54px;
    margin-bottom: 15px; }
  .site-header .menu__right ul li {
    margin-bottom: 25px; }
    .site-header .menu__right ul li a {
      font-size: 24px;
      line-height: 30px; }
  .site-header .menu__left img {
    width: 240px; }
  .site-header .menu__left > div {
    margin-top: 50px; }
    .site-header .menu__left > div a {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 25px; } }

@media screen and (max-width: 1080px) {
  .site-header .menu__right img {
    width: 30px; }
  .site-header .menu__right h4 {
    font-size: 22px;
    line-height: 60px;
    margin-top: 30px;
    margin-bottom: 30px; }
  .site-header .menu__right ul li {
    margin-bottom: 18px; }
    .site-header .menu__right ul li a {
      font-size: 18px;
      line-height: 25px; }
  .site-header .menu__left img {
    width: 190px; }
  .site-header .menu__left > div {
    margin-top: 60px; }
    .site-header .menu__left > div a {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 25px; } }

@media screen and (max-width: 900px) {
  .site-header * {
    transition: .5s; } }

@media screen and (max-width: 767px) {
  .site-header .menu__right {
    transform: translate3d(0, 100%, 0);
    width: 100%;
    height: auto;
    padding: 40px 0; }
  .site-header .menu__left {
    transform: translate3d(0, -100%, 0);
    width: 100%;
    height: auto;
    padding-top: 80px;
    padding-bottom: 40px; }
  body.no-scroll .menu.menu--active {
    overflow-y: scroll;
    height: 100vh; } }

@media screen and (max-width: 640px) {
  .menu-toggle-container {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 60px;
    height: 60px; }
    .menu-toggle-container .toggle-container {
      transform: scale(0.8); }
  .globe {
    width: 60px;
    height: 60px;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .globe .icon {
      width: 30px;
      height: 30px;
      -webkit-transform: scale(0.8) !important;
      -ms-transform: scale(0.8) !important;
      transform: scale(0.8) !important; } }

@media screen and (max-width: 499px) {
  .site-header .menu__left {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 20px; }
    .site-header .menu__left img {
      width: 140px; }
    .site-header .menu__left > div {
      margin-top: 50px; }
      .site-header .menu__left > div a {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 24px; }
  .site-header .menu__right {
    padding-left: 20px;
    padding-right: 20px; }
    .site-header .menu__right h4 {
      font-size: 18px;
      line-height: 50px;
      margin-top: 15px;
      margin-bottom: 15px; }
    .site-header .menu__right ul li {
      margin-bottom: 14px; }
      .site-header .menu__right ul li a {
        font-size: 16px;
        line-height: 22px; } }

@media screen and (max-width: 480px) {
  main {
    overflow: hidden; } }

.home {
  /* Section - general */
  /* Secciones - imgs */
  /* Secciones - textos */
  /* Video */
  /* Section 1 */
  /* Section 2 */
  /* Section 3 */
  /* Section 4 */
  /* Section 5 */
  /* Responsive */ }
  .home main > section:not(.video) {
    max-width: 1950px;
    margin-left: auto;
    margin-right: auto;
    position: relative; }
  .home .btn.orange-bg:hover {
    color: #e9bd30;
    border-color: #e9bd30;
    background: #ffffff; }
  .home .upper-kicker {
    width: 323px;
    font-size: 25px;
    color: #ffffff;
    background: #9e0028;
    padding: 38px 0 39px 38px;
    position: absolute;
    top: 1px;
    left: 49px;
    z-index: 2; }
  .home .overlay-text {
    color: #ffffff;
    background: rgba(228, 0, 57, 0.7);
    padding: 97px 0 50px 49px;
    left: 0;
    z-index: 1; }
  .home .section-img {
    position: relative; }
  .home .img-desc {
    display: inline-block;
    font-size: 35px;
    line-height: 40px;
    color: #ffffff;
    padding: 48px 63px 51px 70px;
    z-index: 1; }
  .home .inside-img {
    width: 61.77%;
    max-width: 706px;
    position: absolute;
    right: 64px;
    bottom: -24.5555%; }
  .home .section-title {
    font-size: 50px;
    line-height: 1;
    font-weight: 900;
    margin-bottom: 53px; }
  .home .section-text {
    max-width: 710px;
    font-size: 20px;
    line-height: 30px;
    color: #414d5b;
    opacity: 0; }
    .home .section-text p:not(:last-child) {
      margin-bottom: 30px; }
    .home .section-text.uppercase {
      font-size: 24px; }
    .home .section-text p:first-of-type.uppercase {
      margin-bottom: 2px; }
  .home section.video {
    height: 100vh;
    padding: 30px;
    margin-bottom: 200px !important;
    max-width: 100% !important; }
    .home section.video .extra-holder {
      position: relative;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      padding-bottom: 0 !important; }
      .home section.video .extra-holder video {
        position: absolute;
        left: 0;
        top: 50%;
        height: auto;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%); }
  .home #section-1 {
    margin-bottom: 200px;
    padding-right: 30px; }
    .home #section-1 .section-text {
      max-width: 678px;
      padding-top: 219px;
      padding-left: 30px; }
      .home #section-1 .section-text p {
        max-width: 605px; }
    .home #section-1 .img-desc {
      width: 699px;
      background: rgba(116, 53, 155, 0.8);
      position: absolute;
      top: -20px;
      left: 79.555555%;
      z-index: 5; }
  .home #section-2 {
    margin-bottom: 195px;
    padding-left: 30px; }
    .home #section-2 > .flex-container {
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
    .home #section-2 .section-text {
      max-width: 700px;
      padding-right: 114px; }
      .home #section-2 .section-text p {
        max-width: 575px; }
        .home #section-2 .section-text p:first-of-type {
          margin-bottom: 55px; }
      .home #section-2 .section-text .btn {
        margin-top: 0; }
    .home #section-2 .section-title {
      margin-bottom: 45px; }
    .home #section-2 .robot {
      position: absolute;
      top: -142px;
      left: -191px;
      z-index: 5; }
  .home #section-3 {
    margin-bottom: 190px;
    padding-right: 30px; }
    .home #section-3 .section-text {
      max-width: 673px;
      padding-left: 30px; }
      .home #section-3 .section-text p {
        max-width: 594px; }
        .home #section-3 .section-text p:nth-child(5) {
          margin-bottom: 45px; }
    .home #section-3 .links {
      max-width: 487px;
      flex-wrap: wrap; }
      .home #section-3 .links .btn {
        flex-basis: 236px;
        font-size: 24px;
        height: 61px;
        line-height: 61px;
        padding: 0;
        margin-top: 0; }
        .home #section-3 .links .btn.push {
          margin-bottom: 13px; }
        .home #section-3 .links .btn:hover {
          color: #5dc295;
          background: #ffffff;
          border-color: #5dc295; }
    .home #section-3 .section-title {
      color: #6de3ae;
      margin-bottom: 48px;
      padding-top: 59px; }
  .home #section-4 {
    margin-bottom: 10px;
    padding-left: 30px; }
    .home #section-4 .flex-container {
      padding-left: 0 !important; }
    .home #section-4 .upper-kicker {
      left: 52px;
      top: -210px; }
    .home #section-4 .section-text {
      width: 75%;
      max-width: 1122px;
      padding-top: 196px;
      z-index: 5;
      opacity: 1;
      margin-top: 440px;
      max-width: initial !important;
      background: rgba(228, 0, 57, 0.75);
      color: white;
      padding-left: 50px !important;
      padding-bottom: 40px !important;
      padding-top: 90px !important;
      max-width: 960px !important;
      position: relative; }
    .home #section-4 .section-title {
      max-width: 485px;
      margin-bottom: 45px; }
    .home #section-4 .overlay-text p {
      max-width: 907px; }
      .home #section-4 .overlay-text p:nth-child(3) {
        max-width: 912px;
        margin-bottom: 51px; }
    .home #section-4 .btn {
      background: #e40039;
      margin-top: 0; }
      .home #section-4 .btn:hover {
        color: #e40039;
        border-color: #e40039;
        background: #ffffff; }
  .home #section-5 {
    position: relative;
    margin-bottom: 191px;
    padding-right: 30px;
    background: url(img/mundo-home.png) no-repeat;
    background-position-x: 114.6%;
    background-size: contain;
    margin-top: -90px !important; }
    .home #section-5 .img-desc {
      width: 620px;
      background: rgba(73, 184, 239, 0.8);
      position: absolute;
      top: 50px;
      left: 83%;
      padding: 64px 63px 66px 70px;
      z-index: 5; }
    .home #section-5 .section-text {
      max-width: 674px;
      padding-top: 258px;
      padding-left: 30px; }
    .home #section-5 .section-title {
      color: #49b8ef;
      margin-top: 20px; }
      .home #section-5 .section-title p {
        margin-bottom: 44px; }
    .home #section-5 .btn {
      background: #49b8ef;
      margin-top: 20px; }
      .home #section-5 .btn:hover {
        color: #49b8ef;
        background: #ffffff;
        border-color: #49b8ef; }
  @media screen and (max-width: 1850px) {
    .home #section-1 .section-text p {
      max-width: 100%; }
    .home #section-2 .section-text {
      padding-right: 30px; }
      .home #section-2 .section-text p {
        max-width: 100%; }
    .home #section-3 .section-text p {
      max-width: 100%; } }
  @media screen and (max-width: 1650px) {
    .home #section-2 .robot {
      width: 250px;
      left: -152px;
      top: -50px;
      transform: translateY(-50%); } }
  @media screen and (max-width: 1440px) {
    .home {
      /* Img descs */
      /* Section 2 */
      /* Section 3 */ }
      .home .section-text .uppercase {
        font-size: 22px; }
      .home section .flex-container {
        flex-wrap: wrap; }
      .home .section-img {
        width: 60%; }
      .home .section-text {
        width: 40%;
        font-size: 16px;
        line-height: 24px; }
        .home .section-text p {
          font-size: 15px; }
          .home .section-text p:not(:last-child) {
            margin-bottom: 20px; }
        .home .section-text .uppercase {
          font-size: 18px; }
      .home .img-desc {
        padding: 40px !important;
        font-size: 22px;
        max-width: 435px;
        width: 100%;
        line-height: 26px; }
        .home .img-desc br {
          display: none; }
      .home .overlay-text {
        padding: 60px 50px 60px 50px; }
      .home .upper-kicker {
        font-size: 20px;
        padding: 32px;
        top: 45px; }
      .home section.video {
        margin-bottom: 130px !important; }
      .home #section-1 {
        margin-bottom: 140px; }
        .home #section-1 .img-desc {
          width: 100%; }
        .home #section-1 .section-text {
          padding-top: 115px; }
      .home #section-2 {
        margin-bottom: 125px; }
        .home #section-2 .section-text {
          max-width: 40%;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          padding-right: 30px; }
          .home #section-2 .section-text p:first-of-type {
            margin-bottom: 40px; }
        .home #section-2 .section-title {
          max-width: 460px; }
        .home #section-2 .robot {
          width: 160px;
          left: -123px;
          top: 5px;
          left: -92px; }
      .home #section-3 {
        margin-bottom: 110px; }
        .home #section-3 .section-title {
          padding-top: 0; }
        .home #section-3 .section-text p:nth-child(5) {
          margin-bottom: 30px; }
        .home #section-3 .links .btn {
          font-size: 16px;
          height: 50px;
          line-height: 50px; }
      .home #section-4 {
        margin-bottom: -75px; }
        .home #section-4 .upper-kicker {
          left: 52px;
          top: -130px !important; }
        .home #section-4 .overlay-text p:nth-child(3) {
          margin-bottom: 30px; }
        .home #section-4 .section-text {
          padding-top: 200px;
          margin-top: 340px;
          position: relative; }
      .home #section-5 {
        margin-bottom: 140px; }
      .home .site-footer .footer-robot {
        width: 160px; } }
  @media screen and (max-width: 1300px) {
    .home section.video {
      margin-bottom: 140px !important; } }
  @media screen and (max-width: 1280px) {
    .home {
      /* Section 3 */ }
      .home #section-2 .robot {
        left: -35px; }
      .home #section-3 .links .btn {
        flex-basis: 49%; }
      .home #section-5 .section-text {
        padding-top: 200px; }
      .home .section-title {
        font-size: 28px;
        margin-bottom: 28px !important; }
      .home .section-text .uppercase {
        font-size: 18px; }
      .home .section-text p:not(:last-child) {
        margin-bottom: 20px; } }
  @media screen and (max-width: 1105px) {
    .home section.video .extra-holder video {
      height: 100%;
      width: auto; } }
  @media screen and (max-width: 1080px) {
    .home .section-title {
      max-width: 100%; }
    .home .section-text {
      padding-left: 30px !important;
      padding-right: 30px !important;
      max-width: 100% !important;
      font-size: 16px;
      line-height: 20px; }
      .home .section-text p {
        max-width: 100% !important; }
    .home .upper-kicker {
      top: 62px; } }
  @media screen and (max-width: 900px) {
    .home {
      /* Img descs */
      /* Section 1 */
      /* Section 2 */
      /* Section 3 */
      /* Section 4 */
      /* Section 5 */ }
      .home .menu-toggle-container {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px; }
        .home .menu-toggle-container .toggle-container {
          transform: scale(0.8); }
      .home .globe {
        width: 60px;
        height: 60px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        .home .globe .icon {
          width: 30px;
          height: 30px;
          transform: scale(0.8) !important; }
      .home .img-desc {
        font-size: 24px;
        line-height: 28px; }
      .home .upper-kicker {
        top: auto;
        left: 50%;
        top: -280px;
        transform: translateX(-50%); }
      .home .overlay-text {
        padding-top: 60px;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: -150px; }
      .home .inside-img {
        right: 5%;
        width: 40%;
        bottom: -70px; }
      .home br {
        display: none; }
      .home .section-img,
      .home .section-text {
        width: 100%; }
      .home section.video {
        margin-bottom: 60px !important;
        height: auto; }
        .home section.video .content-video {
          position: relative;
          padding-bottom: 56.25% !important;
          height: 0;
          overflow: hidden; }
      .home main > section:not(.video) {
        padding: 0 !important;
        margin-bottom: 70px !important;
        text-align: center; }
        .home main > section:not(.video) > .flex-container {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column; }
      .home main > section#section-1:not(.video) {
        margin-bottom: 30px !important; }
      .home #section-1 .img-desc {
        top: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 24px !important;
        bottom: -110px; }
      .home #section-1 .section-text {
        padding-top: 50px; }
      .home #section-2 .section-img {
        order: 1; }
      .home #section-2 .section-text {
        order: 2;
        padding-top: 50px;
        max-width: initial !important; }
      .home #section-2 .robot {
        width: 140px;
        top: 150px;
        right: 40px;
        left: auto; }
      .home #section-2 .section-title {
        max-width: 100%; }
      .home #section-3 .section-text {
        padding-top: 50px; }
      .home #section-3 .links {
        margin: 0 auto; }
      .home #section-4 {
        margin-bottom: 0 !important;
        margin-top: 0; }
        .home #section-4 .section-title {
          max-width: 100%; }
        .home #section-4 .upper-kicker {
          left: 50%; }
        .home #section-4 .section-img {
          width: 100%;
          order: 1;
          position: initial; }
        .home #section-4 .section-text {
          width: 100%;
          padding: 70px 20px !important;
          order: 2;
          max-width: calc(100% - 40px) !important;
          margin-top: 0 !important;
          margin-left: 20px; }
      .home #section-5 {
        background-position: 100% 40%;
        background-size: contain;
        margin-top: 0px !important;
        margin-bottom: 60px !important; }
        .home #section-5 .btn {
          margin-top: 10px; }
        .home #section-5 .img-desc {
          top: auto;
          left: 50%;
          transform: translate(-50%, -50%);
          bottom: -150px; }
        .home #section-5 .section-title br:first-child {
          display: block; }
        .home #section-5 .section-text {
          padding-top: 100px; } }
  @media screen and (max-width: 640px) {
    .home {
      /* Texto */ }
      .home section.video {
        padding: 0;
        padding-top: 60px; }
      .home .img-desc {
        font-size: 18px;
        line-height: 23px;
        padding: 20px !important; }
      .home .section-text {
        padding-left: 20px !important;
        padding-right: 20px !important; }
      .home #section-5 .section-text {
        padding-top: 95px !important; } }
  @media screen and (max-width: 480px) {
    .home {
      /* Img desc */
      /* Section 1 */
      /* Section 2 */ }
      .home .section-title {
        font-size: 24px; }
      .home .inside-img {
        width: 45%;
        bottom: -30px;
        z-index: 7; }
      .home .section-text p {
        font-size: 14px; }
      .home .section-text .uppercase {
        font-size: 16px; }
      .home .btn {
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        padding: 0 20px; }
      .home .upper-kicker {
        font-size: 15px;
        padding: 20px;
        left: 50%;
        transform: translateX(-50%);
        top: -134px;
        width: calc(100% - 40px); }
      .home .overlay-text {
        margin-top: -70px; }
      .home .img-desc {
        font-size: 14px;
        line-height: 20px;
        max-width: calc(100% - 40px);
        padding: 20px !important; }
      .home section.video {
        margin-bottom: 60px !important; }
      .home main > section:not(.video) {
        margin-bottom: 60px !important; }
      .home main > section#section-1:not(.video) {
        margin-bottom: 20px !important; }
      .home #section-1 .section-text {
        padding-top: 40px; }
      .home #section-1 .img-desc {
        width: calc(100% - 40px);
        padding: 10px !important; }
      .home #section-2 {
        margin-bottom: 50px !important; }
        .home #section-2 .robot {
          width: 90px;
          top: 205px;
          right: auto;
          left: 20px; }
        .home #section-2 .section-text {
          padding-top: 30px !important; }
      .home main > section#section-3:not(.video) {
        margin-bottom: 34px !important; }
      .home #section-3 .section-text {
        padding-top: 30px !important; }
      .home #section-3 .links .btn {
        font-size: 14px;
        height: 44px;
        line-height: 44px; }
      .home #section-4 .section-text {
        margin-top: 80px !important;
        padding-bottom: 30px !important; }
        .home #section-4 .section-text .btn {
          margin-top: 15px; }
      .home #section-4 .upper-kicker {
        top: -95px !important; }
      .home #section-5 {
        margin-top: -25px !important; }
        .home #section-5 .img-desc {
          bottom: -144px; } }

#interna {
  color: #414d5b; }
  #interna .flex-container {
    position: relative; }
    #interna .flex-container.data-right.overlay-content {
      padding-right: 30px;
      justify-content: flex-start; }
      #interna .flex-container.data-right.overlay-content .section-img {
        position: relative !important; }
      #interna .flex-container.data-right.overlay-content .section-text {
        max-width: 60%;
        position: absolute;
        left: auto;
        right: 30px;
        background: rgba(42, 43, 46, 0.8);
        width: 100%;
        z-index: 5;
        max-width: 1120px !important;
        color: white;
        padding-left: 0 !important; }
    #interna .flex-container.data-left.overlay-content {
      padding-left: 30px;
      justify-content: flex-end; }
      #interna .flex-container.data-left.overlay-content .section-img {
        position: relative !important; }
      #interna .flex-container.data-left.overlay-content .section-text {
        max-width: 60%;
        position: absolute;
        left: 30px;
        right: auto;
        background: rgba(42, 43, 46, 0.8);
        width: 100%;
        z-index: 5;
        max-width: 1120px !important;
        color: white;
        padding-right: 0; }
  #interna .img-desc {
    position: absolute;
    z-index: 5;
    font-size: 35px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    #interna .img-desc.icon-box {
      width: 228px !important;
      height: 228px !important;
      text-align: center;
      display: flex;
      align-items: center;
      background: rgba(90, 187, 143, 0.85) !important;
      justify-content: center;
      padding: 0 !important; }
  #interna #section-1-interna {
    padding: 30px 30px 0;
    margin-bottom: 330px;
    max-width: initial; }
    #interna #section-1-interna .img-kicker-container {
      max-width: 1152px;
      color: #ffffff;
      position: absolute;
      transform: translate(-50%, -60%);
      text-align: center;
      z-index: 7;
      left: 50%;
      width: 100%; }
      #interna #section-1-interna .img-kicker-container h1 {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 4px;
        text-transform: uppercase;
        font-weight: 900; }
      #interna #section-1-interna .img-kicker-container p {
        font-size: 40px;
        line-height: 50px;
        color: white; }
  @media screen and (max-width: 1950px) {
    #interna .img-desc {
      font-size: 28px !important;
      line-height: 34px !important; }
    #interna section {
      margin-bottom: 150px !important; }
    #interna .flex-container .section-text .section-title {
      font-size: 42px !important;
      line-height: 42px !important; }
    #interna .flex-container .section-text p,
    #interna .flex-container .section-text ul li {
      font-size: 18px;
      line-height: 28px; }
    #interna .flex-container .section-text p {
      margin-bottom: 28px; }
    #interna .flex-container .section-text ul li {
      margin-bottom: 5px; }
      #interna .flex-container .section-text ul li:last-child {
        margin-bottom: 0; }
    #interna .flex-container.data-right .section-text {
      padding-left: 40px !important;
      padding-right: 30px; }
    #interna .flex-container.data-left .section-text {
      padding-right: 40px !important;
      padding-left: 30px; }
    #interna #section-1-interna .img-kicker-container {
      padding: 50px !important; }
      #interna #section-1-interna .img-kicker-container h1 {
        font-size: 42px; }
      #interna #section-1-interna .img-kicker-container p {
        font-size: 30px;
        line-height: 36px; }
    #interna #section-2-interna .section-text h2 {
      margin-bottom: 24px !important; } }
  @media screen and (max-width: 1450px) {
    #interna section {
      margin-bottom: 105px !important; }
    #interna .img-desc {
      font-size: 22px !important;
      line-height: 28px !important; }
    #interna .flex-container .section-text .section-title {
      font-size: 32px !important;
      line-height: 32px !important;
      margin-bottom: 20px !important; }
    #interna .flex-container .section-text p,
    #interna .flex-container .section-text ul li {
      font-size: 16px;
      line-height: 24px; }
    #interna .flex-container.data-right.overlay-content .section-text {
      max-width: 60% !important; }
    #interna .flex-container.data-left.overlay-content .section-text {
      max-width: 60% !important; }
    #interna #section-1-interna .img-kicker-container {
      padding: 40px !important;
      max-width: 65%; }
      #interna #section-1-interna .img-kicker-container h1 {
        font-size: 35px; }
      #interna #section-1-interna .img-kicker-container p {
        font-size: 22px;
        line-height: 30px; } }
  @media screen and (max-width: 900px) {
    #interna br {
      display: none !important; }
    #interna section {
      margin-bottom: 70px !important; }
    #interna .img-desc {
      right: auto !important;
      left: 50% !important;
      transform: translateX(-50%);
      bottom: -60px !important;
      top: auto !important;
      font-size: 18px;
      line-height: 22px; }
    #interna .flex-container .section-text {
      padding-left: 20px !important;
      padding-right: 20px;
      padding-top: 40px !important; }
      #interna .flex-container .section-text .section-title {
        font-size: 28px !important;
        line-height: 28px !important;
        margin-bottom: 20px !important;
        text-align: center; }
      #interna .flex-container .section-text p,
      #interna .flex-container .section-text ul li {
        font-size: 15px;
        line-height: 24px;
        text-align: center; }
      #interna .flex-container .section-text p {
        margin-bottom: 20px; }
      #interna .flex-container .section-text ul li {
        text-align: left;
        margin-bottom: 20px !important; }
    #interna .flex-container.data-right {
      flex-direction: column; }
      #interna .flex-container.data-right .section-text {
        padding-left: 20px !important;
        padding-right: 20px; }
    #interna .flex-container.overlay-content .section-text {
      width: calc(100% - 40px) !important;
      max-width: initial !important;
      left: 20px !important;
      transform: translateX(0);
      top: auto !important;
      bottom: 0;
      position: relative !important;
      padding: 40px 20px !important;
      margin-top: -30px; }
    #interna .flex-container.data-left {
      flex-direction: column;
      padding-left: 0 !important; }
      #interna .flex-container.data-left .section-text {
        padding-left: 20px !important;
        padding-right: 20px; }
      #interna .flex-container.data-left.overlay-content .section-text {
        width: calc(100% - 40px) !important;
        max-width: initial !important;
        left: 20px !important;
        transform: translateX(0);
        top: auto !important;
        bottom: 0;
        position: relative !important;
        padding: 40px 20px !important;
        margin-top: -30px; }
    #interna #section-1-interna .img-kicker-container {
      padding: 20px !important; }
      #interna #section-1-interna .img-kicker-container h1 {
        font-size: 28px; }
      #interna #section-1-interna .img-kicker-container p {
        font-size: 15px;
        line-height: 20px; } }
  @media screen and (max-width: 640px) {
    #interna section {
      margin-bottom: 50px !important; }
    #interna .img-desc {
      font-size: 15px !important;
      line-height: 18px !important; }
    #interna .flex-container .section-text {
      padding-top: 30px !important; }
      #interna .flex-container .section-text .section-title {
        font-size: 24px !important;
        line-height: 24px !important;
        margin-bottom: 15px !important; }
      #interna .flex-container .section-text p {
        margin-bottom: 15px; }
    #interna #section-1-interna {
      padding: 0; }
      #interna #section-1-interna figure {
        height: 300px;
        position: relative; }
        #interna #section-1-interna figure img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0; }
      #interna #section-1-interna .img-kicker-container {
        width: calc(100% - 40px);
        max-width: initial !important; } }
  @media screen and (max-width: 360px) {
    #interna #section-1-interna .img-kicker-container {
      padding: 20px 10px !important; }
      #interna #section-1-interna .img-kicker-container h1 {
        font-size: 25px; } }

#interna.page-template .img-kicker-container,
#interna.page-template .img-desc {
  background: rgba(228, 0, 57, 0.85); }
  #interna.page-template .img-kicker-container .icon-box,
  #interna.page-template .img-desc .icon-box {
    background: rgba(228, 0, 57, 0.85); }

#interna.page-template.page-template-template-clientes .img-desc {
  background: rgba(228, 0, 57, 0.85) !important; }

#interna.page-template.page-template-template-clientes #section-1-interna {
  margin-bottom: 180px; }
  #interna.page-template.page-template-template-clientes #section-1-interna .img-kicker-container {
    padding: 103px 20px !important; }
  #interna.page-template.page-template-template-clientes #section-1-interna .text-top {
    text-align: center;
    width: 100%;
    max-width: 1152px;
    margin: 220px auto auto;
    font-size: 20px;
    line-height: 30px;
    padding: 0 70px; }

#interna.page-template.page-template-template-clientes #section-2-interna {
  margin-bottom: 170px; }
  #interna.page-template.page-template-template-clientes #section-2-interna .section-text {
    padding-top: 200px; }
    #interna.page-template.page-template-template-clientes #section-2-interna .section-text img {
      margin-bottom: 60px; }
    #interna.page-template.page-template-template-clientes #section-2-interna .section-text .btn {
      margin-top: 24px; }
  #interna.page-template.page-template-template-clientes #section-2-interna .section-img .img-desc {
    width: 320px;
    height: 260px;
    top: 130px;
    right: -35px; }

#interna.page-template.page-template-template-clientes #section-3-interna .section-text {
  padding: 89px 70px !important;
  top: auto;
  bottom: -80px; }
  #interna.page-template.page-template-template-clientes #section-3-interna .section-text .img-desc {
    top: -174px;
    left: 354px; }
  #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 0 auto;
    grid-column-gap: 60px;
    grid-row-gap: 100px; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items .item {
      display: flex;
      align-items: center;
      justify-content: center; }

#interna.page-template.page-template-template-clientes #brands-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 146px 30px; }
  #interna.page-template.page-template-template-clientes #brands-content img {
    width: auto; }

#interna.page-template.page-template-template-clientes #slider {
  margin-bottom: 430px;
  display: flex;
  justify-content: flex-end; }
  #interna.page-template.page-template-template-clientes #slider .container-slider {
    max-width: 1728px;
    width: 100%;
    position: relative; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .swiper-container {
      width: 100%;
      height: 100%; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination {
      z-index: 7;
      width: 287px;
      background: white;
      height: 120px;
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      line-height: 30px;
      color: #414d5b;
      font-weight: 600; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-pagination {
        position: relative;
        bottom: auto;
        width: auto; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-next,
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-prev {
        width: 9px;
        height: 18px;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
        background-size: contain; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-next {
        background-image: url("img/clientes/icons/arrow-right.jpg");
        right: 70px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-prev {
        background-image: url("img/clientes/icons/arrow-left.jpg");
        left: 70px; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
      position: absolute;
      right: 162px;
      z-index: 8;
      bottom: -760px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
        width: auto; }

#interna.page-template.page-template-template-clientes #brands-content-2 {
  margin: auto auto 100px; }
  #interna.page-template.page-template-template-clientes #brands-content-2 div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin: auto;
    flex-wrap: wrap;
    padding: 0 60px; }
    #interna.page-template.page-template-template-clientes #brands-content-2 div hr {
      width: 100%;
      opacity: 0;
      margin: 0; }
    #interna.page-template.page-template-template-clientes #brands-content-2 div img {
      width: auto;
      margin-bottom: 124px; }
      #interna.page-template.page-template-template-clientes #brands-content-2 div img:nth-child(5):before {
        content: ''; }

#interna.page-template.page-template-template-clientes #section-4-interna {
  margin-bottom: 130px; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text {
    bottom: 50px; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 107px 165px; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .img-desc {
      top: -178px;
      right: 162px; }

#interna.page-template.page-template-template-clientes_bunge #section-1-interna {
  margin-bottom: 180px; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .img-kicker-container {
    padding: 96px 20px; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .text-top {
    text-align: center;
    width: 100%;
    max-width: 1152px;
    margin: auto;
    margin-top: 180px;
    font-size: 20px;
    line-height: 30px;
    padding: 0 32px; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .content-video {
    padding-bottom: 34.25%; }
    #interna.page-template.page-template-template-clientes_bunge #section-1-interna .content-video video {
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

#interna.page-template.page-template-template-clientes_bunge #section-2-interna {
  margin-bottom: 180px; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text {
    padding-top: 172px;
    position: relative; }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text h2 {
      margin-bottom: 40px !important;
      color: #e40039; }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text img {
      left: 50%;
      position: absolute;
      top: 10px;
      transform: translateX(-50%); }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
    bottom: -30px;
    right: -35px;
    padding: 40px 30px;
    text-transform: initial;
    font-size: 25px;
    line-height: 34px;
    max-width: 580px; }

#interna.page-template.page-template-template-clientes_bunge #section-3-interna {
  margin-bottom: 400px; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text {
    padding-left: 80px;
    padding-top: 160px;
    padding-bottom: 78px;
    top: 300px; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text h2 {
      color: white !important;
      margin-bottom: 40px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text .btn {
      margin-top: 20px;
      font-size: 24px; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text .img-desc {
      font-size: 25px;
      line-height: 32px;
      text-transform: initial;
      padding: 48px 59px;
      position: absolute;
      top: 0;
      transform: translateY(-50%); }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
    flex-direction: column;
    font-weight: 400;
    text-transform: initial;
    font-size: 25px;
    line-height: 40px;
    padding: 52px 117px;
    position: absolute;
    bottom: -492px;
    left: 384px;
    z-index: 7; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos h6 {
      font-weight: 700;
      font-size: 50px;
      line-height: 70px; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos p {
      line-height: 32px; }

@media screen and (max-width: 1950px) {
  #interna.page-template.page-template-template-clientes #section-1-interna {
    margin-bottom: 140px !important; }
    #interna.page-template.page-template-template-clientes #section-1-interna .text-top {
      margin: 200px auto auto; }
  #interna.page-template.page-template-template-clientes #section-2-interna .section-img .img-desc {
    width: 260px;
    height: 210px;
    top: 176px;
    right: -20px; }
  #interna.page-template.page-template-template-clientes #section-2-interna .section-text img {
    width: 300px !important;
    margin-bottom: 50px; }
  #interna.page-template.page-template-template-clientes #brands-content {
    padding: 70px 90px; }
  #interna.page-template.page-template-template-clientes #slider {
    margin-bottom: 240px !important; }
    #interna.page-template.page-template-template-clientes #slider .container-slider {
      max-width: calc(100% - 200px); }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
        bottom: -540px; }
        #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
          width: 700px; }
  #interna.page-template.page-template-template-clientes #brands-content-2 {
    margin-bottom: 30px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .text-top {
    margin: 120px auto auto; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
    right: -20px; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna {
    margin-bottom: 240px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
      bottom: -370px !important;
      padding: 40px 86px !important;
      font-size: 24px !important; }
      #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos h6 {
        font-size: 42px !important;
        line-height: 62px !important; }
      #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos p {
        line-height: 28px; } }

@media screen and (max-width: 1450px) {
  #interna.page-template .img-desc.icon-box {
    width: 150px !important;
    height: 150px !important; }
    #interna.page-template .img-desc.icon-box img {
      width: 60% !important; }
  #interna.page-template.page-template-template-clientes #section-1-interna {
    margin-bottom: 105px !important; }
    #interna.page-template.page-template-template-clientes #section-1-interna .img-kicker-container {
      padding: 40px !important; }
    #interna.page-template.page-template-template-clientes #section-1-interna .text-top {
      margin-top: 110px !important; }
  #interna.page-template.page-template-template-clientes #section-2-interna .flex-container {
    align-items: center; }
    #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-img .img-desc {
      width: 190px;
      height: 150px; }
    #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text {
      padding-top: 0px; }
      #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text img {
        width: 200px !important;
        margin-bottom: 40px; }
      #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text .btn {
        margin-top: 10px; }
  #interna.page-template.page-template-template-clientes #section-3-interna .section-text {
    padding: 40px 40px !important;
    bottom: -45px; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .img-desc {
      top: -119px;
      left: 32%; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items {
      grid-column-gap: 2%;
      grid-row-gap: 5px; }
      #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items .item {
        padding: 12%; }
        #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items .item img {
          max-width: 95% !important;
          max-height: 70px; }
  #interna.page-template.page-template-template-clientes #brands-content {
    padding: 50px 40px; }
  #interna.page-template.page-template-template-clientes #slider {
    margin-bottom: 150px !important; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
      bottom: -395px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
        width: 500px; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination {
      width: 230px;
      height: 90px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-next {
        right: 50px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-prev {
        left: 50px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-pagination {
        font-size: 16px; }
  #interna.page-template.page-template-template-clientes #brands-content-2 {
    margin-bottom: 50px !important; }
    #interna.page-template.page-template-template-clientes #brands-content-2 div {
      margin-bottom: 0 !important; }
      #interna.page-template.page-template-template-clientes #brands-content-2 div img {
        margin-bottom: 80px; }
      #interna.page-template.page-template-template-clientes #brands-content-2 div:first-child {
        max-width: 100%;
        padding: 0 40px; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text {
    padding-right: 0 !important; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .img-desc {
      top: -119px;
      right: 48px; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items {
      padding: 82px 40px; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .text-top {
    margin-top: 110px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text {
    padding-top: 114px !important;
    padding-bottom: 55px; }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text img {
      width: 200px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
    right: -22px !important;
    padding: 22px 50px !important;
    bottom: 0; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text {
    padding-top: 110px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text .img-desc {
      padding: 30px 42px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
    padding: 18px 44px !important;
    padding-top: 22px !important;
    font-size: 16px !important;
    padding-bottom: 22px !important;
    bottom: -330px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos h6 {
      font-size: 26px !important;
      line-height: 38px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos p {
      line-height: 22px !important; } }

@media screen and (max-width: 1200px) {
  #interna.page-template.page-template-template-clientes #brands-content {
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0px 40px; }
    #interna.page-template.page-template-template-clientes #brands-content img {
      margin: 0 25px;
      margin-bottom: 30px; }
  #interna.page-template.page-template-template-clientes #slider .container-slider {
    max-width: calc(100% - 80px); }
    #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
      right: 75px;
      bottom: -365px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
        width: 400px; }
  #interna.page-template.page-template-template-clientes #brands-content-2 {
    margin-bottom: 60px !important; }
    #interna.page-template.page-template-template-clientes #brands-content-2 div {
      flex-wrap: wrap;
      justify-content: space-around;
      margin-bottom: 0 !important; }
      #interna.page-template.page-template-template-clientes #brands-content-2 div img {
        margin: 0 25px;
        margin-bottom: 40px; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text {
    bottom: -20px; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items {
      padding: 60px 20px; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna {
    margin-bottom: 340px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
      bottom: -400px !important;
      left: auto !important;
      right: 30px; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text {
      top: 230px; } }

@media screen and (max-width: 960px) {
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna {
    margin-bottom: 440px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
      padding: 18px 18px 22px !important; } }

@media screen and (max-width: 900px) {
  #interna.page-template.page-template-template-clientes #section-1-interna {
    margin-bottom: 70px !important; }
    #interna.page-template.page-template-template-clientes #section-1-interna .text-top {
      font-size: 15px;
      line-height: 22px;
      padding: 0; }
  #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text {
    text-align: center; }
    #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text img {
      margin: auto;
      margin-bottom: 30px; }
  #interna.page-template.page-template-template-clientes #section-3-interna .section-text {
    padding: 20px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .img-desc {
      bottom: 0px !important;
      top: -110px !important; }
  #interna.page-template.page-template-template-clientes #slider {
    margin-bottom: 120px !important; }
    #interna.page-template.page-template-template-clientes #slider .container-slider {
      max-width: 100%; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
        right: 20px; }
        #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
          width: 300px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination {
        width: 170px;
        height: 60px; }
        #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-next {
          right: 25px; }
        #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-button-prev {
          left: 25px; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text {
    width: 100% !important;
    max-width: calc(100% - 40px) !important;
    left: 20px !important;
    padding: 0 !important; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .img-desc {
      top: -117px !important; }
  #interna.page-template.page-template-template-clientes #brands-content-2 hr {
    display: none !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna {
    margin-bottom: 120px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-1-interna .text-top {
      margin-top: 70px !important; }
      #interna.page-template.page-template-template-clientes_bunge #section-1-interna .text-top p {
        font-size: 15px;
        line-height: 22px; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
    bottom: -50px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text {
    padding-top: 50px !important;
    padding-bottom: 0 !important;
    position: initial; }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text img {
      bottom: auto;
      top: -80px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna {
    margin-bottom: 230px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text {
      text-align: center;
      padding-top: 90px !important; }
      #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text .img-desc {
        bottom: auto !important;
        top: -74px !important;
        left: 50% !important;
        transform: translateX(0%);
        padding: 20px 32px !important;
        width: 380px !important;
        margin-left: -190px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .casos {
      left: 50% !important;
      width: calc(100% - 70px);
      transform: translate(-50%, 50%);
      bottom: -138px !important; } }

@media screen and (max-width: 640px) {
  #interna.page-template.page-template-template-clientes #section-1-interna .img-kicker-container {
    padding: 20px !important; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items {
    flex-direction: column; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items img {
      margin-bottom: 40px; }
      #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items img:last-child {
        margin-bottom: 0; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna .content-video {
    padding-bottom: 0;
    height: 300px; }
    #interna.page-template.page-template-template-clientes_bunge #section-1-interna .content-video video {
      width: auto;
      height: 100%; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
    padding: 20px 15px !important; }
  #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text {
    padding-top: 70px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-3-interna .section-text .img-desc {
      top: -54px !important;
      width: 274px !important;
      margin-left: -137px !important; } }

@media screen and (max-width: 499px) {
  #interna.page-template.page-template-template-clientes .img-desc {
    width: 100px !important;
    height: 100px !important; }
  #interna.page-template.page-template-template-clientes #section-1-interna {
    margin-bottom: 50px !important; }
  #interna.page-template.page-template-template-clientes #section-2-interna .flex-container .section-text img {
    margin: auto auto 25px; }
  #interna.page-template.page-template-template-clientes #section-3-interna .section-text {
    padding: 10px !important; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .img-desc {
      top: -83px !important; }
    #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 0;
      grid-row-gap: 0; }
      #interna.page-template.page-template-template-clientes #section-3-interna .section-text .content-items .item {
        padding: 25px !important; }
  #interna.page-template.page-template-template-clientes #brands-content {
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 40px; }
    #interna.page-template.page-template-template-clientes #brands-content img {
      max-width: 70%;
      max-height: 65px;
      margin: auto;
      display: block; }
  #interna.page-template.page-template-template-clientes #slider {
    margin-bottom: 100px !important; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .content-pagination .swiper-pagination {
      font-size: 14px; }
    #interna.page-template.page-template-template-clientes #slider .container-slider .image-float {
      width: 50%;
      bottom: -280px; }
      #interna.page-template.page-template-template-clientes #slider .container-slider .image-float img {
        width: 100%; }
  #interna.page-template.page-template-template-clientes #brands-content-2 {
    padding: 0 20px;
    margin-bottom: 50px !important; }
    #interna.page-template.page-template-template-clientes #brands-content-2 div {
      padding: 0 !important;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 10px;
      grid-row-gap: 40px; }
      #interna.page-template.page-template-template-clientes #brands-content-2 div img {
        max-width: 70%;
        max-height: 65px;
        margin: auto;
        display: block; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text .img-desc {
    top: -80px !important; }
  #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items {
    padding: 40px 20px;
    padding-top: 50px; }
    #interna.page-template.page-template-template-clientes #section-4-interna .section-text .content-items img {
      max-width: 50%; }
  #interna.page-template.page-template-template-clientes_bunge #section-1-interna {
    margin-bottom: 75px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-1-interna figure img {
      object-position: 23% center; }
  #interna.page-template.page-template-template-clientes_bunge #section-2-interna {
    margin-top: 50px;
    padding-top: 35px !important; }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-img .img-desc {
      padding: 10px 10px !important;
      width: calc(100% - 60px);
      left: 30px !important;
      transform: translateX(0%); }
    #interna.page-template.page-template-template-clientes_bunge #section-2-interna .section-text img {
      width: 170px !important;
      top: -68px !important; } }

#interna.page-template-template-soluciones,
[class*="page-template-template-soluciones"].page-child#interna {
  /* Section 1 */
  /* Section 2 - section-2-interna */
  /* Section 3 */
  /* Section 4 */
  /* Section 5 */
  /* Section 6 */
  /* Section 7 */ }
  #interna.page-template-template-soluciones #section-1-interna .img-kicker-container,
  [class*="page-template-template-soluciones"].page-child#interna #section-1-interna .img-kicker-container {
    background: rgba(90, 187, 143, 0.9);
    padding: 93px 20px; }
  #interna.page-template-template-soluciones #discover,
  #interna.page-template-template-soluciones #section-2-interna,
  [class*="page-template-template-soluciones"].page-child#interna #discover,
  [class*="page-template-template-soluciones"].page-child#interna #section-2-interna {
    padding-right: 30px;
    margin-bottom: 200px; }
    #interna.page-template-template-soluciones #discover .img-desc,
    #interna.page-template-template-soluciones #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #discover .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .img-desc {
      position: absolute;
      top: 168px;
      right: -40px;
      transform: translate(0, 0); }
      #interna.page-template-template-soluciones #discover .img-desc img,
      #interna.page-template-template-soluciones #section-2-interna .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna #discover .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .img-desc img {
        width: 131px; }
    #interna.page-template-template-soluciones #discover .section-text,
    #interna.page-template-template-soluciones #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #discover .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text {
      padding-top: 182px;
      padding-left: 30px; }
      #interna.page-template-template-soluciones #discover .section-text h2,
      #interna.page-template-template-soluciones #section-2-interna .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #discover .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text h2 {
        margin-bottom: 40px !important; }
      #interna.page-template-template-soluciones #discover .section-text ul li,
      #interna.page-template-template-soluciones #section-2-interna .section-text ul li,
      [class*="page-template-template-soluciones"].page-child#interna #discover .section-text ul li,
      [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text ul li {
        margin-bottom: 30px; }
        #interna.page-template-template-soluciones #discover .section-text ul li strong,
        #interna.page-template-template-soluciones #section-2-interna .section-text ul li strong,
        [class*="page-template-template-soluciones"].page-child#interna #discover .section-text ul li strong,
        [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text ul li strong {
          text-transform: uppercase;
          display: block; }
        #interna.page-template-template-soluciones #discover .section-text ul li:last-child,
        #interna.page-template-template-soluciones #section-2-interna .section-text ul li:last-child,
        [class*="page-template-template-soluciones"].page-child#interna #discover .section-text ul li:last-child,
        [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text ul li:last-child {
          margin-bottom: 0; }
    #interna.page-template-template-soluciones #discover h2,
    #interna.page-template-template-soluciones #section-2-interna h2,
    [class*="page-template-template-soluciones"].page-child#interna #discover h2,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna h2 {
      color: #6de3ae; }
  #interna.page-template-template-soluciones #implement .img-desc,
  #interna.page-template-template-soluciones #section-3-interna .img-desc,
  [class*="page-template-template-soluciones"].page-child#interna #implement .img-desc,
  [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .img-desc {
    top: -150px;
    left: 100px; }
  #interna.page-template-template-soluciones #implement .flex-container .section-text,
  #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
  [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text,
  [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
    padding-left: 99px;
    padding-top: 90px;
    padding-bottom: 63px;
    bottom: -60px; }
    #interna.page-template-template-soluciones #implement .flex-container .section-text h2,
    #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text h2 {
      color: #6de3ae;
      margin-bottom: 44px !important; }
  #interna.page-template-template-soluciones #section-4-interna,
  [class*="page-template-template-soluciones"].page-child#interna #section-4-interna {
    background: rgba(106, 194, 154, 0.9);
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    padding: 112px 0 120px;
    margin-bottom: -240px;
    top: 50px;
    max-width: initial;
    z-index: 7; }
    #interna.page-template-template-soluciones #section-4-interna .content-items,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items {
      max-width: 1542px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin: auto; }
      #interna.page-template-template-soluciones #section-4-interna .content-items .icon,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon {
        padding-bottom: 53px;
        margin-bottom: 66px;
        position: relative; }
        #interna.page-template-template-soluciones #section-4-interna .content-items .icon:before,
        [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon:before {
          content: '';
          position: absolute;
          bottom: 0;
          width: 80px;
          height: 5px;
          background: white;
          left: 50%;
          transform: translateX(-50%); }
        #interna.page-template-template-soluciones #section-4-interna .content-items .icon img,
        [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon img {
          width: auto;
          margin: auto; }
    #interna.page-template-template-soluciones #section-4-interna .inner-holder,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .inner-holder {
      flex-basis: 444px;
      padding: 0 24px; }
    #interna.page-template-template-soluciones #section-4-interna h2,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna h2 {
      font-size: 45px;
      font-weight: 900;
      line-height: 1;
      color: #353d44;
      margin-bottom: 20px; }
    #interna.page-template-template-soluciones #section-4-interna p.font-700,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna p.font-700 {
      margin-bottom: 28px; }
  #interna.page-template-template-soluciones #operate .img-desc,
  [class*="page-template-template-soluciones"].page-child#interna #operate .img-desc {
    position: absolute;
    top: 112px;
    right: -40px;
    z-index: 7;
    transform: translate(0, 0); }
    #interna.page-template-template-soluciones #operate .img-desc img,
    [class*="page-template-template-soluciones"].page-child#interna #operate .img-desc img {
      width: 144px; }
  #interna.page-template-template-soluciones #operate .section-text,
  [class*="page-template-template-soluciones"].page-child#interna #operate .section-text {
    padding-top: 180px; }
    #interna.page-template-template-soluciones #operate .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #operate .section-text h2 {
      color: #6de3ae;
      margin-bottom: 44px !important; }
    #interna.page-template-template-soluciones #operate .section-text ul li,
    [class*="page-template-template-soluciones"].page-child#interna #operate .section-text ul li {
      margin-bottom: 30px; }
      #interna.page-template-template-soluciones #operate .section-text ul li:last-child,
      [class*="page-template-template-soluciones"].page-child#interna #operate .section-text ul li:last-child {
        margin-bottom: 0; }
      #interna.page-template-template-soluciones #operate .section-text ul li strong,
      [class*="page-template-template-soluciones"].page-child#interna #operate .section-text ul li strong {
        text-transform: uppercase;
        display: block; }
  #interna.page-template-template-soluciones #enable,
  [class*="page-template-template-soluciones"].page-child#interna #enable {
    padding-top: 190px;
    padding-left: 30px;
    margin-bottom: 300px; }
    #interna.page-template-template-soluciones #enable .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #enable .img-desc {
      top: -140px;
      left: 100px; }
    #interna.page-template-template-soluciones #enable .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #enable .section-text {
      padding-left: 100px;
      padding-top: 134px;
      padding-bottom: 75px;
      bottom: -640px;
      left: 0 !important; }
      #interna.page-template-template-soluciones #enable .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text h2 {
        color: #6de3ae;
        margin-bottom: 45px !important; }
      #interna.page-template-template-soluciones #enable .section-text ul li,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text ul li {
        margin-bottom: 30px; }
        #interna.page-template-template-soluciones #enable .section-text ul li strong,
        [class*="page-template-template-soluciones"].page-child#interna #enable .section-text ul li strong {
          text-transform: uppercase;
          display: block; }
        #interna.page-template-template-soluciones #enable .section-text ul li:last-child,
        [class*="page-template-template-soluciones"].page-child#interna #enable .section-text ul li:last-child {
          margin-bottom: 0; }
      #interna.page-template-template-soluciones #enable .section-text .btns .btn,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text .btns .btn {
        margin-right: 60px;
        margin-top: 30px !important; }
        #interna.page-template-template-soluciones #enable .section-text .btns .btn:last-child,
        [class*="page-template-template-soluciones"].page-child#interna #enable .section-text .btns .btn:last-child {
          margin-right: 0 !important; }
    #interna.page-template-template-soluciones #enable .section-img,
    [class*="page-template-template-soluciones"].page-child#interna #enable .section-img {
      max-width: 60%;
      position: absolute;
      top: 0;
      right: 0; }
    #interna.page-template-template-soluciones #enable .btn,
    [class*="page-template-template-soluciones"].page-child#interna #enable .btn {
      height: 61px;
      font-size: 24px;
      line-height: 61px;
      padding: 0 30px;
      background: #5dc295; }
      #interna.page-template-template-soluciones #enable .btn:hover,
      [class*="page-template-template-soluciones"].page-child#interna #enable .btn:hover {
        color: #5dc295;
        border-color: #5dc295;
        background: #ffffff; }
  #interna.page-template-template-soluciones #section-7-interna,
  [class*="page-template-template-soluciones"].page-child#interna #section-7-interna {
    padding-right: 30px;
    margin-bottom: 160px; }
    #interna.page-template-template-soluciones #section-7-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .img-desc {
      top: 170px;
      right: -40px; }
      #interna.page-template-template-soluciones #section-7-interna .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .img-desc img {
        width: 146px; }
    #interna.page-template-template-soluciones #section-7-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .section-text {
      padding-top: 185px;
      padding-left: 30px; }
      #interna.page-template-template-soluciones #section-7-interna .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .section-text h2 {
        color: #6de3ae;
        margin-bottom: 44px !important; }
    #interna.page-template-template-soluciones #section-7-interna .btn,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .btn {
      height: 61px;
      font-size: 24px;
      line-height: 61px;
      padding: 0 34px;
      margin-top: 25px !important; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna {
    padding-right: 0px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc {
      background: transparent;
      padding: 0 !important;
      height: auto;
      width: auto;
      top: 160px; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img {
        width: auto; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container h2,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container h2 {
      margin-bottom: 0 !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container h3,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container h3 {
      margin-top: 0 !important;
      color: #6de3ae; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna {
    margin-bottom: 150px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .img-desc {
      width: 635px;
      height: 235px;
      text-transform: initial;
      font-weight: 400;
      left: -384px;
      top: 25px;
      font-size: 25px;
      padding: 58px 34px 58px 64px;
      background: rgba(53, 61, 68, 0.8);
      z-index: 7;
      text-align: left;
      top: 40px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
      padding-top: 261px;
      padding-left: 30px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text .btn,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text .btn {
        height: 60px;
        line-height: 60px;
        padding: 0 41px;
        margin-top: 30px; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text h2 {
        margin-bottom: 0 !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text h3,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text h3 {
        margin-top: 0 !important;
        color: #6de3ae; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-text {
      max-width: 635px;
      position: absolute;
      left: -384px;
      top: 25px;
      font-size: 25px;
      padding: 58px 34px 58px 64px;
      background: rgba(53, 61, 68, 0.8);
      z-index: 7; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated {
      position: absolute;
      left: 132px;
      transform: translateY(100%);
      bottom: 97px; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-1-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-1-interna {
    margin-bottom: 300px !important; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna {
    margin-bottom: 300px !important;
    padding-right: 0px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .img-desc {
      top: -140px;
      right: 182px; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .img-desc img {
        width: auto; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna h2,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna h2 {
      color: #fff;
      margin-top: 0 !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text {
      max-width: 1122px;
      position: absolute;
      bottom: -420px;
      color: #ffffff;
      padding: 115px 94px 74px 60px !important;
      z-index: 7;
      background: rgba(54, 61, 68, 0.9); }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text .btn,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text .btn {
        height: 58px;
        line-height: 58px; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text .btn:hover,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text .btn:hover {
          color: #5dc295;
          border-color: #5dc295;
          background: #ffffff; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna {
    padding-right: 0px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .section-text {
      padding-top: 0px;
      margin-top: -8px; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .section-text ul li,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .section-text ul li {
        margin-bottom: 0 !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .img-desc {
      right: 113px; }
  #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna,
  [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna {
    margin-bottom: 120px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna .section-text {
      bottom: -230px !important; }
  @media screen and (max-width: 1950px) {
    #interna.page-template-template-soluciones #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-1-interna {
      margin-bottom: 230px !important; }
    #interna.page-template-template-soluciones #discover .img-desc,
    #interna.page-template-template-soluciones #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #discover .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .img-desc {
      right: -22px; }
    #interna.page-template-template-soluciones #discover .section-text h2,
    #interna.page-template-template-soluciones #section-2-interna .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #discover .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text h2 {
      margin-bottom: 24px !important; }
    #interna.page-template-template-soluciones #implement .flex-container .section-text,
    #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
      padding-top: 70px; }
      #interna.page-template-template-soluciones #implement .flex-container .section-text h2,
      #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text h2 {
        margin-bottom: 24px !important; }
    #interna.page-template-template-soluciones #section-4-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna {
      margin-bottom: -290px !important; }
    #interna.page-template-template-soluciones #operate .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #operate .img-desc {
      right: -25px; }
    #interna.page-template-template-soluciones #operate .section-text h2,
    [class*="page-template-template-soluciones"].page-child#interna #operate .section-text h2 {
      margin-bottom: 24px !important; }
    #interna.page-template-template-soluciones #enable,
    [class*="page-template-template-soluciones"].page-child#interna #enable {
      padding-top: 20px; }
      #interna.page-template-template-soluciones #enable .section-text,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text {
        bottom: -480px; }
        #interna.page-template-template-soluciones #enable .section-text h2,
        [class*="page-template-template-soluciones"].page-child#interna #enable .section-text h2 {
          margin-bottom: 24px !important; }
    #interna.page-template-template-soluciones #section-7-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna {
      margin-bottom: 160px !important; }
      #interna.page-template-template-soluciones #section-7-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .img-desc {
        right: -20px; }
      #interna.page-template-template-soluciones #section-7-interna .section-text h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .section-text h2 {
        margin-bottom: 24px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-1-interna {
      margin-bottom: 240px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna {
      margin-bottom: 220px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text {
        bottom: -330px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
      padding-top: 261px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna {
      margin-bottom: 230px !important; } }
  @media screen and (max-width: 1600px) {
    #interna.page-template-template-soluciones #discover .section-text,
    #interna.page-template-template-soluciones #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #discover .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text {
      bottom: -385px; } }
  @media screen and (max-width: 1450px) {
    #interna.page-template-template-soluciones .img-desc.icon-box,
    [class*="page-template-template-soluciones"].page-child#interna .img-desc.icon-box {
      width: 150px !important;
      height: 150px !important; }
      #interna.page-template-template-soluciones .img-desc.icon-box img,
      [class*="page-template-template-soluciones"].page-child#interna .img-desc.icon-box img {
        width: 60% !important; }
    #interna.page-template-template-soluciones #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-1-interna {
      margin-bottom: 170px !important; }
    #interna.page-template-template-soluciones #discover .section-text,
    #interna.page-template-template-soluciones #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #discover .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-2-interna .section-text {
      padding-top: 120px; }
    #interna.page-template-template-soluciones #implement .img-desc,
    #interna.page-template-template-soluciones #section-3-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #implement .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .img-desc {
      top: -97px;
      left: 60px; }
    #interna.page-template-template-soluciones #implement .flex-container .section-text,
    #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
      padding-left: 60px;
      padding-top: 20px;
      padding-bottom: 20px; }
    #interna.page-template-template-soluciones #section-4-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna {
      top: -20px;
      padding: 70px 0 70px;
      margin-bottom: -420px !important; }
      #interna.page-template-template-soluciones #section-4-interna .content-items .icon,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon {
        padding-bottom: 25px;
        margin-bottom: 32px; }
        #interna.page-template-template-soluciones #section-4-interna .content-items .icon img,
        [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon img {
          width: 85px; }
      #interna.page-template-template-soluciones #section-4-interna h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna h2 {
        font-size: 28px;
        line-height: 28px; }
      #interna.page-template-template-soluciones #section-4-interna p,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna p {
        font-size: 16px;
        line-height: 24px; }
    #interna.page-template-template-soluciones #operate .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #operate .flex-container .section-text {
      padding-top: 80px; }
    #interna.page-template-template-soluciones #enable,
    [class*="page-template-template-soluciones"].page-child#interna #enable {
      margin-bottom: 130px !important; }
      #interna.page-template-template-soluciones #enable .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #enable .img-desc {
        top: -100px;
        left: 60px; }
      #interna.page-template-template-soluciones #enable .section-text,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text {
        padding-left: 60px;
        padding-top: 76px;
        padding-bottom: 60px;
        bottom: -540px; }
      #interna.page-template-template-soluciones #enable .btns,
      [class*="page-template-template-soluciones"].page-child#interna #enable .btns {
        justify-content: space-between;
        display: flex; }
        #interna.page-template-template-soluciones #enable .btns .btn,
        [class*="page-template-template-soluciones"].page-child#interna #enable .btns .btn {
          padding: 0 24px;
          height: 60px;
          line-height: 60px;
          font-size: 16px;
          margin-right: 0 !important; }
    #interna.page-template-template-soluciones #section-7-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .section-text {
      padding-top: 120px; }
    #interna.page-template-template-soluciones #section-7-interna .btn,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .btn {
      padding: 0 24px;
      height: 60px;
      line-height: 60px;
      font-size: 16px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-1-interna {
      margin-bottom: 170px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna {
      margin-bottom: 280px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .img-desc {
        top: -76px;
        right: 145px; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text {
        padding: 105px 94px 74px 50px !important;
        bottom: -406px; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text h2,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text h2 {
          line-height: 34px !important; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text .btn,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text .btn {
          margin-top: 20px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-1-interna {
      margin-bottom: 170px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img {
      width: 200px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .img-desc {
      max-width: 480px;
      left: -310px;
      top: -25px;
      font-size: 18px;
      line-height: 24px;
      padding: 40px;
      height: auto; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
      padding-top: 180px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated img,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated img {
      width: 80%; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-1-interna {
      margin-bottom: 170px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .section-text ul li,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .section-text ul li {
      margin-bottom: 5px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna .section-text {
      padding-top: 45px !important;
      padding-bottom: 40px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna .btn,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna .btn {
      margin-top: 10px; } }
  @media screen and (max-width: 1280px) {
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom .flex-container.data-right.overlay-content .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom .flex-container.data-right.overlay-content .section-text {
      max-width: 75% !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-1-interna {
      margin-bottom: 240px !important; } }
  @media screen and (max-width: 1200px) {
    #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
      bottom: -130px; }
    #interna.page-template-template-soluciones #enable .flex-container,
    [class*="page-template-template-soluciones"].page-child#interna #enable .flex-container {
      padding-left: 30px !important; }
    #interna.page-template-template-soluciones #enable .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #enable .section-text {
      max-width: 75% !important; }
    #interna.page-template-template-soluciones #enable .btns .btn,
    [class*="page-template-template-soluciones"].page-child#interna #enable .btns .btn {
      padding: 0 12px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
      padding-top: 150px; } }
  @media screen and (max-width: 900px) {
    #interna.page-template-template-soluciones #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-1-interna {
      margin-bottom: 110px !important; }
    #interna.page-template-template-soluciones #discover,
    [class*="page-template-template-soluciones"].page-child#interna #discover {
      padding-right: 0px; }
      #interna.page-template-template-soluciones #discover .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #discover .img-desc {
        margin-left: -75px !important; }
    #interna.page-template-template-soluciones #implement .img-desc,
    #interna.page-template-template-soluciones #section-3-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #implement .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .img-desc {
      top: -96px !important; }
    #interna.page-template-template-soluciones #implement .flex-container .section-text,
    #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
      bottom: 40px; }
    #interna.page-template-template-soluciones #section-4-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna {
      padding: 50px 0;
      top: -55px;
      margin-bottom: -130px !important; }
      #interna.page-template-template-soluciones #section-4-interna .inner-holder,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .inner-holder {
        flex-basis: initial;
        padding: 0 20px; }
      #interna.page-template-template-soluciones #section-4-interna .content-items,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; }
        #interna.page-template-template-soluciones #section-4-interna .content-items .icon,
        [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon {
          padding-bottom: 15px;
          margin-bottom: 24px; }
          #interna.page-template-template-soluciones #section-4-interna .content-items .icon:before,
          [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items .icon:before {
            height: 2px;
            width: 50px; }
        #interna.page-template-template-soluciones #section-4-interna .content-items > div,
        [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div {
          position: relative;
          margin-bottom: 40px;
          padding-bottom: 40px; }
          #interna.page-template-template-soluciones #section-4-interna .content-items > div .font-700,
          [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div .font-700 {
            margin-bottom: 20px !important; }
          #interna.page-template-template-soluciones #section-4-interna .content-items > div p,
          [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div p {
            font-size: 15px;
            line-height: 24px; }
          #interna.page-template-template-soluciones #section-4-interna .content-items > div:before,
          [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            height: 2px;
            background: white; }
          #interna.page-template-template-soluciones #section-4-interna .content-items > div.licensing,
          [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div.licensing {
            margin-bottom: 0 !important;
            padding-bottom: 0 !important; }
            #interna.page-template-template-soluciones #section-4-interna .content-items > div.licensing:before,
            [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div.licensing:before {
              content: none !important; }
    #interna.page-template-template-soluciones #operate .flex-container .section-img .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #operate .flex-container .section-img .img-desc {
      margin-left: -75px; }
    #interna.page-template-template-soluciones #enable,
    [class*="page-template-template-soluciones"].page-child#interna #enable {
      padding-top: 0;
      padding-left: 0;
      margin-bottom: -32px !important; }
      #interna.page-template-template-soluciones #enable .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #enable .img-desc {
        top: -132px !important; }
      #interna.page-template-template-soluciones #enable .flex-container,
      [class*="page-template-template-soluciones"].page-child#interna #enable .flex-container {
        padding-left: 0 !important; }
        #interna.page-template-template-soluciones #enable .flex-container .section-text,
        [class*="page-template-template-soluciones"].page-child#interna #enable .flex-container .section-text {
          max-width: initial !important;
          bottom: -20px !important;
          left: 20px !important; }
      #interna.page-template-template-soluciones #enable .btns,
      [class*="page-template-template-soluciones"].page-child#interna #enable .btns {
        justify-content: center; }
        #interna.page-template-template-soluciones #enable .btns .btn,
        [class*="page-template-template-soluciones"].page-child#interna #enable .btns .btn {
          margin-right: 15px !important;
          line-height: 20px;
          font-size: 16px;
          padding: 14px 10px !important;
          display: flex;
          justify-content: center;
          align-items: center; }
          #interna.page-template-template-soluciones #enable .btns .btn:last-child,
          [class*="page-template-template-soluciones"].page-child#interna #enable .btns .btn:last-child {
            margin-right: 0 !important; }
    #interna.page-template-template-soluciones #section-7-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-7-interna {
      margin-bottom: 80px !important;
      padding-right: 0 !important; }
      #interna.page-template-template-soluciones #section-7-interna .flex-container,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .flex-container {
        text-align: center; }
      #interna.page-template-template-soluciones #section-7-interna .btn,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .btn {
        margin-top: 10px !important; }
      #interna.page-template-template-soluciones #section-7-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #section-7-interna .img-desc {
        bottom: -40px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-1-interna {
      margin-bottom: 110px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna {
      margin-bottom: 0px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .img-desc {
        top: -105px !important;
        left: 50% !important;
        right: auto !important;
        transform: translateX(-50%); }
      #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text {
        padding: 80px 50px 50px 50px !important;
        bottom: 0;
        max-width: 100% !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-1-interna {
      margin-bottom: 135px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna {
      margin-bottom: 60px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .img-desc {
        margin-left: -100px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna h3,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna h3 {
        text-align: center;
        margin-top: 5px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna {
      margin-bottom: 60px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .img-desc {
        bottom: auto !important;
        top: 50px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna h3,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna h3 {
        text-align: center;
        margin-top: 5px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated {
        width: 45%;
        left: 30px;
        bottom: 130px;
        z-index: 7; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated img,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated img {
          width: 100%; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
        max-width: 100% !important;
        text-align: center; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text .btn,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text .btn {
          margin-top: 10px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-1-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-1-interna {
      margin-bottom: 120px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .img-desc {
      bottom: -48px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .section-text {
      bottom: 0px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna {
      margin-bottom: 0px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna .section-text {
        bottom: 0 !important; }
        #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna .section-text ul li,
        [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna .section-text ul li {
          margin-bottom: 5px !important; } }
  @media screen and (max-width: 640px) {
    #interna.page-template-template-soluciones .img-desc.icon-box,
    [class*="page-template-template-soluciones"].page-child#interna .img-desc.icon-box {
      width: 100px !important;
      height: 100px !important; }
    #interna.page-template-template-soluciones #discover .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #discover .img-desc {
      bottom: -35px !important;
      margin-left: -50px !important; }
    #interna.page-template-template-soluciones #implement,
    #interna.page-template-template-soluciones #section-3-interna,
    [class*="page-template-template-soluciones"].page-child#interna #implement,
    [class*="page-template-template-soluciones"].page-child#interna #section-3-interna {
      margin-bottom: 30px !important; }
      #interna.page-template-template-soluciones #implement .img-desc,
      #interna.page-template-template-soluciones #section-3-interna .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #implement .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .img-desc {
        top: -70px !important; }
      #interna.page-template-template-soluciones #implement .flex-container .section-text,
      #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text,
      [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text,
      [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text {
        bottom: -20px; }
        #interna.page-template-template-soluciones #implement .flex-container .section-text h2,
        #interna.page-template-template-soluciones #section-3-interna .flex-container .section-text h2,
        [class*="page-template-template-soluciones"].page-child#interna #implement .flex-container .section-text h2,
        [class*="page-template-template-soluciones"].page-child#interna #section-3-interna .flex-container .section-text h2 {
          margin-top: 10px !important; }
    #interna.page-template-template-soluciones #section-4-interna,
    [class*="page-template-template-soluciones"].page-child#interna #section-4-interna {
      margin-bottom: -75px !important;
      top: 0; }
      #interna.page-template-template-soluciones #section-4-interna h2,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna h2 {
        font-size: 24px;
        line-height: 24px; }
      #interna.page-template-template-soluciones #section-4-interna .content-items > div,
      [class*="page-template-template-soluciones"].page-child#interna #section-4-interna .content-items > div {
        margin-bottom: 30px;
        padding-bottom: 30px; }
    #interna.page-template-template-soluciones #operate .flex-container .section-img .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna #operate .flex-container .section-img .img-desc {
      margin-left: -50px;
      bottom: -26px !important; }
    #interna.page-template-template-soluciones #enable,
    [class*="page-template-template-soluciones"].page-child#interna #enable {
      margin-bottom: -60px !important; }
      #interna.page-template-template-soluciones #enable .img-desc,
      [class*="page-template-template-soluciones"].page-child#interna #enable .img-desc {
        top: -80px !important; }
      #interna.page-template-template-soluciones #enable .section-text .btns,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text .btns {
        flex-direction: column;
        margin-top: 30px !important; }
        #interna.page-template-template-soluciones #enable .section-text .btns .btn,
        [class*="page-template-template-soluciones"].page-child#interna #enable .section-text .btns .btn {
          margin-right: 0 !important;
          margin-top: 15px !important; }
          #interna.page-template-template-soluciones #enable .section-text .btns .btn:first-child,
          [class*="page-template-template-soluciones"].page-child#interna #enable .section-text .btns .btn:first-child {
            margin-top: 0 !important; }
      #interna.page-template-template-soluciones #enable .section-text ul,
      [class*="page-template-template-soluciones"].page-child#interna #enable .section-text ul {
        margin-top: 24px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .img-desc {
      top: -55px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_irom #section-2-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_irom #section-2-interna .section-text {
      padding: 64px 20px 50px 20px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc {
      margin-left: -75px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .flex-container .img-desc img {
        width: 140px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .img-desc {
      left: 20px;
      top: 120px;
      max-width: 399px;
      font-size: 15px;
      line-height: 21px;
      padding: 20px; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-2-interna .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-2-interna .img-desc {
      bottom: -30px !important;
      left: 20px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_perfbooster #section-3-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_perfbooster #section-3-interna {
      margin-bottom: -20px !important; } }
  @media screen and (max-width: 499px) {
    #interna.page-template-template-soluciones .img-desc.icon-box,
    [class*="page-template-template-soluciones"].page-child#interna .img-desc.icon-box {
      width: 90px !important;
      height: 90px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna {
      margin-bottom: 150px !important; }
      #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-2-interna .section-text,
      [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-2-interna .section-text {
        padding-top: 55px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .img-desc,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .img-desc {
      width: calc( 100% - 40px);
      transform: translate(0);
      left: 20px !important;
      text-align: center;
      top: -84px !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-img .overlay-certificated {
      width: 160px;
      left: 50%;
      bottom: -90px;
      z-index: 7;
      transform: translateX(-50%) !important; }
    #interna.page-template-template-soluciones.page-template-template-soluciones_training #section-3-interna .section-text,
    [class*="page-template-template-soluciones"].page-child#interna.page-template-template-soluciones_training #section-3-interna .section-text {
      padding-top: 100px !important; } }

#interna.page-template-template-tecnologias_clave .img-kicker-container,
#interna.page-template-template-tecnologias_clave .box-overlay {
  background: rgba(116, 53, 155, 0.85) !important; }

#interna.page-template-template-tecnologias_clave .img-t-board,
#interna.page-template-template-tecnologias_clave .img-r-board,
#interna.page-template-template-tecnologias_clave .img-l-board {
  z-index: 0 !important; }

#interna.page-template-template-tecnologias_clave .flex-container .section-text img,
#interna.page-template-template-tecnologias_clave .flex-container .section-overlay-text img {
  margin-bottom: 58px; }

#interna.page-template-template-tecnologias_clave #section-1-interna .img-kicker-container {
  padding: 91px 20px; }

#interna.page-template-template-tecnologias_clave #section-1-interna .content-video {
  padding-bottom: 34.25%; }
  #interna.page-template-template-tecnologias_clave #section-1-interna .content-video video {
    width: 100%;
    height: auto; }

#interna.page-template-template-tecnologias_clave #section-2-interna {
  margin-bottom: 160px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-text {
    padding-top: 170px; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .effect-y-300 {
    position: absolute;
    top: 100px;
    right: 352px;
    z-index: 7; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .box-overlay {
    width: 386px;
    height: 237px;
    top: 96px; }

#interna.page-template-template-tecnologias_clave #section-3-interna {
  margin-bottom: 260px; }
  #interna.page-template-template-tecnologias_clave #section-3-interna .flex-container.data-left .section-text {
    padding: 113px 113px 45px 113px;
    margin-top: 365px; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .flex-container.data-left .section-text .box-overlay {
      left: 113px;
      transform: translate(0);
      width: 100%;
      max-width: 385px;
      height: 185px;
      top: -125px; }

#interna.page-template-template-tecnologias_clave #section-4-interna {
  margin-bottom: 160px; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-text {
    padding-top: 158px; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-img .effect-y-300 {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 7; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-img .box-overlay {
    width: 386px;
    height: 227px;
    top: 168px;
    right: -33px; }

#interna.page-template-template-tecnologias_clave #section-5-interna {
  margin-bottom: 220px; }
  #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text {
    padding: 110px;
    padding-top: 196px;
    padding-bottom: 56px;
    margin-top: 370px; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text h2 {
      color: white; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
      width: 549px;
      height: 267px;
      transform: translate(0);
      left: 105px;
      top: -128px; }

#interna.page-template-template-tecnologias_clave #section-6-interna {
  margin-bottom: 200px; }
  #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text {
    padding-left: 110px !important;
    padding-right: 110px !important;
    padding-top: 158px;
    padding-bottom: 90px;
    background: rgba(116, 53, 155, 0.9);
    margin-top: 335px; }
    #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text h2 {
      color: white; }
    #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text .box-overlay {
      width: 455px;
      height: 237px;
      background: rgba(53, 61, 68, 0.9) !important;
      transform: translate(0);
      right: 130px;
      top: -134px; }

@media screen and (max-width: 1950px) {
  #interna.page-template-template-tecnologias_clave #section-1-interna {
    margin-bottom: 220px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna {
    margin-bottom: 100px !important; }
    #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .effect-y-300 {
      right: 302px; }
      #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .effect-y-300 .box-overlay {
        width: 320px;
        height: 180px; }
  #interna.page-template-template-tecnologias_clave #section-3-interna {
    margin-bottom: 100px !important; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .flex-container.data-left .section-text {
      margin-top: 300px; }
      #interna.page-template-template-tecnologias_clave #section-3-interna .flex-container.data-left .section-text .box-overlay {
        max-width: 300px;
        height: 132px;
        top: -62px; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-img .box-overlay {
    width: 330px;
    height: 185px;
    top: 190px;
    right: -14px; }
  #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text {
    padding: 160px 110px 56px;
    margin-top: 310px; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
      width: 470px;
      height: 220px;
      left: 105px;
      top: -102px; }
  #interna.page-template-template-tecnologias_clave #section-6-interna {
    margin-bottom: 200px !important; } }

@media screen and (max-width: 1450px) {
  #interna.page-template-template-tecnologias_clave .flex-container .section-text img,
  #interna.page-template-template-tecnologias_clave .flex-container .section-overlay-text img {
    margin-bottom: 30px; }
  #interna.page-template-template-tecnologias_clave #section-1-interna {
    margin-bottom: 160px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-text {
    padding-top: 125px; }
    #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-text img {
      width: 244px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .effect-y-300 {
    right: 212px;
    top: 88px; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .img-desc {
    font-size: 18px;
    line-height: 24px;
    width: auto !important;
    height: auto !important;
    padding: 25px 20px; }
  #interna.page-template-template-tecnologias_clave #section-3-interna .section-text {
    padding-bottom: 30px !important;
    padding: 70px 60px 45px !important;
    margin-top: 250px !important; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .section-text .box-overlay {
      font-size: 18px;
      line-height: 24px;
      width: auto !important;
      height: auto !important;
      top: -40px !important;
      left: 60px !important;
      padding: 25px 20px; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .section-text img {
      width: 175px !important; }
  #interna.page-template-template-tecnologias_clave #section-4-interna {
    margin-bottom: 100px; }
    #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-img .box-overlay {
      width: 224px !important; }
    #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-text {
      padding-top: 110px; }
      #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-text img {
        width: 300px !important; }
    #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .box-overlay {
      font-size: 18px;
      line-height: 24px;
      width: auto !important;
      height: auto !important;
      padding: 25px 20px;
      top: 227px;
      right: -17px; }
  #interna.page-template-template-tecnologias_clave #section-5-interna {
    margin-bottom: 110px; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text {
      padding: 75px 60px 25px;
      margin-top: 260px; }
      #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
        font-size: 18px;
        line-height: 24px;
        width: auto !important;
        height: auto !important;
        padding: 20px 10px;
        left: 60px !important;
        top: -86px !important;
        max-width: 315px; }
      #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text img {
        width: 240px !important;
        margin-top: 20px; }
  #interna.page-template-template-tecnologias_clave #section-6-interna {
    margin-bottom: 160px; }
    #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text {
      padding-left: 80px !important;
      padding-right: 80px !important;
      padding-top: 90px !important;
      padding-bottom: 20px !important;
      margin-top: 275px; }
      #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text .box-overlay {
        max-width: 300px !important;
        font-size: 18px;
        line-height: 24px;
        width: auto !important;
        height: auto !important;
        padding: 25px 20px;
        left: auto !important;
        top: -60px !important; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-tecnologias_clave .effect-y-300 {
    position: initial !important; }
  #interna.page-template-template-tecnologias_clave .section-text img {
    margin-left: auto !important;
    margin-right: auto !important; }
  #interna.page-template-template-tecnologias_clave #section-1-interna {
    margin-bottom: 110px !important; }
    #interna.page-template-template-tecnologias_clave #section-1-interna .box-overlay {
      bottom: -50px !important;
      max-width: 220px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna {
    margin-bottom: 60px !important; }
    #interna.page-template-template-tecnologias_clave #section-2-interna .box-overlay {
      max-width: 200px !important;
      bottom: -35px !important; }
  #interna.page-template-template-tecnologias_clave #section-3-interna {
    margin-bottom: 0px !important; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .section-text {
      margin-top: 0px !important;
      padding-top: 80px !important;
      padding-bottom: 20px !important; }
      #interna.page-template-template-tecnologias_clave #section-3-interna .section-text .box-overlay {
        bottom: auto !important;
        top: -50px !important;
        max-width: 200px !important;
        left: 50% !important;
        margin-left: -100px !important; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .img-desc {
    bottom: -30px !important; }
  #interna.page-template-template-tecnologias_clave #section-5-interna {
    margin-bottom: 0px !important; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text {
      padding-bottom: 30px !important;
      margin-top: -40px; }
      #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
        top: -108px !important;
        bottom: auto !important;
        left: 50% !important;
        transform: translateX(-50%); }
  #interna.page-template-template-tecnologias_clave #section-6-interna {
    margin-bottom: 30px !important; }
    #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text {
      max-width: 100% !important;
      margin-top: 0 !important;
      padding-top: 80px !important;
      padding-left: 20px !important;
      padding-right: 20px !important; }
      #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text .box-overlay {
        top: -66px !important;
        bottom: auto !important;
        right: auto !important;
        left: 50% !important;
        transform: translateX(-50%); } }

@media screen and (max-width: 640px) {
  #interna.page-template-template-tecnologias_clave #section-1-interna .content-video {
    padding-bottom: 0;
    height: 300px; }
    #interna.page-template-template-tecnologias_clave #section-1-interna .content-video video {
      width: auto;
      height: 100%; }
  #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text .box-overlay {
    width: 100% !important;
    max-width: 290px;
    width: 100% !important;
    max-width: 290px; } }

@media screen and (max-width: 480px) {
  #interna.page-template-template-tecnologias_clave #section-1-interna {
    margin-bottom: 100px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-img .box-overlay {
    width: 165px !important; }
  #interna.page-template-template-tecnologias_clave #section-2-interna .flex-container.data-right .section-text img {
    width: 205px !important; }
  #interna.page-template-template-tecnologias_clave #section-3-interna {
    margin-bottom: 0px; }
    #interna.page-template-template-tecnologias_clave #section-3-interna .section-text {
      padding: 70px 20px 45px !important; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-img .box-overlay {
    width: 180px !important; }
  #interna.page-template-template-tecnologias_clave #section-4-interna .flex-container.data-right .section-text img {
    width: 220px !important; }
  #interna.page-template-template-tecnologias_clave #section-5-interna {
    margin-bottom: 0; }
    #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text {
      margin-top: 85px; }
      #interna.page-template-template-tecnologias_clave #section-5-interna .flex-container.data-left.overlay-content .section-text img {
        width: 175px !important; }
  #interna.page-template-template-tecnologias_clave #section-6-interna {
    margin-bottom: 0; }
    #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text {
      margin-top: 40px !important; }
      #interna.page-template-template-tecnologias_clave #section-6-interna .flex-container.data-right.overlay-content .section-text .box-overlay {
        width: 220px !important;
        right: auto !important;
        left: 50% !important; } }

#interna.page-template-template-rpa .img-desc {
  background: rgba(229, 179, 0, 0.85) !important; }

#interna.page-template-template-rpa #section-1-interna {
  margin-bottom: 130px; }
  #interna.page-template-template-rpa #section-1-interna .img-kicker-container {
    background: rgba(229, 179, 0, 0.85) !important;
    padding: 103px 100px; }
  #interna.page-template-template-rpa #section-1-interna .text-top {
    width: 100%;
    max-width: 1152px;
    margin: auto;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin-top: 240px; }
    #interna.page-template-template-rpa #section-1-interna .text-top p {
      color: #414d5b; }

#interna.page-template-template-rpa #section-2-interna {
  margin-bottom: 170px; }
  #interna.page-template-template-rpa #section-2-interna .img-desc {
    width: 385px;
    height: 290px;
    top: 120px;
    right: -50px; }
  #interna.page-template-template-rpa #section-2-interna .section-text {
    padding-top: 220px; }
    #interna.page-template-template-rpa #section-2-interna .section-text h2 {
      color: #e9bc00;
      line-height: 50px;
      margin-bottom: 32px !important; }
    #interna.page-template-template-rpa #section-2-interna .section-text p,
    #interna.page-template-template-rpa #section-2-interna .section-text ul li {
      color: #414d5b; }

#interna.page-template-template-rpa #section-3-interna {
  margin-bottom: 170px; }
  #interna.page-template-template-rpa #section-3-interna .section-text {
    padding-left: 122px;
    padding-top: 101px;
    padding-bottom: 101px;
    top: 306px; }
    #interna.page-template-template-rpa #section-3-interna .section-text p {
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 0; }

#interna.page-template-template-rpa #section-4-interna {
  margin-bottom: 160px; }
  #interna.page-template-template-rpa #section-4-interna .section-text {
    padding-left: 50px !important;
    padding-top: 101px;
    padding-bottom: 71px;
    padding-right: 175px;
    top: 424px; }
    #interna.page-template-template-rpa #section-4-interna .section-text .img-desc {
      width: 437px;
      height: 181px;
      right: 158px;
      top: -125px; }
  #interna.page-template-template-rpa #section-4-interna .btn {
    margin-top: 15px; }

@media screen and (max-width: 1950px) {
  #interna.page-template-template-rpa #section-1-interna .text-top {
    margin-top: 140px; }
  #interna.page-template-template-rpa #section-2-interna .img-desc {
    width: 336px;
    height: 249px;
    right: 5px; }
  #interna.page-template-template-rpa #section-2-interna .section-text {
    padding-top: 130px; }
  #interna.page-template-template-rpa #section-3-interna .section-text {
    padding-left: 90px;
    padding-top: 80px;
    padding-bottom: 80px; }
    #interna.page-template-template-rpa #section-3-interna .section-text p {
      font-size: 42px;
      line-height: 49px; }
  #interna.page-template-template-rpa #section-4-interna {
    margin-bottom: 160px !important; }
    #interna.page-template-template-rpa #section-4-interna .section-text {
      padding-left: 80px !important;
      padding-top: 80px;
      padding-bottom: 54px;
      padding-right: 112px;
      top: 362px; }
      #interna.page-template-template-rpa #section-4-interna .section-text .img-desc {
        width: 340px;
        height: 140px;
        top: -99px; } }

@media screen and (max-width: 1450px) {
  #interna.page-template-template-rpa #section-1-interna .text-top {
    margin-top: 110px; }
  #interna.page-template-template-rpa #section-2-interna .img-desc {
    width: 256px;
    height: 200px;
    right: -12px; }
  #interna.page-template-template-rpa #section-2-interna .section-text {
    padding-top: 100px; }
    #interna.page-template-template-rpa #section-2-interna .section-text h2 {
      margin-bottom: 20px !important; }
  #interna.page-template-template-rpa #section-3-interna .section-text {
    padding: 70px 80px; }
    #interna.page-template-template-rpa #section-3-interna .section-text p {
      font-size: 35px;
      line-height: 40px; }
  #interna.page-template-template-rpa #section-4-interna .section-text {
    padding-left: 60px !important;
    padding-top: 65px;
    padding-bottom: 30px;
    padding-right: 90px; }
    #interna.page-template-template-rpa #section-4-interna .section-text .img-desc {
      width: 250px;
      height: 105px;
      top: -76px;
      right: 90px; } }

@media screen and (max-width: 1280px) {
  #interna.page-template-template-rpa #section-3-interna .section-text {
    padding: 60px 50px;
    top: 270px;
    max-width: 75% !important; }
    #interna.page-template-template-rpa #section-3-interna .section-text p br {
      display: none; }
  #interna.page-template-template-rpa #section-4-interna .section-text {
    top: 330px; } }

@media screen and (max-width: 1020px) {
  #interna.page-template-template-rpa #section-3-interna {
    margin-bottom: 90px !important; }
    #interna.page-template-template-rpa #section-3-interna .section-text {
      top: 170px; }
  #interna.page-template-template-rpa #section-4-interna .section-text {
    padding-left: 50px !important;
    padding-top: 50px;
    padding-bottom: 15px;
    padding-right: 50px; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-rpa #section-1-interna .text-top {
    margin-top: 70px; }
    #interna.page-template-template-rpa #section-1-interna .text-top p {
      font-size: 15px;
      line-height: 22px; }
  #interna.page-template-template-rpa #section-2-interna .img-desc {
    width: 210px;
    height: 150px; }
  #interna.page-template-template-rpa #section-2-interna ul li {
    margin-bottom: 0px !important; }
  #interna.page-template-template-rpa #section-3-interna {
    margin-bottom: 20px !important; }
    #interna.page-template-template-rpa #section-3-interna .section-text {
      max-width: initial !important; }
      #interna.page-template-template-rpa #section-3-interna .section-text p {
        font-size: 24px;
        line-height: 30px; }
  #interna.page-template-template-rpa #section-4-interna {
    margin-bottom: 40px !important; }
    #interna.page-template-template-rpa #section-4-interna .img-desc {
      bottom: auto !important;
      top: -80px !important;
      width: 210px;
      height: 86px; }
    #interna.page-template-template-rpa #section-4-interna .section-text {
      max-width: initial !important;
      padding-top: 50px !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 20px !important; } }

@media screen and (max-width: 640px) {
  #interna.page-template-template-rpa .flex-container.overlay-content .section-text {
    margin-top: 0; }
  #interna.page-template-template-rpa #section-1-interna .text-top {
    padding: 0 20px;
    margin-top: 80px; }
  #interna.page-template-template-rpa #section-2-interna .img-desc {
    width: 170px;
    height: 118px; }
  #interna.page-template-template-rpa #section-2-interna .section-text h2 {
    margin-bottom: 15px !important; }
  #interna.page-template-template-rpa #section-3-interna {
    margin-bottom: 0px !important; }
    #interna.page-template-template-rpa #section-3-interna .section-text p {
      font-size: 18px;
      line-height: 24px; }
  #interna.page-template-template-rpa #section-4-interna {
    margin-bottom: 0px !important; }
    #interna.page-template-template-rpa #section-4-interna .section-text {
      padding: 40px 20px 20px !important; }
      #interna.page-template-template-rpa #section-4-interna .section-text .img-desc {
        width: 180px;
        height: 75px;
        top: -52px !important; } }

#interna.page-template-template-conocenos .img-kicker-container,
#interna.page-template-template-conocenos .box-overlay,
#interna.page-template-template-conocenos .img-desc {
  background: rgba(73, 184, 239, 0.85) !important; }

#interna.page-template-template-conocenos .img-desc.icon-box {
  width: 337px !important;
  height: 290px !important;
  padding: 0 !important; }
  #interna.page-template-template-conocenos .img-desc.icon-box img {
    width: auto; }

#interna.page-template-template-conocenos .img-t-board,
#interna.page-template-template-conocenos .img-r-board,
#interna.page-template-template-conocenos .img-l-board {
  z-index: 0 !important; }

#interna.page-template-template-conocenos #section-1-interna .img-kicker-container {
  padding: 92px 20px; }

#interna.page-template-template-conocenos #section-2-interna .section-img .effect-y-300 {
  top: 215px;
  right: 302px;
  position: absolute; }

#interna.page-template-template-conocenos #section-2-interna .section-text {
  padding-top: 0;
  padding-bottom: 140px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  #interna.page-template-template-conocenos #section-2-interna .section-text h2 {
    color: #49b8ef;
    margin-bottom: 40px !important; }
  #interna.page-template-template-conocenos #section-2-interna .section-text strong {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    display: block; }
  #interna.page-template-template-conocenos #section-2-interna .section-text ul {
    margin-top: 32px; }
    #interna.page-template-template-conocenos #section-2-interna .section-text ul li {
      margin-bottom: 35px; }
      #interna.page-template-template-conocenos #section-2-interna .section-text ul li:last-child {
        margin-bottom: 0; }

#interna.page-template-template-conocenos #nuestros-lideres {
  margin-bottom: 230px;
  margin-top: -92px;
  z-index: 7;
  position: relative; }
  #interna.page-template-template-conocenos #nuestros-lideres .container {
    width: 100%;
    max-width: 1536px;
    margin: auto; }
    #interna.page-template-template-conocenos #nuestros-lideres .container h2 {
      background: rgba(73, 184, 239, 0.85) !important;
      color: #fff;
      font-size: 50px;
      line-height: 50px;
      max-width: 912px;
      margin: auto;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      padding: 47px 20px; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-top: 100px;
      grid-row-gap: 85px; }
      #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item {
        padding: 0 25px; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item h3 {
          font-size: 40px;
          line-height: 45px;
          color: #49b8ef;
          font-weight: 900;
          text-transform: uppercase;
          margin-bottom: 34px; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item strong {
          font-size: 20px;
          line-height: 30px;
          text-transform: uppercase;
          margin-bottom: 32px;
          display: block; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item p {
          font-size: 20px;
          line-height: 30px; }

#interna.page-template-template-conocenos #nuestra-cultura {
  margin-bottom: 180px; }
  #interna.page-template-template-conocenos #nuestra-cultura .section-text {
    padding-left: 82px;
    padding-top: 84px;
    padding-right: 80px !important;
    padding-bottom: 50px;
    bottom: -230px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
      width: 386px;
      height: 269px;
      font-weight: 400;
      flex-direction: column;
      text-align: left;
      text-transform: none;
      font-size: 25px;
      line-height: 30px;
      padding-left: 75px;
      padding-right: 60px;
      right: 192px;
      top: -190px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text h2 {
      color: #49b8ef;
      margin-top: 0 !important;
      margin-bottom: 45px !important; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text p {
      margin-bottom: 35px; }

#interna.page-template-template-conocenos #sumate-a-dirwa {
  margin-bottom: 180px; }
  #interna.page-template-template-conocenos #sumate-a-dirwa .container {
    max-width: 1152px;
    width: 100%;
    background: #49b8ef;
    text-align: center;
    color: white;
    margin: auto;
    padding: 0 100px;
    padding-bottom: 55px;
    padding-top: 68px;
    margin-bottom: -100px; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container h2 {
      color: #fff;
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 16px !important; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container p {
      font-size: 20px;
      line-height: 30px; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container .btn {
      font-size: 24px !important;
      margin-top: 45px !important; }

@media screen and (max-width: 1950px) {
  #interna.page-template-template-conocenos #section-1-interna {
    margin-bottom: 230px !important; }
  #interna.page-template-template-conocenos #section-2-interna {
    margin-bottom: 0 !important; }
    #interna.page-template-template-conocenos #section-2-interna .section-img .effect-y-300 {
      right: 315px;
      top: 160px; }
  #interna.page-template-template-conocenos #nuestros-lideres {
    margin-top: -91px !important; } }

@media screen and (max-width: 1450px) {
  #interna.page-template-template-conocenos .img-desc.icon-box {
    width: 150px !important;
    height: 150px !important; }
    #interna.page-template-template-conocenos .img-desc.icon-box img {
      width: 60% !important; }
  #interna.page-template-template-conocenos #section-1-interna {
    margin-bottom: 190px !important; }
  #interna.page-template-template-conocenos #section-2-interna .section-img .effect-y-300 {
    right: 130px;
    top: 142px; }
  #interna.page-template-template-conocenos #section-2-interna .section-text {
    padding-bottom: 100px; }
    #interna.page-template-template-conocenos #section-2-interna .section-text h2 {
      margin-bottom: 25px !important; }
    #interna.page-template-template-conocenos #section-2-interna .section-text strong {
      font-size: 16px;
      line-height: 24px; }
    #interna.page-template-template-conocenos #section-2-interna .section-text ul {
      margin-top: 22px;
      margin-bottom: 0 !important; }
      #interna.page-template-template-conocenos #section-2-interna .section-text ul li {
        margin-bottom: 20px; }
  #interna.page-template-template-conocenos #nuestros-lideres {
    margin-top: -61px !important; }
    #interna.page-template-template-conocenos #nuestros-lideres .container h2 {
      padding: 34px 20px;
      font-size: 32px !important;
      line-height: 32px !important; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres {
      margin-top: 70px;
      grid-row-gap: 30px; }
      #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item {
        padding: 0px 20px; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item h3 {
          font-size: 28px !important;
          line-height: 28px !important;
          margin-bottom: 20px; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item strong {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px; }
        #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item p {
          font-size: 16px;
          line-height: 24px; }
  #interna.page-template-template-conocenos #nuestra-cultura .section-text {
    padding-left: 60px;
    padding-top: 60px;
    padding-right: 60px !important;
    padding-bottom: 20px;
    bottom: -156px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
      padding-left: 50px;
      padding-right: 40px;
      max-width: 300px;
      font-size: 20px !important;
      line-height: 26px !important;
      height: auto !important;
      padding-top: 35px;
      padding-bottom: 35px;
      top: -120px; }
  #interna.page-template-template-conocenos #sumate-a-dirwa .container {
    padding: 45px 200px 45px; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container .section-title {
      font-size: 32px !important;
      line-height: 32px !important; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container p {
      font-size: 16px;
      line-height: 24px; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container .btn {
      font-size: 18px !important;
      margin-top: 30px !important; } }

@media screen and (max-width: 1240px) {
  #interna.page-template-template-conocenos #sumate-a-dirwa {
    padding: 0 30px; }
  #interna.page-template-template-conocenos #nuestra-cultura .section-text {
    bottom: -230px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
      padding: 30px 30px 30px 30px;
      top: auto;
      bottom: -124px;
      right: -253px; } }

@media screen and (max-width: 1040px) {
  #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres {
    grid-template-columns: 1fr 1fr 1fr; }
  #interna.page-template-template-conocenos #nuestra-cultura .section-text {
    padding-left: 40px;
    padding-top: 50px;
    padding-right: 40px !important;
    padding-bottom: 10px;
    bottom: -270px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
      right: -280px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text h2 {
      margin-bottom: 25px !important; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text p {
      margin-bottom: 25px; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-conocenos #section-1-interna {
    margin-bottom: 110px !important; }
  #interna.page-template-template-conocenos #section-2-interna .section-img .effect-y-300 {
    top: auto !important;
    bottom: 0 !important;
    right: 100px; }
  #interna.page-template-template-conocenos #section-2-interna .section-text strong {
    font-size: 15px;
    line-height: 24px;
    text-align: center; }
  #interna.page-template-template-conocenos #nuestros-lideres .container h2 {
    font-size: 28px !important;
    line-height: 28px !important;
    width: calc(100% - 40px); }
  #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 40px;
    margin-top: 50px; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item h3 {
      font-size: 24px !important;
      line-height: 24px !important;
      margin-bottom: 15px; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item strong {
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 15px; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item p {
      font-size: 15px;
      line-height: 24px; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-conocenos #nuestra-cultura {
    margin-bottom: -25px !important; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text {
      bottom: 0; }
      #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
        font-size: 15px !important;
        line-height: 18px !important;
        padding: 20px !important;
        bottom: auto !important;
        left: 170px !important;
        top: -60px !important; }
  #interna.page-template-template-conocenos #sumate-a-dirwa {
    margin-bottom: 140px !important; }
    #interna.page-template-template-conocenos #sumate-a-dirwa .container {
      padding: 40px 20px; }
      #interna.page-template-template-conocenos #sumate-a-dirwa .container .btn {
        font-size: 16px !important; } }

@media screen and (max-width: 640px) {
  #interna.page-template-template-conocenos #nuestros-lideres .container h2 {
    padding: 25px 20px;
    font-size: 24px !important;
    line-height: 24px !important; }
  #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres {
    grid-template-columns: 1fr;
    margin-top: 30px;
    text-align: center; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item h3 {
      font-size: 20px !important;
      line-height: 20px !important;
      margin-bottom: 5px; }
    #interna.page-template-template-conocenos #nuestros-lideres .container .group-lideres .item strong {
      margin-bottom: 5px; }
  #interna.page-template-template-conocenos #nuestra-cultura {
    margin-bottom: 30px !important;
    bottom: -80px; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text {
      padding-bottom: 10px !important; }
  #interna.page-template-template-conocenos #sumate-a-dirwa .container .section-title {
    font-size: 24px !important;
    line-height: 24px !important; }
  #interna.page-template-template-conocenos #sumate-a-dirwa .container p {
    font-size: 15px;
    line-height: 24px; }
  #interna.page-template-template-conocenos #sumate-a-dirwa .container .btn {
    font-size: 15px !important;
    height: 50px;
    line-height: 50px; } }

@media screen and (max-width: 499px) {
  #interna.page-template-template-conocenos .img-desc.icon-box {
    width: 90px !important;
    height: 90px !important; }
  #interna.page-template-template-conocenos #section-2-interna .section-img .effect-y-300 {
    bottom: 15px !important;
    right: auto;
    left: 70px; }
  #interna.page-template-template-conocenos #nuestra-cultura .section-text {
    bottom: -60px;
    padding-right: 20px !important; }
    #interna.page-template-template-conocenos #nuestra-cultura .section-text .img-desc {
      left: 50% !important;
      top: -36px !important; } }

#interna.page-template-template-contenido_simple .img-kicker-container,
#interna.page-template-template-contenido_simple .box-overlay,
#interna.page-template-template-contenido_simple .img-desc {
  background: rgba(73, 184, 239, 0.85) !important; }

#interna.page-template-template-contenido_simple #section-1-interna {
  margin-bottom: 290px; }
  #interna.page-template-template-contenido_simple #section-1-interna .img-kicker-container {
    padding: 92px 20px; }

#interna.page-template-template-contenido_simple #section-contenido-simple {
  padding-bottom: 150px; }
  #interna.page-template-template-contenido_simple #section-contenido-simple .container {
    width: 100%;
    margin: auto;
    max-width: 1152px; }
    #interna.page-template-template-contenido_simple #section-contenido-simple .container strong {
      font-weight: 900; }
    #interna.page-template-template-contenido_simple #section-contenido-simple .container p {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 30px; }
      #interna.page-template-template-contenido_simple #section-contenido-simple .container p br {
        display: block !important; }

@media screen and (max-width: 1950px) {
  #interna.page-template-template-contenido_simple #section-1-interna {
    margin-bottom: 230px !important; }
  #interna.page-template-template-contenido_simple #section-contenido-simple {
    padding-bottom: 0; } }

@media screen and (max-width: 1450px) {
  #interna.page-template-template-contenido_simple .img-desc.icon-box {
    width: 150px !important;
    height: 150px !important; }
    #interna.page-template-template-contenido_simple .img-desc.icon-box img {
      width: 60% !important; }
  #interna.page-template-template-contenido_simple #section-1-interna {
    margin-bottom: 160px !important; }
  #interna.page-template-template-contenido_simple #section-contenido-simple .container p {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px; } }

@media screen and (max-width: 1190px) {
  #interna.page-template-template-contenido_simple #section-contenido-simple .container {
    padding: 0 20px; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-contenido_simple #section-1-interna {
    margin-bottom: 110px !important; } }

@media screen and (max-width: 480px) {
  #interna.page-template-template-contenido_simple #section-contenido-simple .container p {
    font-size: 14px; } }

#interna.page-template-template-diversidad .img-kicker-container,
#interna.page-template-template-diversidad .box-overlay,
#interna.page-template-template-diversidad .img-desc {
  background: rgba(73, 184, 239, 0.85) !important; }

#interna.page-template-template-diversidad #section-1-interna {
  margin-bottom: 230px; }
  #interna.page-template-template-diversidad #section-1-interna .img-kicker-container {
    padding: 70px 20px; }
  #interna.page-template-template-diversidad #section-1-interna .text-top {
    text-align: center;
    width: 100%;
    max-width: 1152px;
    margin: 170px auto auto;
    font-size: 20px;
    line-height: 30px;
    padding: 0 30px; }

#interna.page-template-template-diversidad #section-2-interna {
  margin-bottom: 370px; }
  #interna.page-template-template-diversidad #section-2-interna .img-desc {
    top: -45px;
    right: -90px;
    left: auto;
    padding: 55px 50px;
    background: rgba(106, 63, 140, 0.8) !important; }
  #interna.page-template-template-diversidad #section-2-interna .dirwi {
    position: absolute;
    z-index: 5;
    bottom: -550px;
    right: 50px;
    z-index: 8; }
    #interna.page-template-template-diversidad #section-2-interna .dirwi img {
      width: auto; }
  #interna.page-template-template-diversidad #section-2-interna .section-text {
    padding-top: 200px; }

#interna.page-template-template-diversidad #section-3-interna {
  margin-bottom: 200px; }
  #interna.page-template-template-diversidad #section-3-interna .section-text {
    padding: 148px 80px 80px;
    background: rgba(190, 0, 62, 0.9) !important;
    bottom: 110px; }
    #interna.page-template-template-diversidad #section-3-interna .section-text .box-overlay {
      padding: 55px 82px;
      background: rgba(229, 179, 0, 0.8) !important;
      top: -129px; }
  #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi {
    position: absolute;
    z-index: 5;
    bottom: -410px;
    transform: translateX(-50%);
    left: 0;
    z-index: 8; }
    #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi img {
      width: auto; }

@media screen and (max-width: 1950px) {
  #interna.page-template-template-diversidad #section-1-interna {
    margin-bottom: 200px !important; }
  #interna.page-template-template-diversidad #section-2-interna {
    margin-bottom: 300px !important; } }

@media screen and (max-width: 1450px) {
  #interna.page-template-template-diversidad .img-desc.icon-box {
    width: 150px !important;
    height: 150px !important; }
    #interna.page-template-template-diversidad .img-desc.icon-box img {
      width: 60% !important; }
  #interna.page-template-template-diversidad #section-1-interna {
    margin-bottom: 160px !important; }
    #interna.page-template-template-diversidad #section-1-interna .text-top {
      margin: 120px auto auto; }
  #interna.page-template-template-diversidad #section-2-interna {
    margin-bottom: 120px !important; }
    #interna.page-template-template-diversidad #section-2-interna .img-desc {
      padding: 30px 30px; }
    #interna.page-template-template-diversidad #section-2-interna .section-text {
      padding-top: 110px; }
    #interna.page-template-template-diversidad #section-2-interna .dirwi {
      bottom: -280px; }
      #interna.page-template-template-diversidad #section-2-interna .dirwi img {
        width: 200px; }
  #interna.page-template-template-diversidad #section-3-interna .section-text {
    bottom: 20px;
    padding: 110px 60px 40px; }
    #interna.page-template-template-diversidad #section-3-interna .section-text .box-overlay {
      padding: 30px 52px;
      top: -53px; }
  #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi {
    bottom: -210px; }
    #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi img {
      width: 130px; } }

@media screen and (max-width: 900px) {
  #interna.page-template-template-diversidad #section-1-interna {
    margin-bottom: 70px !important; }
    #interna.page-template-template-diversidad #section-1-interna .text-top {
      margin: 95px auto auto;
      font-size: 15px;
      line-height: 22px; }
  #interna.page-template-template-diversidad #section-2-interna .dirwi {
    bottom: -515px; }
  #interna.page-template-template-diversidad #section-3-interna .section-text {
    padding-top: 80px !important; }
    #interna.page-template-template-diversidad #section-3-interna .section-text .box-overlay {
      padding: 20px 30px;
      bottom: auto !important;
      top: -43px !important; }
  #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi {
    bottom: -435px;
    left: 100px; } }

@media screen and (max-width: 767px) {
  #interna.page-template-template-diversidad #section-1-interna {
    margin-bottom: 150px !important; }
  #interna.page-template-template-diversidad #section-2-interna {
    margin-bottom: 180px !important; }
    #interna.page-template-template-diversidad #section-2-interna .dirwi {
      top: -50px;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center; }
      #interna.page-template-template-diversidad #section-2-interna .dirwi img {
        width: 120px; }
  #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi {
    bottom: auto;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    top: -96px; }
    #interna.page-template-template-diversidad #section-3-interna .section-img .dirwi img {
      width: 88px; } }

@media screen and (max-width: 480px) {
  #interna.page-template-template-diversidad #section-1-interna .img-kicker-container h1 {
    font-size: 22px !important; }
  #interna.page-template-template-diversidad #section-2-interna .img-desc {
    padding: 20px;
    bottom: -130px !important; }
  #interna.page-template-template-diversidad #section-2-interna .section-text {
    padding-top: 120px !important; }
  #interna.page-template-template-diversidad #section-3-interna .section-text {
    bottom: -60px; } }

/* Nuevo footer */
.site-footer {
  padding: 0 30px 30px;
  color: #ffffff;
  z-index: 7;
  position: relative; }
  .site-footer .inner-holder {
    padding: 64px 20px;
    background: #363e44;
    position: relative; }
    .site-footer .inner-holder > .flex-container {
      max-width: 1378px;
      justify-content: space-between;
      margin: 0 auto;
      align-items: center;
      width: 100%; }
  .site-footer .footer-logo {
    width: auto;
    padding: 0; }
  .site-footer .footer-data {
    font-size: 18px;
    line-height: 1;
    text-align: right;
    align-items: center; }
    .site-footer .footer-data .extra-holder {
      margin-right: 29px; }
      .site-footer .footer-data .extra-holder .footer-links {
        letter-spacing: 0.23px; }
        .site-footer .footer-data .extra-holder .footer-links span {
          padding: 0 5px; }
      .site-footer .footer-data .extra-holder > p.font-700 {
        margin-bottom: 7px; }
  .site-footer .linkedin-link {
    width: 42px; }

@media screen and (max-width: 1500px) {
  .site-footer .footer-data {
    font-size: 15px;
    width: calc(100% - 360px);
    display: flex;
    justify-content: flex-end; }
  .site-footer .footer-logo {
    width: 360px;
    justify-content: center;
    display: flex; }
    .site-footer .footer-logo img {
      width: auto; } }

@media screen and (max-width: 1220px) {
  .site-footer .footer-logo {
    margin-left: 20px;
    width: auto; }
  .site-footer .footer-data {
    width: 100%; } }

@media screen and (max-width: 1150px) {
  .site-footer .footer-data {
    font-size: 14px; }
    .site-footer .footer-data .extra-holder {
      margin-right: 15px; }
  .site-footer .footer-logo {
    margin-left: 0; }
    .site-footer .footer-logo img {
      width: 140px; } }

@media screen and (max-width: 930px) {
  /* Footer */
  .site-footer {
    padding: 0; }
    .site-footer .inner-holder {
      padding: 35px 20px; }
      .site-footer .inner-holder > .flex-container {
        display: block; }
    .site-footer .footer-logo {
      margin-bottom: 40px; }
      .site-footer .footer-logo img {
        width: auto; }
    .site-footer .footer-data {
      justify-content: flex-start; }
      .site-footer .footer-data .extra-holder {
        margin-right: 0;
        margin-left: 15px;
        text-align: left; }
    .site-footer .linkedin-link {
      order: -1; } }

@media screen and (max-width: 767px) {
  .site-footer .footer-logo {
    margin-bottom: 35px; }
  .site-footer .footer-data {
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .site-footer .footer-data .extra-holder {
      text-align: center;
      margin: 0;
      margin-bottom: 20px; }
      .site-footer .footer-data .extra-holder .footer-links {
        flex-direction: column; }
        .site-footer .footer-data .extra-holder .footer-links span {
          display: none; }
        .site-footer .footer-data .extra-holder .footer-links a {
          margin-bottom: 7px; }
          .site-footer .footer-data .extra-holder .footer-links a:last-child {
            margin-bottom: 0; }
  .site-footer .linkedin-link {
    order: 1; } }

@media screen and (max-width: 499px) {
  .site-footer .footer-logo img {
    width: 160px; } }
