#interna.page-template-template-rpa {
    .img-desc {
        background: rgba(229, 179, 0, .85) !important;
    }
    #section-1-interna {
        margin-bottom: 130px;
        .img-kicker-container {
            background: rgba(229, 179, 0, .85) !important;
            padding: 103px 100px;
        }
        .text-top {
            width: 100%;
            max-width: 1152px;
            margin: auto;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            margin-top: 240px;
            p {
                color: #414d5b;
            }
        }
    }
    #section-2-interna {
        margin-bottom: 170px;
        .img-desc {
            width: 385px;
            height: 290px;
            top: 120px;
            right: -50px;
        }
        .section-text {
            padding-top: 220px;
            h2 {
                color: rgb(233, 188, 0);
                line-height: 50px;
                margin-bottom: 32px !important;
            }
            p,
            ul li {
                color: #414d5b;
            }
        }
    }
    #section-3-interna {
        margin-bottom: 170px;
        .section-text {
            padding-left: 122px;
            padding-top: 101px;
            padding-bottom: 101px;
            top: 306px;
            p {
                font-size: 50px;
                line-height: 55px;
                margin-bottom: 0;
            }
        }
    }
    #section-4-interna {
        margin-bottom: 160px;
        .section-text {
            padding-left: 50px !important;
            padding-top: 101px;
            padding-bottom: 71px;
            padding-right: 175px;
            top: 424px;
            .img-desc {
                width: 437px;
                height: 181px;
                right: 158px;
                top: -125px;
            }
        }
        .btn {
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 1950px) {
        #section-1-interna {
            .text-top {
                margin-top: 140px;
            }
        }
        #section-2-interna {
            .img-desc {
                width: 336px;
                height: 249px;
                right: 5px;
            }
            .section-text {
                padding-top: 130px;
            }
        }
        #section-3-interna .section-text {
            padding-left: 90px;
            padding-top: 80px;
            padding-bottom: 80px;
            p {
                font-size: 42px;
                line-height: 49px;
            }
        }
        #section-4-interna {
            margin-bottom: 160px!important;
            .section-text {
                padding-left: 80px!important;
                padding-top: 80px;
                padding-bottom: 54px;
                padding-right: 112px;
                top: 362px;
                .img-desc {
                    width: 340px;
                    height: 140px;
                    top: -99px;
                }
            }
        }
    }
    @media screen and (max-width: 1450px) {
        #section-1-interna {
            .text-top {
                margin-top: 110px;
                p {
                    // font-size: 18px;
                    // line-height: 24px;
                }
            }
        }
        #section-2-interna {
            .img-desc {
                width: 256px;
                height: 200px;
                right: -12px;
            }
            .section-text {
                padding-top: 100px;
                h2 {
                    margin-bottom: 20px!important;
                }
            }
        }
        #section-3-interna {
            .section-text {
                padding: 70px 80px;
                p {
                    font-size: 35px;
                    line-height: 40px;
                }
            }
        }
        #section-4-interna {
            .section-text {
                padding-left: 60px!important;
                padding-top: 65px;
                padding-bottom: 30px;
                padding-right: 90px;
                .img-desc {
                    width: 250px;
                    height: 105px;
                    top: -76px;
                    right: 90px;
                }
            }
        }
    }
    @media screen and (max-width: 1280px) {
        #section-3-interna .section-text {
            padding: 60px 50px;
            top: 270px;
            max-width: 75%!important;
            p {
                br {
                    display: none;
                }
            }
        }
        #section-4-interna .section-text {
            top: 330px;
        }
    }
    @media screen and (max-width: 1020px) {
        #section-3-interna {
            margin-bottom: 90px!important;
            .section-text {
                top: 170px;
            }
        }
        #section-4-interna {
            .section-text {
                padding-left: 50px!important;
                padding-top: 50px;
                padding-bottom: 15px;
                padding-right: 50px;
            }
        }
    }
    @media screen and (max-width: 900px) {
        #section-1-interna {
            .text-top {
                margin-top: 70px;
                p {
                    font-size: 15px;
                    line-height: 22px;
                }
            }
        }
        #section-2-interna {
            .img-desc {
                width: 210px;
                height: 150px;
            }
            ul {
                li {
                    margin-bottom: 0px!important;
                }
            }
        }
        #section-3-interna {
            margin-bottom: 20px!important;
            .section-text {
                max-width: initial !important;
                p {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
        #section-4-interna {
            margin-bottom: 40px!important;
            .img-desc {
                bottom: auto !important;
                top: -80px!important;
                width: 210px;
                height: 86px;
            }
            .section-text {
                max-width: initial !important;
                padding-top: 50px !important;
                padding-left: 20px !important;
                padding-right: 20px !important;
                padding-bottom: 20px !important;
            }
        }
    }
    @media screen and (max-width: 640px) {
        .flex-container.overlay-content .section-text {
            margin-top: 0;
        }
        #section-1-interna {
            .text-top {
                padding: 0 20px;
                margin-top: 80px;
            }
        }
        #section-2-interna {
            .img-desc {
                width: 170px;
                height: 118px;
            }
            .section-text {
                h2 {
                    margin-bottom: 15px!important;
                }
            }
        }
        #section-3-interna {
            margin-bottom: 0px!important;
            .section-text p {
                font-size: 18px;
                line-height: 24px;
            }
        }
        #section-4-interna {
            margin-bottom: 0px!important;
            .section-text {
                padding: 40px 20px 20px!important;
                .img-desc {
                    width: 180px;
                    height: 75px;
                    top: -52px!important;
                }
            }
        }
    }
}