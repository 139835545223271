/**
 * Correct `block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}


/**
  * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
  */

audio,
canvas,
video {
    display: inline-block;
}


/**
  * Prevent modern browsers from displaying `audio` without controls.
  * Remove excess height in iOS 5 devices.
  */

audio:not([controls]) {
    display: none;
    height: 0;
}


/**
  * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
  * Known issue: no IE 6 support.
  */

[hidden] {
    display: none;
}


/* ==========================================================================
    Base
    ========================================================================== */


/**
  * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
  *    `em` units.
  * 2. Prevent iOS text size adjust after orientation change, without disabling
  *    user zoom.
  */

html {
    font-size: 100%;
    /* 1 */
    -ms-text-size-adjust: 100%;
    /* 2 */
    -webkit-text-size-adjust: 100%;
    /* 2 */
}


/**
  * Address `font-family` inconsistency between `textarea` and other form
  * elements.
  */

html,
button,
input,
select,
textarea {
    font-family: sans-serif;
}


/**
  * Address margins handled incorrectly in IE 6/7.
  */

blockquote,
body {
    margin: 0;
}


/* ==========================================================================
    Links
    ========================================================================== */


/**
  * Address `outline` inconsistency between Chrome and other browsers.
  */

a {
    text-decoration: none;
}

a:focus {
    outline: thin dotted;
}


/**
  * Improve readability when focused and also mouse hovered in all browsers.
  */

a:active,
a:hover {
    outline: 0;
}


/* ==========================================================================
    Typography
    ========================================================================== */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: normal;
}


/**
  * Address styling not present in IE 7/8/9, Safari 5, and Chrome.
  */

abbr[title] {
    border-bottom: 1px dotted;
}


/**
  * Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
  */

b,
strong {
    font-weight: bold;
}


/**
  * Address styling not present in Safari 5 and Chrome.
  */

dfn {
    font-style: italic;
}


/**
  * Address differences between Firefox and other browsers.
  * Known issue: no IE 6/7 normalization.
  */

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
}


/**
  * Address styling not present in IE 6/7/8/9.
  */

mark {
    background: #ff0;
    color: #000;
}


/**
  * Address margins set differently in IE 6/7.
  */

pre {
    margin: 0;
}


/**
  * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
  */

code,
kbd,
pre,
samp {
    font-family: monospace;
    _font-family: 'courier new', monospace;
}


/**
  * Address CSS quotes not supported in IE 6/7.
  */

q {
    quotes: none;
}


/**
  * Address `quotes` property not supported in Safari 4.
  */

q:before,
q:after {
    content: '';
    content: none;
}


/**
  * Address inconsistent and variable font size in all browsers.
  */

small {
    font-size: 80%;
}


/**
  * Prevent `sub` and `sup` affecting `line-height` in all browsers.
  */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}


/* ==========================================================================
    Lists
    ========================================================================== */


/**
  * Address margins set differently in IE 6/7.
  */

dd,
dl,
menu,
ol,
ul {
    margin: 0;
}


/**
  * Address paddings set differently in IE 6/7.
  */

menu,
ol,
ul {
    padding: 0;
    list-style: none;
    list-style-image: none;
}


/* ==========================================================================
    Embedded content
    ========================================================================== */


/**
  * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
  * 2. Improve image quality when scaled in IE 7.
  */

img {
    border: 0;
    /* 1 */
    -ms-interpolation-mode: bicubic;
    /* 2 */
    vertical-align: top;
}


/**
  * Correct overflow displayed oddly in IE 9.
  */

svg:not(:root) {
    overflow: hidden;
}


/* ==========================================================================
    Figures
    ========================================================================== */


/**
  * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
  */

figure {
    margin: 0;
}


/* ==========================================================================
    Forms
    ========================================================================== */


/**
  * Correct margin displayed oddly in IE 6/7.
  */

form {
    margin: 0;
}


/**
  * Define consistent border, margin, and padding.
  */

p,
fieldset {
    border: none;
    margin: 0;
    padding: 0;
}


/**
  * 1. Correct color not being inherited in IE 6/7/8/9.
  * 2. Correct text not wrapping in Firefox 3.
  */

legend {
    border: 0;
    /* 1 */
    padding: 0;
    white-space: normal;
    /* 2 */
}


/**
  * 1. Correct font size not being inherited in all browsers.
  * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
  *    and Chrome.
  * 3. Improve appearance and consistency in all browsers.
  */

button,
input,
select,
textarea {
    font-size: 100%;
    /* 1 */
    margin: 0;
    /* 2 */
    vertical-align: baseline;
    /* 3 */
}


/**
  * Address Firefox 3+ setting `line-height` on `input` using `!important` in
  * the UA stylesheet.
  */

button,
input {
    line-height: normal;
}


/**
  * Address inconsistent `text-transform` inheritance for `button` and `select`.
  * All other form control elements do not inherit `text-transform` values.
  * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
  * Correct `select` style inheritance in Firefox 4+ and Opera.
  */

button,
select {
    text-transform: none;
}


/**
  * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
  *    and `video` controls.
  * 2. Correct inability to style clickable `input` types in iOS.
  * 3. Improve usability and consistency of cursor style between image-type
  *    `input` and others.
  * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
  *    Known issue: inner spacing remains in IE 6.
  */

button,
html input[type="button"],

/* 1 */

input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    /* 2 */
    cursor: pointer;
    /* 3 */
}


/**
  * Re-set default cursor for disabled elements.
  */

button[disabled],
html input[disabled] {
    cursor: default;
}


/**
  * 1. Address box sizing set to content-box in IE 8/9.
  * 2. Remove excess padding in IE 8/9.
  */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}


/**
  * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
  * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
  *    (include `-moz` to future-proof).
  */

input[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    /* 2 */
    box-sizing: content-box;
}


/**
  * Remove inner padding and search cancel button in Safari 5 and Chrome
  * on OS X.
  */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}


/**
  * Remove inner padding and border in Firefox 3+.
  */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}


/**
  * 1. Remove default vertical scrollbar in IE 6/7/8/9.
  * 2. Improve readability and alignment in all browsers.
  */

textarea {
    overflow: auto;
    /* 1 */
    vertical-align: top;
    /* 2 */
}


/* ==========================================================================
    Tables
    ========================================================================== */


/**
  * Remove most spacing between table cells.
  */

table {
    border-collapse: collapse;
    border-spacing: 0;
}


/* Clases generales */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    // -webkit-transition: all .3s;
    // transition: all .3s;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Termina';
}

@font-face {
    font-family: 'Termina';
    src: url('fonts/Termina-Light.otf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Termina';
    src: url('fonts/Termina-Regular.otf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('fonts/Termina-Medium.otf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Termina';
    src: url('fonts/Termina-Bold.otf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Termina';
    src: url('fonts/Termina-Heavy.otf') format('truetype');
    font-weight: 900;
}

.no-scroll {
    overflow-y: hidden;
}

.inner-container {
    max-width: 1920px;
    margin: 0 auto;
}

.clearfix:before,
.clearfix:after {
    content: "\0020";
    display: block;
    height: 0;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

.cover-link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.bg-transparent {
    background: transparent !important;
}

body {
    overflow-x: hidden;
}

button,
input {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

a {
    color: inherit;
}

.btn {
    height: 70px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    padding: 0 33px;
    border: 2px solid transparent;
    transition: .3s;
    &.btn-green {
        background: #5dc295;
        border-color: #5dc295;
        &:hover {
            background: white;
            color: #5dc295;
        }
    }
    &.btn-pink {
        background: #e40039;
        border-color: #e40039;
        &:hover {
            background: white;
            color: #e40039;
        }
    }
    &.btn-blue {
        background: #353d44;
        border-color: #353d44;
        &:hover {
            background: white;
            color: #353d44;
        }
    }
    &.btn-blue-light {
        background: #49b8ef;
        border-color: #49b8ef;
        &:hover {
            background: white;
            color: #49b8ef;
        }
    }
    &.btn-yellow {
        background: #e9bc00;
        border-color: #e9bc00;
        &:hover {
            background: white;
            color: #e9bc00;
        }
    }
}

.btn:hover {
    transition: .3s;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.clear-me {
    clear: both;
}


/* ACORDEON */

.accordion {
    width: 100%;
    max-width: 350px;
}

.accordion-item {
    position: relative;
}

.accordion-item.active {
    background: transparent !important;
    .icon {
        background: #fefefe;
    }
    .heading {
        &.usa {
            background: rgba(0, 112, 195, .75);
        }
        &.mexico {
            background: rgba(0, 227, 154, .75);
        }
        &.brasil {
            background: rgba(196, 160, 13, .7);
        }
        &.argentina {
            background: rgba(159, 83, 216, .75);
        }
        &.chile {
            background: rgba(255, 0, 88, .75);
        }
    }
}

.accordion-item.active .icon:before {
    background: #c1bb9e;
}

.accordion-item.active .icon:after {
    width: 0;
}

.accordion-item .heading {
    display: block;
    background: rgba(54, 59, 63, .7);
    padding: 34px 40px;
    border: 1px solid rgba(24, 26, 27, .3);
    box-shadow: 8px 8px 100px rgba(0, 0, 0, .7);
    transition: .3s;
    color: white;
    margin: 0;
    padding: 18px 25px;
    margin-right: 0;
    width: 100%;
    padding-left: 60px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.accordion-item .heading:hover .icon:before,
.accordion-item .heading:hover .icon:after {
    background: #c1bb9e;
}

.accordion-item .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 10px;
    width: 38px;
    height: 38px;
    border: 2px solid #fefefe;
    border-radius: 3px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.accordion-item .icon:before,
.accordion-item .icon:after {
    content: '';
    width: 1.25rem;
    height: 0.25rem;
    position: absolute;
    border-radius: 3px;
    left: 50%;
    top: 50%;
    transition: 0.3s ease-in-out;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: white;
}

.accordion-item .icon:after {
    -webkit-transform: translate(-50%, -50%) rotate(90deg);
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: -1;
}

.accordion-item .content {
    display: none;
    background: rgb(54, 59, 63);
    br {
        display: block !important;
    }
}

.accordion-item .content p {
    margin-top: 0;
    padding: 20px 20px;
    color: white;
}


/* SELECTS */


/* ===== Select Box ===== */

.select-field {
    background: white;
    padding-left: 0 !important;
    outline: none;
    .dropdown {
        display: inline-block;
        transition: all .5s ease;
        position: relative;
        color: #474747;
        height: 100%;
        text-align: left;
        width: 100%;
        outline: none;
    }
    .dropdown .select {
        cursor: pointer;
        display: block;
        height: 100%;
        font-size: 20px;
        line-height: 30px;
        color: #414d5b;
        font-family: Termina;
        outline: none;
        display: flex;
        align-items: center;
        padding-left: 20px;
        outline: none;
    }
    .dropdown .select>i {
        cursor: pointer;
        transition: all .3s ease-in-out;
        position: absolute;
        right: 0;
        background: #9fa1a5;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        color: white;
        outline: none;
    }
    .dropdown:hover {
        box-shadow: 0 0 4px rgb(204, 204, 204)
    }
    .dropdown:active {
        background-color: #f8f8f8
    }
    .dropdown.active:hover,
    .dropdown.active {
        box-shadow: 0 0 4px rgb(204, 204, 204);
        border-radius: 5px 5px 0 0;
        background-color: #f8f8f8
    }
    .dropdown {
        .select>i {
            &:before {
                transition: .3s;
            }
        }
        &.active .select>i {
            &:before {
                transform: rotate(-180deg)
            }
        }
    }
    .dropdown .dropdown-menu {
        position: absolute;
        background-color: #fff;
        width: 100%;
        left: 0;
        margin-top: 1px;
        box-shadow: 0 1px 2px rgb(204, 204, 204);
        border-radius: 0 1px 5px 5px;
        overflow: hidden;
        display: none;
        max-height: 180px;
        overflow-y: auto;
        z-index: 9
    }
    .dropdown .dropdown-menu li {
        padding: 10px;
        transition: all .2s ease-in-out;
        cursor: pointer;
        padding-left: 20px;
    }
    .dropdown .dropdown-menu {
        padding: 0;
        list-style: none
    }
    .dropdown .dropdown-menu li:hover {
        background-color: #f2f2f2
    }
    .dropdown .dropdown-menu li:active {
        background-color: #e2e2e2
    }
}

.mobile-499 {
    display: none;
}

.desktop-499 {
    display: block;
}


/* Posicionamientos */

.block {
    display: block;
}

.flex-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.center-me-x-y {
    position: absolute;
    top: 50%;
    left: 50%;
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.center-me-x {
    position: absolute;
    left: 50%;
    -o-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.center-me-y {
    position: absolute;
    top: 50%;
    left: 0;
    -o-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}


/* Clases para fuentes */

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-700 {
    font-weight: 700;
}

.font-900 {
    font-weight: 900;
}

.uppercase {
    text-transform: uppercase;
}


/* Colores */

.purple {
    color: #74359b;
}

.orange {
    color: #e9bd30;
}

.orange-bg {
    background-color: #e9bd30;
}

.green {
    color: #5dc295;
}

.green-bg {
    background-color: #5dc295;
}

.error {
    input,
    .select-field {
        border-color: #ff3b3b !important;
    }
    label {
        color: #ff3b3b !important;
    }
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}

.img-l-board,
.img-r-board,
.img-t-board {
    position: absolute;
    background: #fff;
    transform: scaleX(100);
    top: -1px;
}

.img-l-board {
    width: 10px;
    height: 201%;
    top: 0;
    left: -2px;
    transform-origin: left;
    z-index: 3;
    transform: scaleX(40);
    height: 100%;
}

.img-r-board {
    width: 10px;
    height: 201%;
    right: -2px;
    top: 0;
    transform-origin: right;
    z-index: 1;
    transform: scaleX(40);
    height: 100%;
}

.img-t-board {
    height: 10px;
    width: 100%;
    left: 0;
    -webkit-transform: scaleY(100);
    transform: scaleY(100);
    -webkit-transform-origin: top;
    transform-origin: top;
    z-index: 3;
    transform: scaleY(25);
}

section:not(#section-1-interna) {
    max-width: 1950px;
    margin: auto;
}

.flex-container {
    &.data-right {
        justify-content: flex-start;
        .section-text {
            padding-left: 65px !important;
            max-width: 693px!important;
        }
        &.overlay-content {
            padding-right: 30px;
            justify-content: flex-end;
            .section-img {
                max-width: 60%;
                position: absolute;
                top: 0;
                right: auto;
                left: 0;
            }
        }
    }
    &.data-left {
        justify-content: flex-end;
        .section-text {
            padding-right: 65px !important;
            max-width: 693px!important;
        }
        &.overlay-content {
            padding-left: 30px;
            justify-content: flex-start;
            .section-img {
                max-width: 60%;
                position: absolute;
                top: 0;
                right: 0;
            }
            .section-text {}
        }
    }
}

.flex-container {
    .section-text,
    .section-overlay-text {
        img {
            width: auto !important;
        }
        .bold-text {
            font-size: 20px;
            line-height: 30px;
            display: block;
            font-weight: 700;
            margin-bottom: 25px!important;
        }
        ul {
            padding-left: 0;
            margin-bottom: 30px;
            li {
                font-size: 20px;
                line-height: 30px;
                position: relative;
                &:before {
                    content: '•';
                    position: absolute;
                    top: 0px;
                    left: 0;
                    font-weight: bold;
                }
                &:first-letter {
                    padding-left: 12px;
                }
            }
        }
        p {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 30px;
        }
        .section-title,
        h3 {
            max-width: initial !important;
            line-height: 60px;
            margin-top: 62px;
            &:first-child {
                margin-top: 0;
            }
        }
        h3 {
            font-size: 50px;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 38px !important;
        }
        .section-title {
            margin-bottom: 25px !important;
            font-size: 50px;
            font-weight: 900;
            line-height: 50px !important;
        }
        .btn {
            margin-top: 50px;
        }
    }
    .section-img {
        position: relative;
    }
}

.section-vdo {
    width: 100%;
    max-width: 1152px;
}

.sound-content {
    width: 60px;
    height: 60px;
    background: #fff;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #61b2e5;
    font-size: 24px;
    border: 3px solid #61b2e5;
    cursor: pointer;
    transition: .5s;
    z-index: 10;
    right: 20px;
    bottom: 20px;
}

.content-video {
    position: relative;
    padding-bottom: 71.25%;
    /* padding-top: 25px; */
    height: 0;
    overflow: hidden;
    video {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .play_video {
        width: 60px;
        height: 60px;
        background: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #61b2e5;
        font-size: 24px;
        border: 3px solid #61b2e5;
        display: none;
        cursor: pointer;
        transition: .5s;
        z-index: 10;
        &.running {
            transform: scale(.8);
            right: 10px;
            left: auto;
            bottom: 10px;
            top: auto;
        }
    }
    .fa.fa-play {
        margin-left: 3px;
    }
}

@media screen and (max-width: 1440px) {
    .flex-container {
        .section-text,
        .section-overlay-text {
            p {
                font-size: 15px;
                line-height: 24px;
            }
            ul {
                li {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
            .section-title,
            h3 {
                font-size: 35px !important;
                line-height: 42px !important;
                margin-bottom: 30px !important;
                br {
                    display: none;
                }
            }
            .bold-text {
                font-size: 15px;
                line-height: 24px;
            }
        }
        .section-overlay-text {
            padding: 80px !important;
        }
    }
    .btn {
        font-size: 16px !important;
        height: 60px;
        line-height: 60px;
        padding: 0 24px;
    }
    /* Titulos - subtitulos */
    .flex-container {
        &.data-right {
            justify-content: flex-start;
            .section-text {
                padding-left: 30px !important;
            }
        }
        &.data-left {
            justify-content: flex-end;
            .section-text {
                padding-right: 30px !important;
            }
        }
    }
}

@media screen and (max-width: 1280px) {
    .flex-container {
        .section-text,
        .section-overlay-text {
            .bold-text {}
            ul {
                li {}
            }
            .section-title,
            h3 {
                font-size: 28px !important;
                line-height: 30px !important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .content-video {
        .play_video {
            display: flex;
        }
    }
    .sound-content {
        display: none;
    }
    .flex-container {
        &.data-left {
            .section-img {
                order: -1 !important;
            }
            .section-text {
                padding-right: 20px!important;
            }
            &.overlay-content {
                padding-left: 0px;
                .section-img {
                    position: relative !important;
                    width: 100%;
                    max-width: 100% !important;
                }
                .section-overlay-text {
                    margin-top: 0 !important;
                    width: calc( 100% - 60px) !important;
                    max-width: 100% !important;
                    left: 30px;
                }
            }
        }
    }
    .flex-container {
        &.data-right {
            .section-text {
                max-width: initial !important;
                padding-left: 20px!important;
            }
            &.overlay-content {
                padding-right: 0px !important;
                justify-content: flex-start;
                .section-img {
                    position: relative !important;
                    width: 100%;
                    max-width: 100% !important;
                    order: -1 !important;
                }
                .section-overlay-text {
                    margin-top: 0 !important;
                    width: calc( 100% - 60px) !important;
                    max-width: 100% !important;
                    left: 30px;
                }
            }
        }
        .section-text,
        .section-overlay-text {
            padding-top: 50px !important;
            .bold-text {
                margin-bottom: 20px !important;
            }
            ul {
                max-width: 500px;
                margin: auto;
                text-align: left;
                margin-bottom: 20px !important;
            }
            .section-title,
            h3 {
                margin-bottom: 20px !important;
                margin-top: 40px !important;
                &:first-child {
                    margin-top: 0px !important;
                }
            }
        }
        .section-overlay-text {
            position: relative !important;
            bottom: 130px !important;
            padding: 50px 40px !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .mobile-499 {
        display: block;
    }
    .desktop-499 {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .content-video .play_video {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }
    .flex-container {
        .section-overlay-text {
            bottom: 20px!important;
        }
        &.data-left.overlay-content .section-overlay-text {
            width: calc( 100% - 40px)!important;
            left: 20px;
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
        .section-text {
            .bold-text {}
            ul {
                li {}
            }
            .section-title,
            h3 {
                font-size: 22px!important;
                line-height: 24px!important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@import "modulos/header";
@import "modulos/home";
@import "modulos/interna";
@import "modulos/clientes";
@import "modulos/soluciones";
@import "modulos/tecnologias";
@import "modulos/rpa";
@import "modulos/conocenos";
@import "modulos/contenido-simple";
@import "modulos/diversidad";
@import "modulos/footer";