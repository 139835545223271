.home {
    /* Section - general */
    main {
        &>section:not(.video) {
            max-width: 1950px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }
    }
    .btn.orange-bg:hover {
        color: #e9bd30;
        border-color: #e9bd30;
        background: #ffffff;
    }
    .upper-kicker {
        width: 323px;
        font-size: 25px;
        color: #ffffff;
        background: #9e0028;
        padding: 38px 0 39px 38px;
        position: absolute;
        top: 1px;
        left: 49px;
        z-index: 2;
    }
    .overlay-text {
        color: #ffffff;
        background: rgba(228, 0, 57, 0.7);
        padding: 97px 0 50px 49px;
        left: 0;
        z-index: 1;
    }
    /* Secciones - imgs */
    .section-img {
        position: relative;
    }
    .img-desc {
        display: inline-block;
        font-size: 35px;
        line-height: 40px;
        color: #ffffff;
        padding: 48px 63px 51px 70px;
        z-index: 1;
    }
    .inside-img {
        width: 61.77%;
        max-width: 706px;
        position: absolute;
        right: 64px;
        bottom: -24.5555%;
    }
    /* Secciones - textos */
    .section-title {
        font-size: 50px;
        line-height: 1;
        font-weight: 900;
        margin-bottom: 53px;
    }
    .section-text {
        max-width: 710px;
        font-size: 20px;
        line-height: 30px;
        color: #414d5b;
        opacity: 0;
        p:not(:last-child) {
            margin-bottom: 30px;
        }
        &.uppercase {
            font-size: 24px;
        }
        p:first-of-type.uppercase {
            margin-bottom: 2px;
        }
    }
    /* Video */
    section.video {
        height: 100vh;
        padding: 30px;
        margin-bottom: 200px !important;
        max-width: 100% !important;
        .extra-holder {
            position: relative;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            padding-bottom: 0 !important;
            video {
                position: absolute;
                left: 0;
                top: 50%;
                height: auto;
                width: 100%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    /* Section 1 */
    #section-1 {
        margin-bottom: 200px;
        padding-right: 30px;
        .section-text {
            max-width: 678px;
            padding-top: 219px;
            padding-left: 30px;
            p {
                max-width: 605px;
            }
        }
        .img-desc {
            width: 699px;
            background: rgba(116, 53, 155, 0.8);
            position: absolute;
            top: -20px;
            left: 79.555555%;
            z-index: 5;
        }
    }
    /* Section 2 */
    #section-2 {
        margin-bottom: 195px;
        padding-left: 30px;
        &>.flex-container {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }
        .section-text {
            max-width: 700px;
            padding-right: 114px;
            p {
                max-width: 575px;
                &:first-of-type {
                    margin-bottom: 55px;
                }
            }
            .btn {
                margin-top: 0;
            }
        }
        .section-title {
            margin-bottom: 45px;
        }
        .robot {
            position: absolute;
            top: -142px;
            left: -191px;
            z-index: 5;
        }
    }
    /* Section 3 */
    #section-3 {
        margin-bottom: 190px;
        padding-right: 30px;
        .extra-holder {}
        .section-text {
            max-width: 673px;
            padding-left: 30px;
            p {
                max-width: 594px;
                &:nth-child(5) {
                    margin-bottom: 45px;
                }
            }
        }
        .links {
            max-width: 487px;
            flex-wrap: wrap;
            .btn {
                flex-basis: 236px;
                font-size: 24px;
                height: 61px;
                line-height: 61px;
                padding: 0;
                margin-top: 0;
                &.push {
                    margin-bottom: 13px;
                }
                &:hover {
                    color: #5dc295;
                    background: #ffffff;
                    border-color: #5dc295;
                }
            }
        }
        .section-title {
            color: #6de3ae;
            margin-bottom: 48px;
            padding-top: 59px;
        }
    }
    /* Section 4 */
    #section-4 {
        margin-bottom: 10px;
        padding-left: 30px;
        .flex-container {
            padding-left: 0 !important;
        }
        .upper-kicker {
            left: 52px;
            top: -210px;
        }
        .section-text {
            width: 75%;
            max-width: 1122px;
            padding-top: 196px;
            z-index: 5;
            opacity: 1;
            margin-top: 440px;
            max-width: initial !important;
            background: rgba(228, 0, 57, .75);
            color: white;
            padding-left: 50px !important;
            padding-bottom: 40px !important;
            padding-top: 90px !important;
            max-width: 960px !important;
            position: relative;
        }
        .section-title {
            max-width: 485px;
            margin-bottom: 45px;
        }
        .overlay-text p {
            max-width: 907px;
            &:nth-child(3) {
                max-width: 912px;
                margin-bottom: 51px;
            }
        }
        .btn {
            background: #e40039;
            margin-top: 0;
            &:hover {
                color: #e40039;
                border-color: #e40039;
                background: #ffffff;
            }
        }
        // .section-img {
        //     max-width: -webkit-calc(60% + 30px);
        //     max-width: calc(60% + 30px);
        //     right: 0;
        // }
    }
    /* Section 5 */
    #section-5 {
        position: relative;
        margin-bottom: 191px;
        padding-right: 30px;
        background: url(img/mundo-home.png)no-repeat;
        background-position-x: 114.6%;
        background-size: contain;
        margin-top: -90px !important;
        .img-desc {
            width: 620px;
            background: rgba(73, 184, 239, 0.8);
            position: absolute;
            top: 50px;
            left: 83%;
            padding: 64px 63px 66px 70px;
            z-index: 5;
        }
        .section-text {
            max-width: 674px;
            padding-top: 258px;
            padding-left: 30px;
        }
        .section-title {
            color: #49b8ef;
            margin-top: 20px;
            p {
                margin-bottom: 44px;
            }
        }
        .btn {
            background: #49b8ef;
            margin-top: 20px;
            &:hover {
                color: #49b8ef;
                background: #ffffff;
                border-color: #49b8ef;
            }
        }
    }
    /* Responsive */
    @media screen and (max-width: 1850px) {
        #section-1 .section-text p {
            max-width: 100%;
        }
        #section-2 .section-text {
            padding-right: 30px;
            p {
                max-width: 100%;
            }
        }
        #section-3 .section-text p {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 1650px) {
        #section-2 .robot {
            width: 250px;
            left: -152px;
            top: -50px;
            transform: translateY(-50%);
        }
    }
    @media screen and (max-width: 1440px) {
        .section-text .uppercase {
            font-size: 22px;
        }
        section .flex-container {
            flex-wrap: wrap;
        }
        .section-img {
            width: 60%;
        }
        .section-text {
            width: 40%;
            font-size: 16px;
            line-height: 24px;
            p {
                font-size: 15px;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
            .uppercase {
                font-size: 18px;
            }
        }
        /* Img descs */
        .img-desc {
            padding: 40px!important;
            font-size: 22px;
            max-width: 435px;
            width: 100%;
            line-height: 26px;
            br {
                display: none;
            }
        }
        .overlay-text {
            padding: 60px 50px 60px 50px;
        }
        .upper-kicker {
            font-size: 20px;
            padding: 32px;
            top: 45px;
        }
        section.video {
            margin-bottom: 130px!important;
        }
        #section-1 {
            margin-bottom: 140px;
            .img-desc {
                width: 100%;
            }
            .section-text {
                padding-top: 115px;
            }
        }
        /* Section 2 */
        #section-2 {
            margin-bottom: 125px;
            .section-text {
                max-width: 40%;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                padding-right: 30px;
                p:first-of-type {
                    margin-bottom: 40px;
                }
            }
            .section-title {
                max-width: 460px;
            }
            .robot {
                width: 160px;
                left: -123px;
                top: 5px;
                left: -92px;
            }
        }
        /* Section 3 */
        #section-3 {
            margin-bottom: 110px;
            .section-title {
                padding-top: 0;
            }
            .section-text p:nth-child(5) {
                margin-bottom: 30px;
            }
            .links .btn {
                font-size: 16px;
                height: 50px;
                line-height: 50px;
            }
        }
        #section-4 {
            margin-bottom: -75px;
            .upper-kicker {
                left: 52px;
                top: -130px !important;
            }
            .overlay-text p:nth-child(3) {
                margin-bottom: 30px;
            }
            .section-text {
                padding-top: 200px;
                margin-top: 340px;
                position: relative;
            }
        }
        #section-5 {
            margin-bottom: 140px;
        }
        .site-footer .footer-robot {
            width: 160px;
        }
    }
    @media screen and (max-width: 1300px) {
        section.video {
            margin-bottom: 140px!important;
            .extra-holder {}
        }
    }
    @media screen and (max-width: 1280px) {
        #section-2 .robot {
            left: -35px;
        }
        /* Section 3 */
        #section-3 .links .btn {
            flex-basis: 49%;
        }
        #section-5 .section-text {
            padding-top: 200px;
        }
        .section-title {
            font-size: 28px;
            margin-bottom: 28px!important;
        }
        .section-text {
            .uppercase {
                font-size: 18px;
            }
            p:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 1105px) {
        section.video .extra-holder video {
            height: 100%;
            width: auto;
        }
    }
    @media screen and (max-width: 1080px) {
        .section-title {
            max-width: 100%;
        }
        .section-text {
            padding-left: 30px!important;
            padding-right: 30px!important;
            max-width: 100%!important;
            font-size: 16px;
            line-height: 20px;
            p {
                max-width: 100%!important;
            }
        }
        .upper-kicker {
            top: 62px;
        }
    }
    @media screen and (max-width: 900px) {
        .menu-toggle-container {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            .toggle-container {
                transform: scale(.8);
            }
        }
        .globe {
            width: 60px;
            height: 60px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
                width: 30px;
                height: 30px;
                transform: scale(.8) !important;
            }
        }
        .img-desc {
            font-size: 24px;
            line-height: 28px;
        }
        /* Img descs */
        .upper-kicker {
            top: auto;
            left: 50%;
            top: -280px;
            transform: translateX(-50%);
        }
        .overlay-text {
            padding-top: 60px;
            padding-left: 30px;
            padding-right: 30px;
            margin-top: -150px;
        }
        .inside-img {
            right: 5%;
            width: 40%;
            bottom: -70px;
        }
        br {
            display: none;
        }
        // section.video {
        //     margin-bottom: 50px;
        //     position: relative;
        //     padding-bottom: 56.25%;
        //     padding-top: 25px;
        //     height: 0;
        //     iframe {}
        // }
        .section-img,
        .section-text {
            width: 100%;
        }
        section.video {
            margin-bottom: 60px!important;
            height: auto;
            .content-video {
                position: relative;
                padding-bottom: 56.25% !important;
                height: 0;
                overflow: hidden;
            }
        }
        main>section {
            &:not(.video) {
                padding: 0!important;
                margin-bottom: 70px!important;
                text-align: center;
                &>.flex-container {
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }
            &#section-1:not(.video) {
                margin-bottom: 30px!important;
            }
        }
        /* Section 1 */
        #section-1 {
            .img-desc {
                top: auto;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 24px !important;
                bottom: -110px;
            }
            .section-text {
                padding-top: 50px;
            }
        }
        /* Section 2 */
        #section-2 {
            .section-img {
                order: 1;
            }
            .section-text {
                order: 2;
                padding-top: 50px;
                max-width: initial!important;
            }
            .robot {
                width: 140px;
                top: 150px;
                right: 40px;
                left: auto;
            }
            .section-title {
                max-width: 100%;
            }
        }
        /* Section 3 */
        #section-3 {
            .section-text {
                padding-top: 50px;
            }
            .links {
                margin: 0 auto;
            }
        }
        /* Section 4 */
        #section-4 {
            margin-bottom: 0 !important;
            margin-top: 0;
            .section-title {
                max-width: 100%;
            }
            .upper-kicker {
                left: 50%;
            }
            .section-img {
                width: 100%;
                order: 1;
                position: initial;
            }
            .section-text {
                width: 100%;
                padding: 70px 20px!important;
                order: 2;
                max-width: calc(100% - 40px) !important;
                margin-top: 0 !important;
                margin-left: 20px;
            }
        }
        /* Section 5 */
        #section-5 {
            background-position: 100% 40%;
            background-size: contain;
            margin-top: 0px!important;
            margin-bottom: 60px!important;
            .btn {
                margin-top: 10px;
            }
            .img-desc {
                top: auto;
                left: 50%;
                transform: translate(-50%, -50%);
                bottom: -150px;
            }
            .section-title br:first-child {
                display: block;
            }
            .section-text {
                padding-top: 100px;
            }
        }
    }
    @media screen and (max-width: 640px) {
        section.video {
            padding: 0;
            padding-top: 60px;
        }
        /* Texto */
        .img-desc {
            font-size: 18px;
            line-height: 23px;
            padding: 20px !important;
        }
        .section-text {
            padding-left: 20px!important;
            padding-right: 20px!important;
        }
        #section-5 .section-text {
            padding-top: 95px !important;
        }
    }
    @media screen and (max-width: 480px) {
        .section-title {
            font-size: 24px;
        }
        .inside-img {
            width: 45%;
            bottom: -30px;
            z-index: 7;
        }
        .section-text {
            p {
                font-size: 14px;
            }
            .uppercase {
                font-size: 16px;
            }
        }
        .btn {
            font-size: 14px;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
        }
        .upper-kicker {
            font-size: 15px;
            padding: 20px;
            left: 50%;
            transform: translateX(-50%);
            top: -134px;
            width: calc(100% - 40px);
        }
        .overlay-text {
            margin-top: -70px;
        }
        // section.video {
        //     margin-bottom: 40px;
        // }
        /* Img desc */
        .img-desc {
            font-size: 14px;
            line-height: 20px;
            max-width: calc(100% - 40px);
            padding: 20px!important;
        }
        section.video {
            margin-bottom: 60px!important;
        }
        main>section:not(.video) {
            margin-bottom: 60px!important;
        }
        /* Section 1 */
        main>section#section-1:not(.video) {
            margin-bottom: 20px!important;
        }
        #section-1 {
            .section-text {
                padding-top: 40px;
            }
            .img-desc {
                width: calc(100% - 40px);
                padding: 10px !important;
            }
        }
        /* Section 2 */
        #section-2 {
            margin-bottom: 50px!important;
            .robot {
                width: 90px;
                top: 205px;
                right: auto;
                left: 20px;
            }
            .section-text {
                padding-top: 30px!important;
            }
        }
        main>section#section-3:not(.video) {
            margin-bottom: 34px!important;
        }
        #section-3 {
            .section-text {
                padding-top: 30px !important;
            }
            .links .btn {
                font-size: 14px;
                height: 44px;
                line-height: 44px;
            }
        }
        #section-4 {
            .section-text {
                margin-top: 80px !important;
                padding-bottom: 30px !important;
                .btn {
                    margin-top: 15px;
                }
            }
            .upper-kicker {
                top: -95px!important;
            }
        }
        #section-5 {
            margin-top: -25px!important;
            .img-desc {
                bottom: -144px;
            }
        }
    }
}