    #interna.page-template-template-diversidad {
        .img-kicker-container,
        .box-overlay,
        .img-desc {
            background: rgba(73, 184, 239, .85) !important;
        }
        #section-1-interna {
            margin-bottom: 230px;
            .img-kicker-container {
                padding: 70px 20px;
            }
            .text-top {
                text-align: center;
                width: 100%;
                max-width: 1152px;
                margin: 170px auto auto;
                font-size: 20px;
                line-height: 30px;
                padding: 0 30px;
            }
        }
        #section-2-interna {
            margin-bottom: 370px;
            .img-desc {
                top: -45px;
                right: -90px;
                left: auto;
                padding: 55px 50px;
                background: rgba(106, 63, 140, .8) !important;
            }
            .dirwi {
                position: absolute;
                z-index: 5;
                bottom: -550px;
                right: 50px;
                z-index: 8;
                img {
                    width: auto;
                }
            }
            .section-text {
                padding-top: 200px;
            }
        }
        #section-3-interna {
            margin-bottom: 200px;
            .section-text {
                padding: 148px 80px 80px;
                background: rgba(190, 0, 62, .9) !important;
                bottom: 110px;
                .box-overlay {
                    padding: 55px 82px;
                    background: rgba(229, 179, 0, .8) !important;
                    top: -129px;
                }
            }
            .section-img {
                .dirwi {
                    position: absolute;
                    z-index: 5;
                    bottom: -410px;
                    transform: translateX(-50%);
                    left: 0;
                    z-index: 8;
                    img {
                        width: auto;
                    }
                }
            }
        }
        @media screen and (max-width: 1950px) {
            #section-1-interna {
                margin-bottom: 200px!important;
            }
            #section-2-interna {
                margin-bottom: 300px!important;
            }
        }
        @media screen and (max-width: 1450px) {
            .img-desc.icon-box {
                width: 150px!important;
                height: 150px!important;
                img {
                    width: 60%!important;
                }
            }
            #section-1-interna {
                margin-bottom: 160px!important;
                .text-top {
                    margin: 120px auto auto;
                }
            }
            #section-2-interna {
                margin-bottom: 120px!important;
                .img-desc {
                    padding: 30px 30px;
                }
                .section-text {
                    padding-top: 110px;
                }
                .dirwi {
                    bottom: -280px;
                    img {
                        width: 200px;
                    }
                }
            }
            #section-3-interna {
                .section-text {
                    bottom: 20px;
                    padding: 110px 60px 40px;
                    .box-overlay {
                        padding: 30px 52px;
                        top: -53px;
                    }
                }
                .section-img .dirwi {
                    bottom: -210px;
                    img {
                        width: 130px;
                    }
                }
            }
        }
        @media screen and (max-width: 900px) {
            #section-1-interna {
                margin-bottom: 70px!important;
                .text-top {
                    margin: 95px auto auto;
                    font-size: 15px;
                    line-height: 22px;
                }
            }
            #section-2-interna .dirwi {
                bottom: -515px;
            }
            #section-3-interna {
                .section-text {
                    padding-top: 80px !important;
                    .box-overlay {
                        padding: 20px 30px;
                        bottom: auto !important;
                        top: -43px !important;
                    }
                }
                .section-img {
                    .dirwi {
                        bottom: -435px;
                        left: 100px;
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            #section-1-interna {
                margin-bottom: 150px!important;
            }
            #section-2-interna {
                margin-bottom: 180px!important;
                .dirwi {
                    top: -50px;
                    bottom: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 120px;
                    }
                }
            }
            #section-3-interna .section-img .dirwi {
                bottom: auto;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                top: -96px;
                img {
                    width: 88px;
                }
            }
        }
        @media screen and (max-width: 480px) {
            #section-1-interna .img-kicker-container h1 {
                font-size: 22px !important;
            }
            #section-2-interna {
                .img-desc {
                    padding: 20px;
                    bottom: -130px!important;
                }
                .section-text {
                    padding-top: 120px !important;
                }
            }
            #section-3-interna .section-text {
                bottom: -60px;
            }
        }
    }